import {
  CarePlan,
  DiagnosticReport,
  MedicationKnowledge,
  MedicationRequest,
  Money,
  Observation,
  ServiceRequest,
} from "fhir"

import { DRData } from "commons/procedures"

export type TriadData = {
  id: string
  triad: Observation
  organs: OrganData[]
}

export type OrganData = {
  id: string
  organ: Observation
  labData: Observation[]
}

export type MCSuplements = {
  triads: string[]
  mr: MedicationRequest
  mk: MedicationKnowledge
  price?: Money
}

export enum mcObsCategories {
  laboratory = "laboratory",
  vitality_risk = "vitality-risk",
  organ_risk = "organ-risk",
  vital_signs = "vital-signs",
  suborgan_risk = "suborgan-risk",
  wellness_indicator = "wellness-indicator",
  triad_risk = "triad-risk",
}

export type CarePlanWithDR = {
  order: ServiceRequest
  plan?: CarePlan
  diagnosticReport?: DiagnosticReport
  qrId?: string
  results?: DRData
}
