import { CarePlan, MedicationKnowledge, isActivityDefinition } from "fhir"

import { CalculatorOutput } from "./types"

const getDoseNumberValue = (dose: string, doseUnit: string | undefined = "mg") => {
  let number = 0
  try {
    number = parseFloat(dose.replace(doseUnit, ""))
  } catch (_) {
    number = 0
  }

  return number
}

const generateCalculatorResultFromCarePlan = (cp: CarePlan): CalculatorOutput => {
  const ads = cp.contained?.filter(isActivityDefinition) ?? []
  const mks = ads.flatMap((ad) => (ad?.contained ?? []) as MedicationKnowledge[])

  return {
    suggestedMeds: mks.filter((med) => !!med.catalogHeader),
    recommended: ads.map((ad) => ({
      codeableConcept: ad?.product?.CodeableConcept ?? {},
      dosage: ad?.dosage?.[0] ?? {},
    })),
    notes: cp?.note ?? [],
  }
}

export { generateCalculatorResultFromCarePlan, getDoseNumberValue }
