import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { StackedListItem, StackedListItemProps } from "commons"

const SuggestedMedItem = ({ model, checked, disabled, onChange }: Props) => {
  const [qty, setQty] = useState(1)
  const [checkStatus, setCheckStatus] = useState(checked)

  return (
    <div className="flex items-center">
      <div
        className={classNames("flex flex-1 items-center", { "cursor-pointer": !disabled })}
        onClick={
          disabled
            ? undefined
            : () => {
                setCheckStatus(!checkStatus)
                onChange(!checkStatus, qty)
              }
        }
      >
        <div className="flex items-center mr-3">
          <Checkbox checked={checkStatus} disabled={disabled} />
        </div>
        <StackedListItem modelData={model} />
      </div>
      <Dropdown
        className="mr-1 p-inputtext-sm"
        title="Quantity"
        disabled={!checkStatus}
        options={Array.from({ length: 5 }, (_, i) => i + 1)}
        value={qty}
        onChange={(e) => {
          setQty(e.value)
          onChange(checkStatus, e.value)
        }}
      />
    </div>
  )
}

type Props = {
  model: StackedListItemProps
  checked: boolean
  disabled?: boolean
  onChange(checked: boolean, quantity: number): void
}

export { SuggestedMedItem }
