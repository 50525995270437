import { Reference } from "fhir"
import { FieldProps, FormikValues, useFormikContext } from "formik"
import { DropdownProps } from "primereact/dropdown"
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton"
import { FC, useCallback, useEffect } from "react"

import { BILLING_TYPES_CODES, statesWithOnlyInsuranceLabOrdersAllowed } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getAddressByType, getHomeAddress } from "utils"

import { LaboratoryOrder } from "../../labs"
import { FormField } from "../FormField"
import { InsuranceField } from "./InsuranceField"

const BillingField: FC<Props> = ({
  billingLabel = "Bill to",
  insuranceLabel = "Insurance Plan",
  insuranceField = BILLING_TYPES_CODES.INSURANCE,
  insuranceValidation,
  onBillingChange,
  className,
  disabled,
  horizontal,
  labelClassName,
  hideInsuranceOption,
  hideInsuranceField,
  ...dropdownProps
}) => {
  const { currentOrganizationBillingTypes } = useOrganizationContext()
  const { setFieldValue, values } = useFormikContext<LaboratoryOrder>()
  const {
    patient: { address },
  } = usePatientContext()
  const physicalOrAnyValidAddress =
    getAddressByType("physical", address) ??
    getAddressByType("postal", address) ??
    getHomeAddress(address) ??
    address?.[0]
  const showOnlyInsuranceBill = statesWithOnlyInsuranceLabOrdersAllowed.includes(physicalOrAnyValidAddress?.state ?? "")
  const filteredBillingTypes = showOnlyInsuranceBill
    ? currentOrganizationBillingTypes.filter(({ code }) => code === BILLING_TYPES_CODES.INSURANCE)
    : hideInsuranceOption
      ? currentOrganizationBillingTypes.filter(({ code }) => code !== BILLING_TYPES_CODES.INSURANCE)
      : currentOrganizationBillingTypes

  const {
    values: { billingType },
  } = useFormikContext<FormikValues & { billingType: string | undefined }>()

  const showInsuranceField = values.billingType === BILLING_TYPES_CODES.INSURANCE

  const defValidation = useCallback(
    (value: Reference) =>
      billingType === BILLING_TYPES_CODES.INSURANCE && !value?.id ? `${insuranceLabel} is required` : undefined,
    [insuranceLabel, billingType],
  )

  const handleChange = (e: RadioButtonChangeEvent) => {
    onBillingChange?.(e.value)
  }

  useEffect(() => {
    if (showOnlyInsuranceBill) {
      setFieldValue("billingType", BILLING_TYPES_CODES.INSURANCE)
      onBillingChange?.(BILLING_TYPES_CODES.INSURANCE)
    }
  }, [showOnlyInsuranceBill])

  return (
    <>
      <FormField
        field="billingType"
        label={billingLabel}
        horizontal={horizontal}
        className={className}
        labelClassName={labelClassName}
      >
        {({ field: { name, value, onChange } }: FieldProps) => (
          <div className="flex space-x-4 w-full">
            {filteredBillingTypes.map(({ code, display }, index) => (
              <div key={index} className="text-xs flex items-center mt-2">
                <RadioButton
                  inputId={code}
                  name={name}
                  value={code}
                  checked={code === value}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e)
                  }}
                  disabled={disabled}
                />
                <label htmlFor={code} className="flex w-full cursor-pointer mx-2">
                  {display}
                </label>
              </div>
            ))}
          </div>
        )}
      </FormField>

      {showInsuranceField && !hideInsuranceField && (
        <InsuranceField
          field={insuranceField}
          label={insuranceLabel}
          validation={insuranceValidation ?? defValidation}
          horizontal={horizontal}
          className={className}
          labelClassName={labelClassName}
          {...dropdownProps}
        />
      )}
    </>
  )
}

type Props = {
  billingLabel?: string
  insuranceLabel?: string
  insuranceField?: string
  horizontal?: boolean
  className?: string
  labelClassName?: string
  hideInsuranceOption?: boolean
  hideInsuranceField?: boolean
  onBillingChange?(value: string): void
  insuranceValidation?(value: Reference): string
} & Omit<DropdownProps, "options">

export { BillingField }
