import {
  Coding,
  Coverage,
  ObservationValue,
  PlanDefinition,
  Reference,
  RequestGroupActionArrayActionArray,
  ResourceObject,
  ServiceRequest,
  asReference,
  codeableConceptAsString,
  isOrganization,
} from "fhir"

import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST } from "data"
import { SYSTEM_VALUES } from "system-values"
import {
  convertIdentifiersToCodings,
  getCoverage,
  getServiceRequestBillingType,
  getStringAddress,
  restrictedICD10LabPerformers,
} from "utils"

import {
  LabPanelDetailsComponent,
  LabPanelResult,
  LaboratoryOrderDetailsComponent,
  LaboratoryOrderPanel,
  PlaceOfService,
} from "./types"

const getStatus = (labOrder: ServiceRequest) =>
  labOrder?.orderDetail?.find((item) => item?.coding?.[0]?.system === SYSTEM_VALUES.ORDER_STATUS)?.coding?.[0]

const observationValueAsString = (value?: ObservationValue): string | boolean | undefined => {
  if (!value) return undefined
  const { Quantity, boolean, dateTime, integer, string, time, CodeableConcept } = value
  if (string) return string
  if (Quantity) {
    return Quantity.value?.toString()
  }
  if (integer) return integer.toString()
  if (boolean) return boolean
  if (CodeableConcept) return codeableConceptAsString(CodeableConcept)
  return time || dateTime
}

// funcion to get the Places of Service and the wrapped data of the panels
// we take advantage that we have to go through all the panels to get the places of service
// so we wrapped all the data in the same iteration and reduce overall cost for the app,
// because in one iteration we get all the data we need to render all the panels (panels + details) and places of service
// we created a LabPanelPOSDetails component to render the places of service
// we don`t use the same component for the panels because the data to render is different
const getPanelDetails = (panels: LaboratoryOrderPanel[], scrollToPosRef: (name: string) => void) => {
  if (panels.length === 0) return

  // we use a set to avoid duplicates
  const posNameSet = new Set()

  return panels.reduce<LaboratoryOrderDetailsComponent>(
    (acc: LaboratoryOrderDetailsComponent, panel: LaboratoryOrderPanel) => {
      const placesOfService: PlaceOfService[] = acc?.placesOfService ?? []
      const panels = acc?.panels ?? []
      const nonCancelledObservations = panel.observations?.filter(({ status }) => status !== "cancelled") ?? []

      // we iterate over the observations to get the places of service
      // and the wrapped data of the panels
      const data = {
        key: panel.profile.code,
        code: panel.profile.code,
        results: nonCancelledObservations.map((observation) => {
          const observationPOS = observation?.contained?.find(isOrganization)
          const namePOS = observationPOS?.name
          // we add the place of service to the list if it is not already there
          if (namePOS && !posNameSet.has(namePOS)) {
            posNameSet.add(namePOS)
            placesOfService.push({
              key: observationPOS?.name,
              name: observationPOS?.name,
              address: getStringAddress(observationPOS?.address?.[0]),
              contact: observationPOS?.contact?.[0]?.name?.text,
              telecom: observationPOS?.telecom?.[0]?.value,
            })
          }

          return {
            key: observation.id ?? codeableConceptAsString(observation.code),
            scrollToPosRef: scrollToPosRef,
            code: codeableConceptAsString(observation.code),
            referenceRange: observation?.referenceRange?.[0],
            effectiveDateTime: observation?.effective?.dateTime,
            value: observationValueAsString(observation?.value),
            unit: observation?.value?.Quantity?.unit ?? observation.valueUnit ?? "",
            interpretation: observation?.interpretation?.[0].text,
            placeOfService: observationPOS,
            notes: observation?.note,
            status: observation?.status,
            attachment: observation?.value?.Attachment,
          } as LabPanelResult
        }),
        totalResults: nonCancelledObservations.length,
        price: panel.price,
        collected: nonCancelledObservations.find((o) => !!o.effective?.dateTime)?.effective?.dateTime,
        received: nonCancelledObservations.find((o) => !!o.issued)?.issued,
        tests: !nonCancelledObservations.length
          ? panel.planDefinition?.action?.length
          : nonCancelledObservations.length,
      } as LabPanelDetailsComponent

      return { placesOfService, panels: [...panels, data] }
    },
    { placesOfService: [], panels: [] },
  )
}

const getSRCodes = ({
  serviceRequests,
  planDefinitions,
  serviceRequestActions,
}: {
  serviceRequests: ServiceRequest[]
  planDefinitions: PlanDefinition[]
  serviceRequestActions?: RequestGroupActionArrayActionArray[]
}) => {
  const pds = planDefinitions.reduce<Record<string, Coding[]>>((result, pd) => {
    return { ...result, [`${pd?.url}|${pd?.version}`]: convertIdentifiersToCodings([pd]) }
  }, {})

  const srs = serviceRequests.reduce<Record<string, ServiceRequest>>((acc, cur) => {
    return { ...acc, [cur.id as string]: cur }
  }, {})

  const codes: { billToPracticeOrInsuranceCIDs: Coding[]; billToPatientCIDs: Coding[] } = [
    ...(serviceRequestActions ?? serviceRequests),
  ].reduce(
    (acc: { billToPracticeOrInsuranceCIDs: Coding[]; billToPatientCIDs: Coding[] }, cur) => {
      const serviceRequest = srs?.[((cur as RequestGroupActionArrayActionArray).resource?.id ?? cur.id) as string]

      const srPanels =
        serviceRequest.basedOn
          ?.filter((basedOn) => basedOn.resourceType === "ServiceRequest")
          .reduce((acc, sr) => {
            const newSr = srs[sr.id as string]
            return [...acc, ...(newSr ? [newSr] : [])]
          }, Array<ServiceRequest>()) ?? []

      const codes = srPanels.reduce(
        (acc, curr) => [...acc, ...(pds[curr.instantiatesCanonical?.[0] as string] ?? [])],
        [] as Coding[],
      )

      const billingType = getServiceRequestBillingType(serviceRequest)
      return billingType === BILLING_TYPES_CODES.BILL_PATIENT
        ? {
            billToPracticeOrInsuranceCIDs: acc.billToPracticeOrInsuranceCIDs,
            billToPatientCIDs: [...acc.billToPatientCIDs, ...codes],
          }
        : {
            billToPracticeOrInsuranceCIDs: [...acc.billToPracticeOrInsuranceCIDs, ...codes],
            billToPatientCIDs: acc.billToPatientCIDs,
          }
    },
    { billToPracticeOrInsuranceCIDs: [] as Coding[], billToPatientCIDs: [] as Coding[] },
  )

  return { pds, srs, labCodes: codes }
}

const getEnabledLabFacilities = (labFacilities: Reference[], enabledLabs: string[]) =>
  labFacilities.filter(({ id }) => enabledLabs.includes(id as string))

const isBDPanel = (panel: LaboratoryOrderPanel) =>
  panel.profile.code?.coding?.some(({ code }) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(code as string))

const getClasifiedBDPanels = (panels?: LaboratoryOrderPanel[]) => {
  const initialValue = { bdPanels: Array<LaboratoryOrderPanel>(), nobdPanels: Array<LaboratoryOrderPanel>() }
  return (
    panels?.reduce((prev, p) => {
      if (isBDPanel(p)) {
        return {
          ...prev,
          bdPanels: [...prev.bdPanels, p],
        }
      } else {
        return { ...prev, nobdPanels: [...prev.nobdPanels, p] }
      }
    }, initialValue) ?? initialValue
  )
}

const getRestrictedLabPerformer = (restrictedLabPerformerId: string) =>
  restrictedICD10LabPerformers.find((restrictedLabPerformer) =>
    restrictedLabPerformer.ids.includes(restrictedLabPerformerId),
  )

const getSanitizedOrderCoverage = (order: ServiceRequest, billingType: string, orgRef: Reference) => {
  if (billingType !== BILLING_TYPES_CODES.INSURANCE) {
    let coverage: Coverage
    if (billingType === BILLING_TYPES_CODES.BILL_PATIENT) {
      coverage = getCoverage(billingType, asReference(order.subject))
    } else {
      coverage = getCoverage(billingType as BILLING_TYPES_CODES, asReference(order.subject), orgRef)
    }
    order.contained = order.contained
      ? [...(order.contained as ResourceObject[]).filter(({ resourceType }) => resourceType !== "Coverage"), coverage]
      : [coverage]
    order.insurance = [{ localRef: coverage.id }]
  } else {
    order.contained = order.contained && [
      ...(order.contained as ResourceObject[]).filter(({ resourceType }) => resourceType !== "Coverage"),
    ]
  }

  return order
}

export {
  getClasifiedBDPanels,
  getEnabledLabFacilities,
  getPanelDetails,
  getRestrictedLabPerformer,
  getSRCodes,
  getSanitizedOrderCoverage,
  getStatus,
  isBDPanel,
  observationValueAsString,
}
