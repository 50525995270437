import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ServiceRequest } from "fhir"
import { FC } from "react"

import { getBadgeColor, getLabOrderIdentifier } from "utils"

import { Badge } from "../../../components/Badge"
import { getStatus } from "../../../labs"
import { CPLabResultsDetails } from "./CPLabResultsDetails"

const CPLabsDetails: FC<Props> = ({ serviceRequest, isOnlySurvey, patientId, showOrder }) => {
  const labStatus = serviceRequest && getStatus(serviceRequest)

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <span className="text-gray-700">
          {serviceRequest?.performer?.[0].display}{" "}
          {labStatus?.code === "draft" || !serviceRequest
            ? ""
            : `(${getLabOrderIdentifier(serviceRequest as ServiceRequest, [])})`}
        </span>
        <div className="flex justify-end">
          <div className="flex w-fit justify-between gap-8">
            <Badge
              {...getBadgeColor(labStatus?.code === "revoked" ? "cancelled" : labStatus?.display ?? "unspecified")}
              className="h-min lowercase"
            />
            {serviceRequest?.id && (
              <FontAwesomeIcon
                icon={faExternalLink}
                className="cursor-pointer text-gray-500 mr-4"
                title="Show order"
                onClick={() => showOrder(serviceRequest.id as string)}
              />
            )}
          </div>
        </div>
      </div>
      {!isOnlySurvey && <CPLabResultsDetails patientId={patientId} labOrderId={serviceRequest?.id} />}
    </div>
  )
}

type Props = {
  serviceRequest?: ServiceRequest
  isOnlySurvey: boolean
  patientId: string
  showOrder(srId: string): void
}

export { CPLabsDetails }
