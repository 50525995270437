import {
  Dosage,
  Duration,
  Medication,
  MedicationKnowledge,
  MedicationKnowledgeAdministrationGuidelinesArray,
  Quantity,
} from "fhir"

import { dispenseInterval, getMKDisplayText, MedicationRequestData } from "commons/meds"
import { UNIT_DAYS } from "data"
import { SYSTEM_VALUES } from "system-values"
import { unitToDays } from "utils"

import { sanitizeDosage } from "./components/validation"
import { MedicationDosage, MedicationRequestFormData } from "./types"

const getTreatmentFrequency = (dosage?: Dosage) => {
  let treatmentFrequency = "oncePerDay"
  if (dosage?.timing?.repeat?.period === 1) {
    if (dosage?.timing?.repeat?.periodUnit === "d") {
      switch (dosage?.timing?.repeat.frequency) {
        case 2:
          treatmentFrequency = "twicePerDay"
          break
        case 3:
          treatmentFrequency = "threePerDay"
          break
        case 4:
          treatmentFrequency = "fourPerDay"
          break
      }
      switch (dosage?.timing?.repeat.when?.[0]) {
        case "MORN":
          treatmentFrequency = "everyMorning"
          break
        case "NIGHT":
          treatmentFrequency = "everyNight"
          break
      }
      return treatmentFrequency
    }
    if (dosage?.timing?.repeat?.periodUnit === "wk") {
      treatmentFrequency = "oncePerWeek"
      switch (dosage?.timing?.repeat.frequency) {
        case 2:
          treatmentFrequency = "twicePerWeek"
          break
        case 3:
          treatmentFrequency = "threePerWeek"
          break
        case 4:
          treatmentFrequency = "fourPerWeek"
          break
        case 5:
          treatmentFrequency = "fivePerWeek"
          break
        case 8:
          treatmentFrequency = "eightPerWeek"
          break
      }
      return treatmentFrequency
    }
    if (dosage?.timing?.repeat?.periodUnit === "mo") {
      treatmentFrequency = "oncePerMonth"
      switch (dosage?.timing?.repeat.frequency) {
        case 5:
          treatmentFrequency = "fivePerMonth"
          break
        case 7:
          treatmentFrequency = "sevenPerMonth"
          break
        case 15:
          treatmentFrequency = "fifteenPerMonth"
          break
        case 25:
          treatmentFrequency = "twentyFivePerMonth"
          break
      }
      return treatmentFrequency
    }
  }
  if (
    dosage?.timing?.repeat?.period === 2 &&
    dosage?.timing?.repeat?.periodUnit === "d" &&
    dosage?.timing?.repeat.frequency === 1
  ) {
    return "everyOtherDay"
  }
}

const calculateTotalDoseQuantity = (dosages: MedicationDosage[]) =>
  parseFloat(dosages.reduce((acc, dosage) => acc + calculateDoseQuantity(dosage), 0).toFixed(2))

const calculateAmountQuantity = (totalDoseQty: number, amount?: Quantity) => {
  const amountValue = amount?.value ?? 1
  return Math.ceil(totalDoseQty / amountValue)
}

const calculateDoseQuantity = (orientedDosage: MedicationDosage) => {
  const dosage = sanitizeDosage(orientedDosage)
  const doseQuantity = dosage?.doseAndRate?.[0]?.dose?.Quantity?.value ?? 0
  const dosageRepeats = dosage?.timing?.repeat?.frequencyMax ?? dosage?.timing?.repeat?.frequency ?? 1
  const dosagePeriod = dosage?.timing?.repeat?.periodMax ?? dosage?.timing?.repeat?.period ?? 1
  const dosagePeriodUnit = dosage?.timing?.repeat?.periodUnit ?? "d"
  const dosageDuration = dosage?.timing?.repeat?.duration ?? 1
  const dosageDurationUnit = dosage?.timing?.repeat?.durationUnit ?? "wk"

  const units =
    (doseQuantity * dosageRepeats * dosageDuration * unitToDays(dosageDurationUnit)) /
    (dosagePeriod * unitToDays(dosagePeriodUnit))

  return units
}

const calculateDosageDispenseInterval = (dosages: MedicationDosage[]) => {
  const totalDays = dosages.reduce(
    (prev, { doseTiming }) => prev + (doseTiming?.repeat?.duration ?? 0) * unitToDays(doseTiming?.repeat?.durationUnit),
    0,
  )
  const totalMonths = Math.floor(totalDays / UNIT_DAYS.MONTH) || 1

  return (
    dispenseInterval.find(({ value: { value } }) => totalMonths <= (value ?? 0))?.value ??
    dispenseInterval.at(-1)?.value
  )
}

const getGuidelineLabel = (guideline: MedicationKnowledgeAdministrationGuidelinesArray) =>
  (guideline.dosage?.flatMap(({ dosage }) => dosage?.map(({ text }) => text ?? "") ?? []) ?? []).join(", ")

const getMedicationFormData = ({
  medicationKnowledge,
  medicationRequestInfo,
}: MedicationRequestData): MedicationRequestFormData => {
  return {
    ...medicationRequestInfo,
    medicationKnowledge,
    medicationField: {
      ...(medicationKnowledge ?? (medicationRequestInfo.contained?.[0] as Medication)),
      textDisplayedInField: medicationKnowledge
        ? getMKDisplayText(medicationKnowledge)
        : (medicationRequestInfo.contained?.[0] as Medication)?.code?.text,
    },
    prescriptionQuantity: { ...medicationRequestInfo.dispenseRequest?.quantity },
  }
}

const getMedicationKnowledgeSku = (mk: MedicationKnowledge) =>
  mk.code?.coding?.find(({ system }) => system === SYSTEM_VALUES.SKU)?.code

const getDispenseFrequency = (interval: Duration | undefined) => {
  const dispense =
    interval &&
    dispenseInterval.find(
      ({ value: { code, system, unit, value } }) =>
        interval?.system === system && interval?.code === code && interval?.value === value && interval?.unit === unit,
    )

  return dispense
}

// NPI Validation algorithm as per https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
function isValidNPIValue(npi: string) {
  // Ensure the NPI is 10 digits long
  if (!/^\d{10}$/.test(npi)) {
    return false
  }

  const digits = Array.from(npi).map(Number)

  // The last digit is the check digit
  const checkDigit = digits.pop() // Remove the check digit

  // Step 1: Double the value of alternate digits, starting from the rightmost digit
  const sum = digits.reverse().reduce((total, digit, index) => {
    // Double every second digit
    if (index % 2 === 0) {
      digit *= 2
      // If the product is 2 digits (i.e., >= 10), sum its digits (e.g., 16 -> 1 + 6 = 7)
      if (digit > 9) {
        digit -= 9
      }
    }
    return total + digit
  }, 0)

  // Step 2: Add the constant 24 as per NPI requirement
  const adjustedSum = sum + 24

  // Step 3: Calculate the check digit by subtracting the sum from the next higher number ending in zero
  const calculatedCheckDigit = (10 - (adjustedSum % 10)) % 10

  // Compare the calculated check digit with the provided check digit
  return calculatedCheckDigit === checkDigit
}

const exceedsSupplyDurationLimit = ({ durationDays, daysLimit }: { daysLimit?: number; durationDays?: number }) =>
  durationDays && daysLimit && durationDays > daysLimit

export {
  calculateAmountQuantity,
  calculateDosageDispenseInterval,
  calculateTotalDoseQuantity,
  exceedsSupplyDurationLimit,
  getDispenseFrequency,
  getGuidelineLabel,
  getMedicationFormData,
  getMedicationKnowledgeSku,
  getTreatmentFrequency,
  isValidNPIValue,
}
