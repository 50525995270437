import { asReference, MedicationKnowledge, Reference } from "fhir"
import { useFormikContext } from "formik"
import { useEffect, useMemo, useState } from "react"

import { SkeletonLoader } from "commons"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"
import { getCommonCode, getMedCodes } from "utils"

import { getInvCode } from "../../data"
import { useAlgorithmMKInventory, useProcedureCatalogs } from "../../hooks"
import { CalculatorOutput, InventoryData, ProcedureData } from "../../types"
import { NewMedData } from "../MedicationInventoryList"
import { pelletModel } from "../pelletModel"
import { getMedInitialValues } from "../validations"
import { CalculatorRecomendations } from "./CalculatorRecomendations"
import { SuggestedMedItem } from "./SuggestedMedItem"

const SuggestedMeds = ({ suggestedMeds, recommended, notes, setDisableSave }: Props) => {
  const medCodes = getMedCodes({ meds: suggestedMeds }) ?? []
  const recommendedMeds = useMemo(
    () => recommended.map(({ codeableConcept }) => getCommonCode({ codes: codeableConcept.coding })),
    [recommended],
  )
  const { patientId, patientRef } = usePatientContext()
  const { openEncounter } = useOpenEncounter(patientId)

  const { catalogs } = useProcedureCatalogs()
  const catalogId = catalogs?.[0]?.id as string

  const { inventory, isLoading } = useAlgorithmMKInventory(catalogId, medCodes, catalogId !== undefined)

  const { setSubmitting, setFieldValue } = useFormikContext<ProcedureData>()
  const [selectedMeds, setSelectedMeds] = useState<SuggestedNewMedData[]>([])

  useEffect(() => {
    if (!isLoading) {
      setSubmitting(false)
      recommendedMeds.length &&
        suggestedMeds
          .filter((mk) => recommendedMeds.includes(getCommonCode({ codes: mk.code?.coding })))
          .forEach((mk) => {
            processMKSelection(true, mk, 1)
          })
    }
  }, [isLoading, suggestedMeds, recommended, recommendedMeds])

  useEffect(() => {
    setDisableSave(selectedMeds.length === 0 || isLoading || !catalogId)
    if (!isLoading && selectedMeds.length) {
      const meds = selectedMeds.map(({ mk, catalogAuthor, invData, quantity }) => {
        const med = getMedInitialValues(
          mk,
          quantity,
          catalogAuthor,
          patientRef,
          invData,
          openEncounter && asReference(openEncounter),
        )
        return med
      })
      setFieldValue("configurationItem", meds)
    }
  }, [selectedMeds])

  const processMKSelection = (checked: boolean, mk: MedicationKnowledge, quantity: number) => {
    if (mk) {
      const medCode = getCommonCode({ codes: mk?.code?.coding })
      const mkCatalog = catalogs.find((c) => c.id === mk?.catalogHeader?.[0].id)
      const newMed = checked
        ? [
            {
              mk,
              catalogAuthor: mkCatalog?.author?.[0] as Reference,
              invData: inventory[getInvCode(mk?.code?.coding)] as InventoryData[],
              quantity,
            },
          ]
        : []
      setSelectedMeds((selectedMeds) => [
        ...selectedMeds.filter((med) => getCommonCode({ codes: med.mk.code?.coding }) !== medCode),
        ...newMed,
      ])
    }
  }

  return (
    <>
      {isLoading || !catalogId ? (
        <SkeletonLoader containerClassName="flex flex-col mt-4" loaderType="two-lines" repeats={5} />
      ) : (
        <div className="space-y-6 mt-4 overflow-y-auto">
          <CalculatorRecomendations notes={notes} recommended={recommended} />
          {!!suggestedMeds.length && (
            <div className="flex flex-col">
              <div className="font-medium text-gray-900 text-lg">Related meds</div>
              <div className="divide-y">
                {suggestedMeds.map((mk, index) => (
                  <SuggestedMedItem
                    key={index}
                    model={pelletModel({
                      medicationKnowledge: mk,
                      inStock: !!inventory[getInvCode(mk?.code?.coding)],
                    })}
                    disabled={!inventory[getInvCode(mk?.code?.coding)]}
                    checked={recommendedMeds.includes(getCommonCode({ codes: mk.code?.coding }))}
                    onChange={(s, q) => processMKSelection(s, mk, q)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

type Props = CalculatorOutput & {
  setDisableSave(state: boolean): void
}

export type SuggestedNewMedData = NewMedData & {
  quantity: number
}

export { SuggestedMeds }
