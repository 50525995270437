import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMountEffect } from "primereact/hooks"
import { Message } from "primereact/message"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { FormContainer, ModulesId } from "commons"
import { useAppModuleContext } from "modules"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { MouseEvent } from "react"

import { MedicationRequestFormData } from "../../types"
import { prescriptionValidationSchema } from "../validation"
import { PrescriptionForm } from "./PrescriptionForm"

const PrescriptionFormContainer = ({ initialValues, isEditing, onSubmit, onCancel }: Props) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { practitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const { patient } = usePatientContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const missingPatientAddress = !patient.address?.length

  useMountEffect(() => {
    if (isModuleActive(ModulesId.INTAKE) && !["allergies", "conditions"].includes(searchParams.get("kp") as string)) {
      searchParams.set("kp", "allergies")
      setSearchParams(searchParams)
    }
  })

  return (
    <div className="h-full">
      <FormContainer
        title={`${isEditing ? "Update" : "Create"} Prescription`}
        initialValue={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideButtonsDivider
        saveLabel={`${isEditing ? "Update" : "Create"} Prescription`}
        validationSchema={prescriptionValidationSchema(practitionersInfo)}
        innerContainerClassName="px-4 space-y-4 pb-6"
        footerClassName="border-t border-gray-200"
        disableSave={missingPatientAddress}
      >
        {missingPatientAddress && <MissingAddressWarningMessage />}
        <PrescriptionForm isEditing={isEditing} practitionersInfo={practitionersInfo} />
        {isModuleActive(ModulesId.INTAKE) && (
          <div className="flex mt-4 border-t pt-2 gap-6">
            <a
              className="flex items-center gap-2 p-3 text-primary font-medium text-sm hover:text-primary-hover group"
              href="?view=intake&subview=allergies"
              target="_blank"
              title="Open a new tab to perform this action"
            >
              <span className="border-2 border-dashed border-gray-300 flex h-8 w-8 items-center justify-center rounded-full text-gray-400 group-hover:border-gray-400 group-hover:text-gray-500">
                <FontAwesomeIcon icon={faPlus} className="h-5 w-5" />
              </span>
              Add new Patient Allergy
            </a>
            <a
              className="flex items-center gap-2 p-3 text-primary font-medium text-sm hover:text-primary-hover group"
              href="?view=intake&subview=conditions"
              target="_blank"
              title="Open a new tab to perform this action"
            >
              <span className="border-2 border-dashed border-gray-300 flex h-8 w-8 items-center justify-center rounded-full text-gray-400 group-hover:border-gray-400 group-hover:text-gray-500">
                <FontAwesomeIcon icon={faPlus} className="h-5 w-5" />
              </span>
              Add new Patient Condition
            </a>
          </div>
        )}
      </FormContainer>
    </div>
  )
}

const MissingAddressWarningMessage = () => {
  const { showModule } = useChartContext()

  const navToAddressSubview = (event: MouseEvent) => {
    event.preventDefault()
    showModule({ module: ModulesId.PATIENT, moduleParams: { subview: "address" } })
  }

  return (
    <div className="sticky top-0 z-20 bg-white py-2">
      <Message
        className="w-full"
        severity="warn"
        content={
          <div className="flex gap-2 text-sm">
            <span>In order to create a prescription the patient should have an address on file.</span>
            <button className="font-medium underline" onClick={navToAddressSubview}>
              Add it here.
            </button>
          </div>
        }
      />
    </div>
  )
}

type Props = {
  initialValues: MedicationRequestFormData
  isEditing?: boolean
  onSubmit(_: MedicationRequestFormData): void
  onCancel(): void
}

export { PrescriptionFormContainer }
