import { faCircleInfo, faSearch, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, FamilyMemberHistory, FamilyMemberHistoryConditionArray } from "fhir"
import { ErrorMessage, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { useEffect, useMemo } from "react"

import {
  AddFieldArrayItemButton,
  ConfirmDialog,
  DialogFormContainer,
  MenuStyles,
  StackedListContainer,
  StackedListItemProps,
} from "commons"
import { ReplacementProps, useCrudReducer, useReplceFormContext } from "commons/hooks"

import { FamilyHistoryConditionForm } from "./FamilyHistoryConditionForm"
import { familyHistoryConditionValidationSchema, getFamilyHistoryConditionsInitialValues } from "./valitations"

const FamilyHistoryConditions = () => {
  const {
    values: { condition },
    errors: { condition: fError },
    touched: { condition: fTouched },
    initialValues,
    setFieldValue,
  } = useFormikContext<FamilyMemberHistory>()

  const conditions = useMemo(() => condition || initialValues.condition || [], [condition])

  const { showSlide, initialValue, add, reset, deleteIndex, setDeleteIndex } = useCrudReducer({
    defaultEntity: { newCondition: getFamilyHistoryConditionsInitialValues() },
  })

  const addNewCondition = async ({ newCondition }: { newCondition: FamilyMemberHistoryConditionArray }) => {
    const newConditions = [...conditions, newCondition]

    await setFieldValue("condition", newConditions)
    reset()
  }

  const deleteCondition = async (deleteIndex: number) => {
    const newConditions = conditions.toSpliced(deleteIndex, 1)

    await setFieldValue("condition", newConditions)
  }

  const dialogFormProps = useMemo(() => {
    return {
      title: "Condition",
      showForm: showSlide,
      useFormik: true,
      initialValue,
      validationSchema: familyHistoryConditionValidationSchema,
      saveLabel: initialValue.newCondition?.id ? "Save" : "Add",
      children: <FamilyHistoryConditionForm fieldPath="newCondition" />,
      onCancel: reset,
      onSubmit: (data: { newCondition: FamilyMemberHistoryConditionArray }) => addNewCondition(data),
    } as ReplacementProps<{ newCondition: FamilyMemberHistoryConditionArray }>
  }, [showSlide])

  const dialogContext = useReplceFormContext<{ newCondition: FamilyMemberHistoryConditionArray }>()

  useEffect(() => {
    if (dialogContext) {
      dialogContext.setReplacementContent?.(dialogFormProps)
    }
  }, [dialogFormProps])

  return (
    <>
      <AddFieldArrayItemButton className="px-2 py-4" onClick={add} />
      {conditions.length > 0 ? (
        <StackedListContainer
          itemsClassName="px-2 py-4"
          data={conditions}
          itemModelBuilder={(item: FamilyMemberHistoryConditionArray, itemIndex) =>
            conditionModel(item, () => setDeleteIndex(itemIndex))
          }
        />
      ) : (
        <div
          className={classNames("flex flex-col items-center justify-center w-full py-5 my-2", {
            "border rounded-md border-red-600": fError && fTouched,
          })}
        >
          <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
          <p className="text-slate-500 text-xs pt-1">No conditions added yet</p>
        </div>
      )}
      <ErrorMessage name="condition">
        {(msg) =>
          typeof msg === "string" && (
            <small id="errorMessage.condition" className="p-error">
              {msg}
            </small>
          )
        }
      </ErrorMessage>
      {!dialogContext && <DialogFormContainer {...dialogFormProps} />}
      <ConfirmDialog
        confirmText="Are you sure you want to remove this condition?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={() => deleteCondition(deleteIndex as number)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </>
  )
}

const conditionModel = (condition: FamilyMemberHistoryConditionArray, onDelete: () => void): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        { name: "Code", value: codeableConceptAsString(condition.code), icon: faCircleInfo },
        ...(condition.onset?.Age?.value ? [{ name: "Age", value: condition.onset?.Age?.value + "yo" }] : []),
      ],
    },
    ...(condition.outcome
      ? [
          {
            lineItems: [{ name: "Outcome", value: codeableConceptAsString(condition.outcome) }],
          },
        ]
      : []),
  ],
  menu: [
    {
      label: "Delete",
      icon: <FontAwesomeIcon icon={faTrashCan} size="1x" className="mr-1" />,
      command: onDelete,
    },
  ],
  menuStyle: MenuStyles.ActionItems,
})

export { FamilyHistoryConditions }
