import { PlanDefinition, Reference } from "fhir"
import { useFormikContext } from "formik"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo, useState } from "react"

import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST, billingTypes } from "data"
import { useOrganizationContext } from "organization"

import { AddFieldArrayItemButton } from "../../../components/Buttons"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { StackedListContainer } from "../../../components/StackedListContainer"
import { FormField, FormFieldBaseProps } from "../../../forms/FormField"
import { useBloodDrawnTests } from "../../../hooks"
import { LaboratoryTest } from "../../../types"
import { usePlanDefinitionTests } from "../../hooks"
import { ComboDefinition, PanelItemDisplay, PlanData } from "../../types"
import { panelModelBuilder } from "../../utils"
import { ExtraPanelList } from "./ExtraPanelList"
import { ExtraPanelSelection } from "./ExtraPanelSelection"

const ExtraPanelField = ({ plan, containerClassName, ...formFielfProps }: Props) => {
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const [initialTests, setInitialTests] = useState<string[]>([])
  const [panelItems, setPanelItems] = useState<PanelItemDisplay[]>([])
  const [panelsToDel, setPanelsToDel] = useState<{ panels: PanelItemDisplay[]; autoDelete?: boolean }>({ panels: [] })
  const [showSlide, setShowSlide] = useState(false)

  const {
    values: { billingType, panels, combo, extraPlanDefinition, performer, bloodDrawnInOffice },
    setFieldValue,
    getFieldMeta,
  } = useFormikContext<PlanData>()

  const isInsurance = useMemo(() => billingType === BILLING_TYPES_CODES.INSURANCE, [billingType])

  const selectedCombo = useMemo(() => plan?.combos.find((c) => c.canonical === combo), [combo, plan])

  const { bloodDrawnTests, isLoading: isLoadingBDTests } = useBloodDrawnTests(
    currentOrganizationId,
    DEFAULT_BLOOD_DRAWN_PANELS_LIST,
  )
  const { labTests, isLoading } = usePlanDefinitionTests(currentOrganizationId, initialTests)

  const isDBIOPanelActive = useCallback(
    (pIdOrCode: string) =>
      !bloodDrawnInOffice &&
      !!(
        bloodDrawnTests &&
        performer?.id &&
        bloodDrawnTests[performer.id]?.some(
          ({ planDefinition }) =>
            planDefinition.id === pIdOrCode || planDefinition.identifier?.some(({ value }) => pIdOrCode === value),
        )
      ),
    [bloodDrawnTests, performer, bloodDrawnInOffice],
  )

  const getPDCanonical = (item: PlanDefinition) => `${item.url}|${item.version}`

  const onUpdatePanels = useCallback(
    ({
      newPanels,
      deletedPanels,
      deleteAll,
    }: {
      newPanels: LaboratoryTest[]
      deletedPanels: { id: string; index: number }[]
      deleteAll?: boolean
    }) => {
      let panelsAfterDelete = deleteAll
        ? []
        : panels?.filter((pCanonical) => !deletedPanels.some(({ id }) => id === pCanonical))
      const testCanonicalsToAdd = [] as string[]
      const { panelsToAdd, pDefinitions } = newPanels.reduce(
        (acc, test) => {
          const canonical = `${test.planDefinition.url}|${test.planDefinition.version}`
          testCanonicalsToAdd.push(canonical)

          return {
            panelsToAdd: [
              ...acc.panelsToAdd,
              {
                id: canonical,
                display: test.display,
                price: test.price,
                planDefinition: test.planDefinition,
                isCombo: false,
                questionnaires: test.questionnaires,
              } as PanelItemDisplay,
            ],
            pDefinitions: [...acc.pDefinitions, test.planDefinition],
          }
        },
        { panelsToAdd: Array<PanelItemDisplay>(), pDefinitions: Array<PlanDefinition>() },
      )
      // Add a track of PD from extra panels added. This PDs aren't in original CP
      // Allows to get the identifiers fom this extra panels and craft the panel codes
      setFieldValue("extraPlanDefinition", [...(extraPlanDefinition ?? []), ...pDefinitions])

      if ((!!selectedCombo?.canonical && deletedPanels.some(({ id }) => id === selectedCombo.canonical)) || deleteAll) {
        // Handle unselect combo
        setFieldValue("combo", undefined)
        // Remove all panels related to unselected combo
        panelsAfterDelete = panelsAfterDelete?.filter((p) => !selectedCombo?.canonicalPanels.includes(p))
      }
      setFieldValue("panels", [...(panelsAfterDelete ?? []), ...testCanonicalsToAdd])
      setPanelItems((pItems) => [
        ...pItems.filter(
          ({ planDefinition }) => !deletedPanels.some(({ id }) => id === getPDCanonical(planDefinition)),
        ),
        ...panelsToAdd,
      ])
    },
    [panels, selectedCombo, extraPlanDefinition],
  )

  useEffect(() => {
    if (selectedCombo) {
      setPanelItems((items) => [
        ...items.filter(
          (i) =>
            !i.isCombo &&
            panels?.includes(getPDCanonical(i.planDefinition)) &&
            getPDCanonical(i.planDefinition) !== selectedCombo.canonical,
        ),
        ...[
          {
            display: selectedCombo.definition.title ?? selectedCombo.definition.name ?? "",
            price: selectedCombo.price,
            isCombo: true,
            info: `${selectedCombo.panels.length} panels`,
            planDefinition: selectedCombo.definition,
            panels: selectedCombo.panels,
          } as PanelItemDisplay,
        ],
      ])
    } else
      setPanelItems((items) => items.filter((i) => !i.isCombo && panels?.includes(getPDCanonical(i.planDefinition))))
  }, [selectedCombo, panels])

  useEffect(() => {
    if (panels?.length) {
      const initialPanels = panels.filter(
        (p) => !selectedCombo?.canonicalPanels.includes(p) && p !== selectedCombo?.canonical,
      )
      setInitialTests(initialPanels)
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      const newPanelItems = [...(labTests ?? [])].map((test) => {
        return {
          display: test.display,
          price: test.price,
          isCombo: false,
          planDefinition: test.planDefinition,
        } as PanelItemDisplay
      })
      setPanelItems((items) => [...items, ...newPanelItems])
    }
  }, [labTests, isLoading])

  useEffect(() => {
    if (!performer?.id) onUpdatePanels({ newPanels: [], deletedPanels: [], deleteAll: true })
    else if (!bloodDrawnInOffice) {
      if (bloodDrawnTests && performer?.id && !isLoadingBDTests) {
        const testToAdd =
          bloodDrawnTests[performer?.id]?.filter(
            ({ planDefinition }) => !panels?.includes(getPDCanonical(planDefinition)),
          ) ?? []
        const toDel = panelItems
          .filter(({ planDefinition }) =>
            planDefinition.identifier?.some(({ value }) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(value as string)),
          )
          .map(({ planDefinition }, index) => {
            return { id: getPDCanonical(planDefinition), index }
          })

        if (testToAdd.length || toDel.length) onUpdatePanels({ newPanels: testToAdd, deletedPanels: toDel })
      }
    } else if (bloodDrawnTests && performer?.id) {
      const canonicalPanelsToDelete =
        bloodDrawnTests[performer?.id]?.map(({ planDefinition }, index) => {
          return {
            id: getPDCanonical(planDefinition),
            index,
          }
        }) ?? []
      if (canonicalPanelsToDelete.length) onUpdatePanels({ newPanels: [], deletedPanels: canonicalPanelsToDelete })
    }

    const comboD = plan?.combos?.find((c) => c.canonical === combo)
    if (performer && panelItems.length) {
      const pervPerformerId = comboD?.performer?.id ?? plan?.performer?.id
      if (pervPerformerId !== performer.id) {
        setPanelItems([])
        onUpdatePanels({
          newPanels: [...(!bloodDrawnInOffice ? (bloodDrawnTests?.[performer?.id as string] ?? []) : [])],
          deletedPanels: [],
          deleteAll: true,
        })
      }
    }
  }, [bloodDrawnInOffice, bloodDrawnTests, isLoadingBDTests, performer?.id])

  useEffect(() => {
    if (billingType !== BILLING_TYPES_CODES.INSURANCE && !isExemptLabPayment)
      setPanelsToDel({ panels: panelItems.filter((pd) => !pd.price), autoDelete: true })
  }, [billingType])

  const cancelRemovePanels = () => {
    if (panelsToDel.autoDelete) setFieldValue("billingType", "insurance")
    const pervPerformer = selectedCombo?.performer ?? plan?.performer
    if (performer?.id !== pervPerformer?.id && !panelsToDel.autoDelete) {
      setFieldValue("performer", pervPerformer)
    }
    setPanelsToDel({ panels: [] })
  }

  const removePanels = (toDelete: PanelItemDisplay[]) => {
    let updatedPanels = panels?.filter(
      (panel) => !toDelete.some((p) => `${p.planDefinition.url}|${p.planDefinition.version}` === panel),
    )

    if (toDelete.some((item) => item.isCombo)) {
      // Handle unselect combo
      setFieldValue("combo", undefined)
      // Remove all panels related to unselected combo
      updatedPanels = updatedPanels?.filter((p) => !selectedCombo?.canonicalPanels.includes(p))
    }
    setFieldValue("panels", updatedPanels)
    setPanelItems((items) => items.filter((i) => !toDelete.includes(i)))
    setPanelsToDel({ panels: [] })
  }

  const { touched, error } = getFieldMeta("panels")

  return (
    <div className="flex flex-col grow">
      <FormField field="panels" label="Additional Tests" labelAlign="items-start" {...formFielfProps}>
        {showSlide && (
          <ExtraPanelSelection
            extraPanels={panelItems}
            onHide={() => setShowSlide(false)}
            onSave={onUpdatePanels}
            selectedCombo={selectedCombo}
            selectedPerformer={performer}
            billingType={billingType as BILLING_TYPES_CODES}
            isReadonlyItem={isDBIOPanelActive}
          />
        )}
        <AddFieldArrayItemButton
          label="Select Additional Testing"
          onClick={() => setShowSlide(true)}
          disabled={!performer}
        />
        <ExtraPanelList
          panelItems={panelItems}
          onDelete={(item) => removePanels([item])}
          isLoading={isLoading}
          error={touched ? !!error : undefined}
          modelBuilder={panelModelBuilder}
          showPrice={!isExemptLabPayment}
          isReadonlyItem={isDBIOPanelActive}
          isInsurance={isInsurance}
          className={containerClassName}
        />
      </FormField>

      <ConfirmDialog
        headerTitle="Confirm panel deletion"
        actionName="Remove"
        visible={!!panelsToDel.panels.length}
        onConfirm={() => removePanels(panelsToDel.panels)}
        hideDialog={cancelRemovePanels}
        waitToFinish
        confirmElement={
          <div className="flex flex-col gap-2">
            {panelsToDel.autoDelete ? (
              <p className="font-semibold">
                As you specified{" "}
                <strong>
                  billing type as {billingTypes.find(({ code }) => code === billingType)?.display ?? billingType}
                </strong>
                , the following {pluralize("panel", panelsToDel.panels.length)} will be removed:
              </p>
            ) : (
              <p className="font-semibold">
                The following {pluralize("panel", panelsToDel.panels.length)} will be removed:
              </p>
            )}
            <StackedListContainer
              data={panelsToDel.panels}
              itemModelBuilder={(item) => panelModelBuilder({ item, readOnly: true, showPrice: !isExemptLabPayment })}
            />
          </div>
        }
      />
    </div>
  )
}

type Props = {
  plan?: { combos: ComboDefinition[]; performer?: Reference }
  containerClassName?: string
} & Omit<FormFieldBaseProps, "field">

export { ExtraPanelField }
