import { Dosage, MedicationKnowledge, MedicationKnowledgeAdministrationGuidelinesArray } from "fhir"
import { useFormikContext } from "formik"
import { FC } from "react"

import {
  AddressProvider,
  NumberField,
  PractitionerInfo,
  PractitionerRoleDropdownField,
  ReferenceDropdownField,
  TextAreaField,
} from "commons"
import { MEDICATIONS_REGULATIONS_CODE, getAdministrationGuideline } from "commons/meds"
import { useOrganizationContext } from "organization"

import { MedicationRequestFormData } from "../../types"
import { PrescriptionSigProvider } from "../prescriptions/context/PrescriptionSigContext"
import { AutocompleteMedicationField } from "./AutocompleteMedicationField"
import { DispenseRequestFields } from "./DispenseRequestFields"
import { PrescriptionDosages } from "./PrescriptionDosages"
import { PrescriptionPrices } from "./PrescriptionPrices"
import { PrescriptionShippingAddressField } from "./PrescriptionShippingAddressField"
import { PrescriptionUnitField } from "./PrescriptionUnitField"

const PrescriptionForm: FC<Props> = ({
  practitionersInfo,
  isEditing,
  allowedMedicationCodes,
  medRecommendedDosage,
  hidePrescriberField,
}) => {
  const { rxCatalogs } = useOrganizationContext()
  const { values, isSubmitting } = useFormikContext<MedicationRequestFormData>()
  const { medicationField } = values
  const hasPackaging = !!(medicationField as MedicationKnowledge)?.packaging?.type
  const catalogsAuthorRef = rxCatalogs.map((c) => c.author[0])
  const medicationDispenseLimit = (medicationField as MedicationKnowledge)?.regulatory?.find(
    (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DISPENSE,
  )?.maxDispense?.quantity?.value

  return (
    <>
      <div className="p-fluid relative border-b pb-4">
        <AutocompleteMedicationField
          field="medicationField"
          disabled={isEditing}
          label="Medication"
          allowedMedicationCodes={allowedMedicationCodes}
        />
      </div>
      <PrescriptionSigProvider
        medRecommendedDosage={medRecommendedDosage}
        getAdministrationGuideline={getAdministrationGuideline}
      >
        <PrescriptionDosages />
      </PrescriptionSigProvider>
      <div className="flex space-x-6 pb-4">
        {!hidePrescriberField && (
          <PractitionerRoleDropdownField
            field="requester"
            label="Prescriber"
            options={practitionersInfo ?? []}
            className="flex-1"
            disabled={isSubmitting}
            useFilter={false}
          />
        )}
        <ReferenceDropdownField
          className="flex-1"
          label="Pharmacy"
          options={catalogsAuthorRef}
          field="dispenseRequest.performer"
          filter={false}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex gap-y-3 gap-x-6 flex-wrap">
          <div className="flex flex-1 space-x-6">
            <NumberField
              field="dispenseRequest.quantity.value"
              label="Quantity"
              min={1}
              disabled={isSubmitting}
              max={medicationDispenseLimit}
              subMessage={
                medicationDispenseLimit
                  ? `Max dispense limit for these medication is (${medicationDispenseLimit})`
                  : undefined
              }
              className="w-full"
            />
            <PrescriptionUnitField disabled={hasPackaging} />
          </div>
          <DispenseRequestFields />
        </div>
        <PrescriptionPrices />
      </div>
      <AddressProvider>
        <PrescriptionShippingAddressField fieldName="dispenseRequest.shippingAddress" />
      </AddressProvider>

      <TextAreaField
        field="note[0].text"
        label="Note"
        rows={5}
        className="w-full mt-2"
        inputClassName="h-full"
        disabled={isSubmitting}
      />
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  isEditing?: boolean
  allowedMedicationCodes?: string[]
  medRecommendedDosage?: Record<string, Dosage[]>
  getAdministrationGuideline?: (
    mk: MedicationKnowledge,
    medRecommendedDosage?: Record<string, Dosage[]>,
  ) => MedicationKnowledgeAdministrationGuidelinesArray | undefined
  hidePrescriberField?: boolean
}

export { PrescriptionForm }
