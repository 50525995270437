import { format, parseISO } from "date-fns"
import { codeableConceptAsString } from "fhir"
import { classNames } from "primereact/utils"

import { formatsByTypes } from "data"
import { getMoneyCurrencyAlt } from "utils"

import { LabPanelDetailsComponent } from "../types"
import { ResultDetails } from "./ResultDetails"

const LabPanelDetails = ({ panel }: Props) => {
  const results = panel.results
  const hasFinalResults = results?.length && results?.every(({ status }) => status === "final")

  return (
    <div className="flex flex-col rounded-lg border p-4">
      <div className="flex flex-row justify-between items-baseline">
        <div className="flex flex-col">
          <div className="flex flex-row gap-2 items-baseline">
            <span
              className={classNames("rounded-full w-2 h-2", hasFinalResults ? "bg-green-500" : "bg-yellow-500")}
            ></span>
            <span className="font-medium text-primary break-words whitespace-normal text-sm text-wrap">
              {codeableConceptAsString(panel.code ?? panel.key)}
            </span>
          </div>
          <div className="flex gap-8 text-gray-500 text-sm">
            {!!panel.collected && (
              <span>Collected: {format(parseISO(panel.collected), formatsByTypes.SHORT_DATETIME)}</span>
            )}
            {!!panel.received && (
              <span>Received: {format(parseISO(panel.received), formatsByTypes.SHORT_DATETIME)}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col text-sm text-gray-500 text-right">
          <span>Tests: {panel.tests ?? 0}</span>
          <span>{`${getMoneyCurrencyAlt(panel?.price?.currency)}${(panel?.price?.value ?? 0).toFixed(2)}`}</span>
        </div>
      </div>
      {!!panel.totalResults && (
        <div className="flex flex-col border-t mt-4 pt-4 divide-y gap-5">
          {results?.map((observation) => <ResultDetails key={observation.key} labPanelResult={observation} />)}
        </div>
      )}
    </div>
  )
}

type Props = {
  panel: LabPanelDetailsComponent
}

export { LabPanelDetails }
