import { useInfiniteQuery } from "@tanstack/react-query"
import {
  getResources,
  humanNameAsString,
  MedicationAdministration,
  MedicationRequest,
  Practitioner,
  PractitionerRole,
  Procedure,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { ConfigurationItem, getProcedureKind, ProcedureData, ProcedureKind } from "commons/procedures"
import { SYSTEM_VALUES } from "system-values"

import { proceduresQueryKeys } from "../query-keys"

const usePatientProcedures = ({
  patientId,
  textFilter,
  statusFilter,
  encounter,
  procedureId,
}: PatientProceduresArgs) => {
  const { search } = useClient()
  const queryKey = proceduresQueryKeys.list(patientId, textFilter, statusFilter, encounter, procedureId)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PatientProceduresQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-createdAt",
        ...(statusFilter && statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        ...(textFilter ? { "code:text": textFilter } : {}),
        _revinclude: "MedicationAdministration:part-of",
        _include: "MedicationAdministration:request, performer",
        ...(encounter ? { encounter } : {}),
        ...(procedureId ? { _id: procedureId } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Procedure`, filters, signal })

      const procedures = getResources<Procedure>(bundle, "Procedure")
      const medicationAdministrations = getResources<MedicationAdministration>(bundle, "MedicationAdministration")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const practitionerRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        procedures,
        medicationAdministrations,
        medicationRequests,
        practitioners,
        practitionerRoles,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { proceduresData, count } = useMemo(() => {
    const procedures = data?.pages.flatMap((page) => page.procedures)
    const medicationAdministrations = data?.pages.flatMap((page) => page.medicationAdministrations)
    const medicationRequests = data?.pages.flatMap((page) => page.medicationRequests)
    const practitioners = data?.pages.flatMap((page) => page.practitioners)
    const practitionerRoles = data?.pages.flatMap((page) => page.practitionerRoles)

    const proceduresData = procedures?.reduce((acc, procedure) => {
      const isMassage = getProcedureKind(procedure) === ProcedureKind.massage
      let configItems: ConfigurationItem[] | undefined
      if (isMassage) {
        configItems = procedure.bodySite?.map(
          (cc) =>
            ({
              bodySite: cc,
              zone: cc.coding?.[1],
            }) as ConfigurationItem,
        )
      } else {
        configItems = medicationAdministrations?.reduce((acc, ma) => {
          if (ma.partOf?.[0].id === procedure.id) {
            const mr = medicationRequests?.find((mr) => mr.id === ma.request?.id)
            return [
              ...acc,
              {
                medicationAdministration: ma,
                medicationRequest: mr,
                bodySite: ma.dosage?.site,
                zone: ma.dosage?.site?.coding?.find(({ system }) => system === SYSTEM_VALUES.BODY_SITES),
                medTotalDose: ma.dosage?.dose,
              } as ConfigurationItem,
            ]
          }
          return acc
        }, [] as ConfigurationItem[])
      }

      const practitioner = practitioners?.find((pract) => pract.id === procedure.performer?.[0].actor.id)
      const practitionerRole = practitionerRoles?.find((practR) => practR.id === procedure.performer?.[0].actor.id)

      const newProcedure: ProcedureData = {
        procedure,
        configurationItem: configItems ?? [],
        performerName: practitionerRole?.practitioner?.display ?? humanNameAsString(practitioner?.name?.[0]),
        deletedMedications: [],
      }

      return [...acc, newProcedure]
    }, new Array<ProcedureData>())

    return {
      proceduresData: proceduresData ?? [],
      count: proceduresData?.length ?? 0,
    }
  }, [data?.pages])

  return {
    proceduresData,
    isLoading,
    count: count ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

interface PatientProceduresArgs {
  patientId: string
  textFilter?: string
  statusFilter?: string[]
  encounter?: string
  procedureId?: string
}

type PatientProceduresQueryData = {
  procedures: Procedure[]
  medicationAdministrations: MedicationAdministration[]
  medicationRequests: MedicationRequest[]
  practitioners: Practitioner[]
  practitionerRoles: PractitionerRole[]
  next: number | undefined
  total: number
}

export { usePatientProcedures }
