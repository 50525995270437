import {
  asReference,
  CodeableConcept,
  Coding,
  Dosage,
  Medication,
  MedicationAdministration,
  MedicationKnowledge,
  MedicationRequest,
  Observation,
  Patient,
  Quantity,
  Reference,
} from "fhir"
import * as Yup from "yup"

import { mrCategoryCodes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { isRefrigeratedMedicationKnowledge } from "utils"

import { getProcedureKind } from "../data"
import { bodyZonesCodes, ConfigurationItem, InventoryData, ProcedureData, ProcedureKind } from "../types"

const getInitialValues = (
  patient: Patient,
  icd10Codes: CodeableConcept[] = [],
  defaultRequeter?: Reference,
  encounter?: Reference,
  canonical?: string,
): ProcedureData => {
  return {
    procedure: {
      status: "preparation",
      subject: asReference(patient),
      code: undefined,
      instantiatesCanonical: canonical ? [canonical] : undefined,
      reasonCode: icd10Codes.map((code) => ({ coding: [code] })),
      note: [{ text: "" }],
      performed: {
        dateTime: new Date().toISOString(),
      },
      performer: [
        {
          actor: defaultRequeter ?? {},
          function: {
            text: "Healthcare professional",
            coding: [
              {
                code: "223366009",
                system: SYSTEM_VALUES.SNOMED_SCT,
                display: "Healthcare professional",
              },
            ],
          },
        },
      ],
      encounter,
    },
    configurationItem: [],
    deletedMedications: [],
  }
}

const getMAInitialValues = (
  patientRef: Reference,
  medicationKnowledge: MedicationKnowledge,
  unitCount: number,
  doseQuantity: Quantity,
  invData: InventoryData,
): MedicationAdministration => ({
  status: "preparation",
  subject: patientRef,
  performer: undefined,
  dosage: {
    dose: {
      ...doseQuantity,
      value: (doseQuantity?.value as number) * unitCount,
    },
    site: undefined,
  },
  partOf: undefined, //to be filled on save
  request: undefined, //to be filled on save
  reasonCode: [
    {
      coding: [
        {
          code: "b",
          system: SYSTEM_VALUES.MEDICATION_REASON_CODE,
          display: "Given as Ordered",
        },
      ],
    },
  ],
  effective: {
    Period: {
      start: new Date().toISOString(),
    },
  },
  medication: {
    Reference: {
      localRef: "med1",
    },
  },
  contained: [
    {
      id: "med1",
      code: medicationKnowledge.code,
      batch: invData,
      resourceType: "Medication",
    } as Medication,
  ],
})

const getMRInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  catalogAuthor: Reference,
  patientRef: Reference,
  encounterRef?: Reference,
): MedicationRequest => {
  const mkDefaultDosages = medicationKnowledge.administrationGuidelines?.[0].dosage?.reduce((prev, dosageArray) => {
    return [...prev, ...dosageArray.dosage]
  }, [] as Dosage[])

  const defaultQuantity = {
    value: dispenseQuantity,
    code: medicationKnowledge.packaging?.type?.coding?.[0].code,
    unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
    system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
  } as Quantity
  const currentDate = new Date().toISOString()

  const mrCategory = [{ coding: [mrCategoryCodes.procedure], text: mrCategoryCodes.procedure.display }]

  const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationKnowledge)

  if (isMKRefrigerated) {
    mrCategory.push({
      coding: [mrCategoryCodes.refrigerated],
      text: mrCategoryCodes.refrigerated.display,
    })
  }

  return {
    medication: { CodeableConcept: medicationKnowledge.code },
    status: "draft",
    intent: "order",
    authoredOn: currentDate,
    subject: patientRef,
    encounter: encounterRef ?? undefined,
    requester: undefined,
    recorder: undefined,
    performer: undefined,
    dosageInstruction: mkDefaultDosages,
    category: mrCategory,
    dispenseRequest: {
      performer: catalogAuthor,
      initialFill: {
        isDfo: true,
        quantity: defaultQuantity,
        duration: { value: 0, code: "d", unit: "day", system: SYSTEM_VALUES.UNITS_MEASURE },
      },
      nextRefillDate: currentDate,
      numberOfRepeatsAllowed: 0,
      quantity: defaultQuantity,
      dispenseInterval: { value: 0, code: "d", unit: "day", system: SYSTEM_VALUES.UNITS_MEASURE },
    },
  }
}

const getMedInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  catalogAuthor: Reference,
  patientRef: Reference,
  invData: InventoryData[],
  encounterRef?: Reference | null,
): ConfigurationItem => {
  const doseQuantity =
    medicationKnowledge.administrationGuidelines?.[0].dosage?.[0].dosage?.[0].doseAndRate?.[0].dose?.Quantity ?? {}

  return {
    medicationRequest: getMRInitialValues(
      medicationKnowledge,
      dispenseQuantity,
      catalogAuthor,
      patientRef,
      encounterRef ?? undefined,
    ),
    medicationAdministration: getMAInitialValues(
      patientRef,
      medicationKnowledge,
      dispenseQuantity,
      doseQuantity,
      invData?.[0],
    ),
    invData,
    medTotalDose: doseQuantity,
    ...getMassageInitialValues(),
  }
}

const getMassageInitialValues = (): ConfigurationItem => ({
  bodySite: { coding: [{ code: undefined }] },
  zone: bodyZonesCodes[0],
})

const sanitizeProcedureData = (
  procedure: ProcedureData,
  recorder: Reference,
  requester: Reference,
  performerPR: Reference,
  planId: string | null,
): ProcedureData => {
  const performer = procedure.procedure.performer?.[0].actor
  const isMassageProcedure = getProcedureKind(procedure.procedure) === ProcedureKind.massage

  if (!isMassageProcedure) {
    procedure.configurationItem = procedure.configurationItem.map((med) => ({
      medicationAdministration: {
        ...med.medicationAdministration,
        dosage: {
          ...med.medicationAdministration?.dosage,
          ...{ site: { ...med.bodySite, coding: [...(med.bodySite?.coding ?? []), ...(med.zone ? [med.zone] : [])] } },
        },
        performer: [{ actor: performer }],
        contained: [
          ...(med.medicationAdministration?.contained?.[0]
            ? [
                {
                  ...(med.medicationAdministration.contained[0] as Medication),
                  batch: {
                    lotNumber: (med.medicationAdministration.contained?.[0] as Medication)?.batch?.lotNumber,
                    expirationDate: (med.medicationAdministration.contained?.[0] as Medication)?.batch?.expirationDate,
                  },
                } as Medication,
              ]
            : []),
        ],
      } as MedicationAdministration,
      ...(med.medicationRequest
        ? {
            medicationRequest: {
              ...med.medicationRequest,
              performer: performerPR,
              requester,
              recorder,
            },
          }
        : {}),
    }))
  } else {
    procedure.procedure.bodySite = procedure.configurationItem.map(({ bodySite, zone }) => ({
      ...bodySite,
      coding: [...(bodySite?.coding ?? []), ...(zone ? [zone] : [])],
    }))
  }

  if (planId) {
    procedure.procedure.basedOn = [{ id: planId, resourceType: "CarePlan" }]
  }
  return procedure
}

const getObservationInitialValue = (
  patientRef: Reference,
  codes: Coding[],
  value: string,
  performer: Reference,
): Observation => ({
  category: [
    {
      text: "Laboratory",
      coding: [
        {
          code: "laboratory",
          system: SYSTEM_VALUES.OBSERVATION_CATEGORY,
          display: "Laboratory",
        },
      ],
    },
  ],
  value: !Number.isNaN(Number.parseFloat(value))
    ? {
        Quantity: {
          value: Number.parseFloat(value),
        },
      }
    : {
        string: value,
      },
  resourceType: "Observation",
  status: "final",
  code: { coding: codes },
  issued: new Date(),
  subject: patientRef,
  performer: [performer],
})

const procedureValidationSchema = Yup.object().shape({
  procedure: Yup.object().shape({
    instantiatesCanonical: Yup.array().required("Type is required"),
    code: Yup.object().test(
      "test-type",
      "Type is required",
      (value) => (value as CodeableConcept)?.coding?.[0]?.code !== undefined,
    ),
    reasonCode: Yup.array(),
    performer: Yup.array().of(
      Yup.object().shape({
        actor: Yup.object().test(
          "test-actor-performer",
          "Performer is required",
          (value: Reference) => value?.id !== undefined,
        ),
      }),
    ),
  }),
  configurationItem: Yup.array()
    .of(
      Yup.object().test(
        "test-batch",
        "Configure administration sites",
        (value) => !!(value as ConfigurationItem | undefined)?.bodySite?.coding?.[0]?.code,
      ),
    )
    .test("test-meds", (value, context) => {
      const proc = context.parent.procedure
      const msg = `At least one ${getProcedureKind(proc) === ProcedureKind.massage ? "massage" : "medication"} is required`
      return !!value?.length || new Yup.ValidationError(msg, value, context.path)
    }),
})

const procedureTypeValidation = Yup.object().shape({
  procedure: Yup.object().shape({
    instantiatesCanonical: Yup.array().required("Type is required"),
  }),
})

const configItemValidationSchema = Yup.object().shape({
  configurationItem: Yup.array()
    .of(
      Yup.object().test(
        "test-batch",
        "Configure administration sites",
        (value) => !!(value as ConfigurationItem | undefined)?.bodySite?.coding?.[0]?.code,
      ),
    )
    .test("test-meds", (value, context) => {
      const proc = context.parent.procedure
      const msg = `At least one ${getProcedureKind(proc) === ProcedureKind.massage ? "massage" : "medication"} is required`
      return !!value?.length || new Yup.ValidationError(msg, value, context.path)
    }),
})

export {
  configItemValidationSchema,
  getInitialValues,
  getMassageInitialValues,
  getMedInitialValues,
  getObservationInitialValue,
  procedureTypeValidation,
  procedureValidationSchema,
  sanitizeProcedureData,
}
