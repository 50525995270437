import { faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference } from "fhir"
import { FieldProps, FormikValues, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { useEffect, useState } from "react"

import { FormField, ReferenceDropdownField } from "commons"

import "./LabDataInputFieldList.css"

const LabDataInputFieldList = ({
  field,
  label,
  addFieldLabel = "Add item",
  fieldDefinitions,
  initialFields,
  performers,
  requiredFields = [],
}: Props) => {
  const [activeFields, setActiveFields] = useState<Array<OptionalFieldDefinition>>(requiredFields)
  const { setFieldTouched, setFieldValue, unregisterField, isSubmitting } = useFormikContext<FormikValues>()

  useEffect(() => {
    if (initialFields)
      setActiveFields([
        ...requiredFields,
        ...activeFields.filter((a) => initialFields.some((f) => f.field === a.field)),
        ...initialFields.filter((f) => !activeFields.some((a) => a.field === f.field)),
      ])
    else setActiveFields(requiredFields)
  }, [initialFields])

  useEffect(() => {
    isSubmitting && activeFields.forEach(({ field: fieldName }) => setFieldTouched(`${field}.${fieldName}`))
  }, [isSubmitting])

  const availableFields = fieldDefinitions.filter((fd) => activeFields.every((af) => af.field !== fd.field))

  const addField = (fieldData: OptionalFieldDefinition) => setActiveFields([fieldData, ...activeFields])

  const removeField = (fieldData: OptionalFieldDefinition) => {
    setActiveFields(activeFields.filter((af) => af.field !== fieldData.field))
    unregisterField(`${field}.${fieldData.field}`)
    setFieldValue(`${field}.${fieldData.field}`, "")
  }

  return (
    <FormField
      field={field}
      label={label}
      validation={(value) =>
        ((!value && !!requiredFields.length) ||
          activeFields.length !== Object.values(value ?? {}).filter((v) => !!v).length) &&
        "Lab data is required"
      }
      showInvalidState
    >
      <div className="flex flex-col divide-y space-y-2 w-full p-1 @container">
        {availableFields.length > 0 && (
          <div className="flex justify-between items-baseline">
            <Dropdown
              optionLabel="label"
              options={availableFields}
              onChange={(e) => addField(e.value)}
              filter={availableFields.length > 0}
              filterBy="label"
              className="p-inputtext-sm text-sm no-focusable border-0 ring-0 items-center hover:bg-inherit font-medium flex-row-reverse lab-data-field-list"
              placeholder={addFieldLabel}
              dropdownIcon="pi pi-plus"
            />
            {performers && performers.length > 0 && (
              <ReferenceDropdownField
                field="performer"
                label="Lab"
                options={performers}
                horizontal
                className="items-baseline p-1"
                inputClassName="max-w-56"
                validation={(value) => (activeFields.length && !value?.id ? "Performer is required" : undefined)}
                disabled={!!initialFields}
                showFilter={false}
              />
            )}
          </div>
        )}
        <div className="grid @2xl:grid-cols-2 gap-x-10 gap-y-4 pt-6">
          {activeFields.map((item) => (
            <FormField
              key={item.field}
              field={`${field}.${item.field}`}
              horizontal
              label={
                <span className="flex flex-col max-w-32">
                  <span className="text-gray-700 font-medium text-sm">{item.label}</span>
                  <span className="text-gray-400 text-xs">{item.field}</span>
                </span>
              }
              containerClassName="justify-center p-1"
            >
              {({ field: { name, value, onChange }, meta: { touched } }: FieldProps) => {
                const isRequired = requiredFields.some((f) => f.field === item.field)
                return (
                  <div className="flex gap-6 items-center justify-between w-full">
                    <InputText
                      aria-autocomplete="none"
                      type={"text"}
                      id={name}
                      name={name}
                      onChange={onChange}
                      value={value}
                      className={classNames("p-inputtext-sm horizontal", { "p-invalid": touched && !value })}
                    />
                    {!isRequired ? (
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="cursor-pointer"
                        title="Remove result"
                        onClick={() => removeField(item)}
                      />
                    ) : (
                      <span className="w-4"></span>
                    )}
                  </div>
                )
              }}
            </FormField>
          ))}
        </div>
      </div>
    </FormField>
  )
}

type Props = OptionalFieldDefinition & {
  addFieldLabel?: string
  fieldDefinitions: OptionalFieldDefinition[]
  initialFields?: OptionalFieldDefinition[]
  performers?: Reference[]
  requiredFields?: OptionalFieldDefinition[]
}

export type OptionalFieldDefinition = {
  field: string
  label: string
}

export { LabDataInputFieldList }
