import {
  faCalendarCheck,
  faCalendarDays,
  faFileInvoiceDollar,
  faPills,
  faSearch,
  faUser,
  faUserDoctor,
  faVials,
} from "@fortawesome/pro-solid-svg-icons"
import {
  EmptyMessage,
  InfiniteScroll,
  ModulesId,
  SearchInput,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
} from "commons"
import { format, parseISO } from "date-fns"
import { Reference, ServiceRequest, humanNameAsString } from "fhir"
import pluralize from "pluralize"
import { OverlayPanel } from "primereact/overlaypanel"
import { useCallback, useEffect, useId, useMemo, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { getStatus } from "commons/labs"
import { formatsByTypes, isLabOrder, isMedicationOrder } from "data"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { OrderFilter } from "organization-orders/types"
import { useOrganization } from "organization/hooks"
import { usePatient } from "patients"
import { formatDate, getBadgeColor, getServiceRequestBillingType, strCapitalize } from "utils"

import { useOrganizationOrders } from "../hooks"
import { getCleanDate, getCleanType, getStatusesByType, getStatusesClean } from "../utils"
import { OrderSearchWithFilters } from "./OrderSearchWithFilters"
import { OrdersFiltersFormContiner } from "./OrdersFiltersFormContainer"

const OrdersView = () => {
  const loaderKey = useId()
  const overlayFilter = useRef<OverlayPanel>(null)
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const { currentOrganizationId } = useOrganizationContext()
  const { practitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const searchText = params.get("search") ?? undefined
  const type = getCleanType(params.get("type") ?? "") ?? undefined
  const organizationId = params.get("organization") ?? undefined
  // const requester = params.get("requester") ?? undefined
  const patientId = params.get("patientId") ?? undefined
  const status = getStatusesClean(params.get("status")?.split(",") ?? [], getStatusesByType(type ?? "")) ?? undefined
  const authored = params.get("authored") ? getCleanDate(params.get("authored") ?? "") : undefined
  const occurrence = params.get("occurrence") ? getCleanDate(params.get("occurrence") ?? "") : undefined
  const [patient, setPatient] = useState<Reference>()
  const [organization, setOrganization] = useState<Reference>()

  useEffect(() => {
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    !type && params.delete("type")
    !authored && params.delete("authored")
    !occurrence && params.delete("occurrence")
    setParams(params)
  }, [])

  const { serviceRequests, count, total, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useOrganizationOrders(currentOrganizationId, type, patientId, status?.join(","), authored, occurrence, searchText)

  const { patient: patientData, isLoading: isLoadingPatient } = usePatient(patientId, !patient?.display)
  const { organization: org, isLoading: isLoadingOrg } = useOrganization(organizationId ?? "")

  useEffect(() => {
    if (!isLoadingPatient) {
      if (patientId && !patient?.display && patientData?.name?.[0]) {
        setPatient({ id: patientId, display: humanNameAsString(patientData?.name?.[0]) })
      } else if (!patient) {
        params.delete("patientId")
        setPatient(undefined)
        setParams(params)
      }
    }
  }, [patient?.display, patientData?.name, patientId, isLoadingPatient])

  useEffect(() => {
    if (!isLoadingOrg) {
      if (organizationId && !organization?.display && org.name) {
        setOrganization({ id: organizationId, display: org.name })
      } else if (!organization) {
        params.delete("organization")
        setOrganization(undefined)
        setParams(params)
      }
    }
  }, [organization?.display, org?.name, organizationId, isLoadingOrg])

  const practitionerRefs = useMemo(
    () =>
      practitionersInfo
        .reduce<
          Reference[]
        >((acc, pi) => [...acc, { id: pi.practitionerRoleRef?.id as string, display: humanNameAsString(pi.practitioner.name?.[0]) }], [])
        .sort((a, b) => a?.display?.localeCompare(b?.display ?? "") ?? -1),
    [practitionersInfo],
  )

  const setFilters = ({ searchText, type, patient, organization, status, authored, occurrence }: OrderFilter) => {
    searchText ? params.set("search", encodeURI(searchText)) : params.delete("search")
    type ? params.set("type", type) : params.delete("type")
    patient?.id ? params.set("patientId", patient?.id) : params.delete("patientId")
    organization?.id ? params.set("organization", organization.id) : params.delete("organization")
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    authored
      ? params.set("authored", formatDate(authored, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("authored")
    occurrence
      ? params.set("occurrence", formatDate(occurrence, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("occurrence")
    setPatient(patient)
    setOrganization(organization)
    setParams(params)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const showOrder = useCallback(
    (order: ServiceRequest) => {
      const commonBaseUrl = `/orgs/${currentOrganizationId}/patients/${order.subject.id}`
      if (isMedicationOrder(order)) {
        const isNutraOrder = order.orderDetail?.some(({ coding }) =>
          coding?.some(({ code }) => code === "nutraceutical-order"),
        )

        navigate(
          `${commonBaseUrl}?view=${isNutraOrder ? ModulesId.MEDICATIONR : ModulesId.EPRESCRIBE}&subview=${order?.status === "active" ? "orders" : "history"}&order=${order?.id}`,
        )
      } else if (isLabOrder(order)) navigate(`${commonBaseUrl}?view=labs&order=${order?.id}`)
    },
    [currentOrganizationId],
  )

  return (
    <>
      <div className="px-6 pt-3 border-b drop-shadow">
        <h6 className="font-medium text-lg">Orders</h6>
        <p className="text-sm text-gray-500">
          Showing {count} {pluralize("order", count)} of {total} found
        </p>
        <div className="flex py-4 w-full h-16 justify-between">
          <OrderSearchWithFilters
            showOverlaypanel={overlayFilter}
            filterNone={!type && !patient && !status && !organization && !authored && !occurrence}
            showSearchInput={false}
            customSearchInput={
              <SearchInput
                className="flex-1"
                search={(searchText) => {
                  setFilters({
                    searchText: searchText,
                    type,
                    patient,
                    organization,
                    status,
                    authored,
                    occurrence,
                  })
                }}
                isLoading={isLoading}
              />
            }
            isLoading={isLoading || isFetchingNextPage}
            formContent={
              <OrdersFiltersFormContiner
                initialValues={{
                  type,
                  patient,
                  organization,
                  searchText,
                  status,
                  authored,
                  occurrence,
                }}
                onSearch={(filters) => {
                  setFilters(filters)
                  overlayFilter?.current?.hide()
                }}
                onClearFilters={() => {
                  setFilters({})
                  overlayFilter?.current?.hide()
                }}
              />
            }
          />
        </div>
      </div>
      {isLoading ? (
        loader()
      ) : !serviceRequests.length ? (
        <EmptyMessage icon={faSearch} message="No Orders Found" subMessage={false} />
      ) : (
        <div className="flex flex-col overflow-auto h-full">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <StackedListContainer
              data={serviceRequests}
              itemModelBuilder={(item) => modelBuilder(item, () => showOrder(item), practitionerRefs)}
            />
          </InfiniteScroll>
        </div>
      )}
    </>
  )
}

const modelBuilder = (
  order: ServiceRequest,
  showOrder: () => void,
  practitionerRefs: Reference[],
): StackedListItemProps => {
  const isMedicationRequest = isMedicationOrder(order)
  const labOrderStatus = isMedicationRequest ? order.status : getStatus(order)?.display
  const itemsCount =
    order.basedOn?.filter((ref) =>
      isMedicationRequest ? ref.resourceType === "MedicationRequest" : ref.resourceType === "ServiceRequest",
    ) ?? []

  const orderRequester =
    practitionerRefs.find((practitionerRef) => practitionerRef.id === order.requester?.id)?.display ??
    order?.requester?.display ??
    "Unspecified requester"
  const orderBillingTypeDisplay = strCapitalize(getServiceRequestBillingType(order).replace("bill-", ""))

  const data = isMedicationRequest
    ? [
        {
          lineItems: [
            {
              name: "Order number",
              value: order.identifier?.[0]?.value ?? "Unspecified number",
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: order.subject.display, icon: faUser },
            { name: "Requester", value: orderRequester, icon: faUserDoctor },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Authored On",
              value: order?.authoredOn
                ? format(new Date(parseISO(order?.authoredOn)), formatsByTypes.LONG_DATE)
                : "Unspecified",
              icon: faCalendarDays,
            },
            ...(order?.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.occurrence?.dateTime), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),

            { name: "Medication Requests", value: `${itemsCount.length}`, icon: faPills },
          ],
        },
      ]
    : [
        {
          lineItems: [{ name: "Laboratory", value: strCapitalize(order.performer?.[0]?.display as string) }],
        },
        {
          lineItems: [
            { name: "Patient", value: order.subject.display, icon: faUser },
            { name: "Requester", value: orderRequester, icon: faUserDoctor },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Authored on",
              value: order.authoredOn ? format(parseISO(order.authoredOn), formatsByTypes.LONG_DATE) : "Unspecified",
              icon: faCalendarDays,
            },
            ...(order.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.occurrence?.dateTime), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),
            { name: "Tests", value: `${itemsCount.length}`, icon: faVials },
          ],
        },
      ]

  return {
    leftData: data,
    badge: getBadgeColor(labOrderStatus ?? "unspecified"),
    onClick: showOrder,
  }
}

export { OrdersView }
