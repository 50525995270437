import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "./constants"
import {
  Address,
  Bundle,
  CodeableConcept,
  ContactPoint,
  HumanName,
  isComposition,
  isConsent,
  isCoverage,
  isDevice,
  isLocation,
  isOrganization,
  isPatient,
  isPractitioner,
  isPractitionerRole,
  isResourceObject,
  Reference,
  ResourceObject,
} from "./fhir"

const getResources = <T extends ResourceObject>(bundle: Bundle, resourceType?: string) =>
  bundle.entry?.reduce((acc, { resource }) => {
    if (isResourceObject(resource)) {
      if (resourceType) return resourceType === resource.resourceType ? [...acc, resource as T] : [...acc]
      else return [...acc, resource as T]
    }

    return acc
  }, [] as T[]) ?? []

const getResource = <T extends ResourceObject>(bundle: Bundle, resourceType: string) =>
  bundle.entry?.find(({ resource }) => isResourceObject(resource) && resource.resourceType === resourceType)
    ?.resource as T

const getResourcesByTypeAsIndex = <T extends ResourceObject>(
  bundle: Bundle,
  resourceType: string,
  getIndex?: (resource: T) => string | undefined,
) =>
  bundle.entry?.reduce(
    (acc, { resource }) => {
      if (isResourceObject(resource) && resource.id && resource.resourceType === resourceType) {
        const index = getIndex?.(resource as T) ?? resource.id
        return { ...acc, [index]: resource as T }
      }

      return acc
    },
    {} as Record<string, T>,
  ) ?? {}

const getResourcesGrouped = <T extends ResourceObject>(
  bundle: Bundle,
  resourceType: string,
  getIndex: (resource: T) => string,
) =>
  bundle.entry?.reduce(
    (acc, { resource }) => {
      if (isResourceObject(resource) && resource.id && resource.resourceType === resourceType) {
        const index = getIndex(resource as T)
        return { ...acc, [index]: [...(acc[index] ?? []), resource as T] }
      }

      return acc
    },
    {} as Record<string, T[]>,
  ) ?? {}

const humanNameAsString = (name: HumanName | undefined, fallback: string = "No name provided"): string => {
  if (!name) {
    return fallback
  }

  if (name.text) {
    return name.text.trim()
  }

  const given = name?.given ? `${name?.given.join(" ")} ` : ""

  return `${given}${name.family}`
}

const getFirstEmail = (telecom: ContactPoint[] | undefined) => getFirstEmailNoFallback(telecom) ?? "No email provided"

const getFirstEmailNoFallback = (telecom: ContactPoint[] | undefined) =>
  telecom?.find(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)?.value

const getFirstPhone = (telecom: ContactPoint[] | undefined) => getFirstPhoneNoFallback(telecom) ?? "No phone provided"

const getFirstPhoneNoFallback = (telecom: ContactPoint[] | undefined) => {
  const phone = telecom?.find(({ system }) => system === PHONE_CONTACT_POINT_SYSTEM)
  const matches = phone?.value?.match(/^(\d{3})-?(\d{3})-?(\d{4})$/)
  if (matches && matches.length === 4) return `(${matches[1]}) ${matches[2]}-${matches[3]}`
  return phone?.value
}

const getAddress = (address: Address[] | undefined) => {
  if (!address?.[0]) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address?.[0] ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const codeableConceptAsString = (cc: CodeableConcept | undefined) => {
  if (!cc?.coding?.[0]?.code) {
    return cc?.text ?? "unspecified"
  }

  return cc.text ?? cc.coding?.[0]?.display ?? cc.coding?.[0]?.code
}

const asReference = (resource: ResourceObject | Reference): Reference => {
  let display

  if (isPatient(resource) || isPractitioner(resource)) {
    display = humanNameAsString(resource.name?.[0])
  }

  if (isPractitionerRole(resource)) {
    display = codeableConceptAsString(resource.code?.[0])
  }

  if (isOrganization(resource) || isLocation(resource)) {
    display = resource.name
  }

  if (isComposition(resource)) {
    display = resource.title
  }

  if (isCoverage(resource)) {
    display = resource.type?.coding?.[0].display
  }

  if (isConsent(resource)) {
    display = resource.category?.[0].coding?.[0].display
  }

  if (isDevice(resource)) {
    display = resource.deviceName?.[0]?.name
  }

  if (!display) display = (resource as Reference).display

  return { id: resource.id, resourceType: resource.resourceType, ...(display ? { display } : {}) }
}

export {
  asReference,
  codeableConceptAsString,
  getAddress,
  getFirstEmail,
  getFirstEmailNoFallback,
  getFirstPhone,
  getFirstPhoneNoFallback,
  getResource,
  getResources,
  getResourcesByTypeAsIndex,
  getResourcesGrouped,
  humanNameAsString,
}
