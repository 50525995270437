import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CarePlan,
  CarePlanActivityArray,
  codeableConceptAsString,
  PlanDefinitionActionArrayActionArray,
  Procedure,
  Task,
} from "fhir"
import { RadioButton } from "primereact/radiobutton"
import { FC, useEffect, useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import { useAppModuleContext } from "internals"
import { useUpdateTask } from "tasks"
import { getBadgeColor } from "utils"

import { Badge } from "../../../components/Badge"
import { ModulesId } from "../../../Module"
import { CalculatorOutput, CalculatorRecomendations, ProcedureCalculator, ProcedureFormKind } from "../../../procedures"
import { usePlanContext, useUpdatePlanActivity } from "../../hooks"
import { plansQueryKeys } from "../../query-keys"

const CPProcedureDetails: FC<Props> = ({
  carePlan,
  procedure,
  procedureAction,
  candidateTask,
  showProcedureCalculator,
  generatedCalculatorResult,
}) => {
  const { patientId, completedReviews } = usePlanContext()
  const { isModuleActive } = useAppModuleContext()
  const { hasGP } = useChartContext()
  const [pCandidateTask, setpCandidateTask] = useState(candidateTask)
  const [showCalculator, setShowCalculator] = useState(false)
  const [showCandidateOptions, setShowCandidateOptions] = useState(true)

  const { updateTask } = useUpdateTask({ queryKeysToInvalidate: [plansQueryKeys.details(patientId)] })
  const { updatePlanActivity } = useUpdatePlanActivity(patientId)

  useEffect(() => {
    setpCandidateTask(candidateTask)
  }, [candidateTask?.status])

  const allCompletedReviews = useMemo(
    () => pCandidateTask?.dependsOn?.every(({ id }) => completedReviews?.includes(id as string)),
    [pCandidateTask?.dependsOn, completedReviews],
  )

  const procedureActivityIndex = useMemo(
    () =>
      carePlan?.activity?.findIndex(({ outcomeCodeableConcept }) =>
        outcomeCodeableConcept?.[0]?.coding?.some(({ code }) => code === "procedure"),
      ) ?? -1,
    [carePlan.activity],
  )

  const procedureActivity = carePlan.activity?.[procedureActivityIndex]

  const updateCandidate = (isCandidate: boolean) => {
    if (!isCandidate) {
      const updatedActivities = carePlan.activity?.reduce((acc, act, index) => {
        if (index === procedureActivityIndex) return [...acc, { ...act, enabled: false }]
        return [...acc, act]
      }, Array<CarePlanActivityArray>())
      if (updatedActivities) updatePlanActivity({ planId: carePlan.id as string, activity: updatedActivities })
    }
    const updatedTask = { ...(pCandidateTask as Task), ...{ status: isCandidate ? "completed" : "rejected" } }
    updateTask(updatedTask)
    setpCandidateTask(updatedTask)
  }

  const isProcedureModuleActive = isModuleActive(ModulesId.PROCEDURES)
  const disableCandidateOptions =
    !allCompletedReviews ||
    pCandidateTask?.status === "rejected" ||
    !!procedure ||
    carePlan.status !== "active" ||
    !hasGP

  useEffect(() => {
    if (procedureActivity?.enabled && pCandidateTask?.status === "completed" && !!generatedCalculatorResult)
      setShowCalculator(true)
  }, [procedureActivity?.enabled, pCandidateTask?.status, generatedCalculatorResult])

  return (
    <div className="flex flex-col flex-1 h-full p-3">
      {!!pCandidateTask && showCandidateOptions && (
        <div className="mb-4 pb-3 flex w-full space-x-10 text-sm border-b last:border-none">
          <span className="text-sm text-gray-900 mr-12">{pCandidateTask.description}</span>
          <div key={`is-candidate-${pCandidateTask.id}`} className="flex items-center">
            <RadioButton
              inputId={`is-candidate-${pCandidateTask.id}`}
              name="Yes"
              value={true}
              radioGroup={`candidate-${pCandidateTask.id}`}
              checked={pCandidateTask.status === "completed"}
              onChange={(e) => updateCandidate(e.value)}
              disabled={disableCandidateOptions}
            />
            <label htmlFor={`is-candidate-${pCandidateTask.id}`} className="ml-2">
              Yes
            </label>
          </div>
          <div key={`not-candidate-${pCandidateTask.id}`} className="flex items-center">
            <RadioButton
              inputId={`not-candidate-${pCandidateTask.id}`}
              name="No"
              value={false}
              radioGroup={`candidate-${pCandidateTask.id}`}
              checked={pCandidateTask.status === "rejected"}
              onChange={(e) => updateCandidate(e.value)}
              disabled={disableCandidateOptions}
            />
            <label htmlFor={`not-candidate-${pCandidateTask.id}`} className="ml-2">
              No
            </label>
          </div>
        </div>
      )}

      {procedure ? (
        <div className="flex flex-col gap-6">
          {!!generatedCalculatorResult && (
            <CalculatorRecomendations
              notes={generatedCalculatorResult?.notes}
              recommended={generatedCalculatorResult?.recommended}
            />
          )}
          <div className="flex-flex-col">
            <div className="font-medium text-gray-900">Procedure</div>
            <div className="flex justify-between pt-2">
              <div className="flex w-1/2 justify-between">
                <span className="text-sm text-gray-900">{codeableConceptAsString(procedure.code)}</span>
                <Badge className="flex-shrink-0 lowercase" {...getBadgeColor(procedure.status)} />
              </div>
              {procedure?.id && (
                <FontAwesomeIcon
                  icon={faExternalLink}
                  className="cursor-pointer text-gray-500 mr-4"
                  title="Show procedure"
                  onClick={() => showProcedureCalculator(procedure?.id)}
                />
              )}
            </div>
          </div>
        </div>
      ) : isProcedureModuleActive &&
        hasGP &&
        carePlan?.id &&
        carePlan.status === "active" &&
        candidateTask?.status === "completed" ? (
        <>
          {showCalculator ? (
            <ProcedureCalculator
              carePlanId={carePlan.id as string}
              procedurePdCanonical={procedureAction?.definition?.canonical}
              onFinish={() => {
                setShowCalculator(false)
              }}
              generatedCalculatorResult={generatedCalculatorResult}
              hideCloseButton
              onCurrentFormChanged={(currentFormType) =>
                setShowCandidateOptions(currentFormType === ProcedureFormKind.suggestedMeds)
              }
            />
          ) : (
            <p className="text-sm text-gray-600">
              Awaiting recommendations for this treatment plan. Please answer the required questionnaires and wait for
              laboratory results if applicable.
            </p>
          )}
        </>
      ) : (
        <span className="text-sm text-gray-900 mt-3.5">
          You can't perform a procedure
          {!isProcedureModuleActive
            ? ", practice not allowed to perform procedures or"
            : candidateTask?.status !== "completed"
              ? " when patient isn't a candidate for this"
              : carePlan.status === "revoked"
                ? " on a cancelled"
                : carePlan.status.replace("-", " ")}{" "}
          treatment plan
        </span>
      )}
    </div>
  )
}

type Props = {
  carePlan: CarePlan
  procedureAction?: PlanDefinitionActionArrayActionArray
  procedure?: Procedure
  candidateTask?: Task
  generatedCalculatorResult?: CalculatorOutput
  showProcedureCalculator(cpId?: string): void
}

export { CPProcedureDetails }
