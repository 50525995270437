import { FamilyMemberHistory } from "fhir"
import { FC, useId } from "react"

import {
  ConfirmDialog,
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { ReplceFormProvider } from "commons/context"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useCreateFamilyHistory, useDeleteFamilyHistory, useFamilyHistory, useUpdateFamilyHistory } from "../hooks"
import { FamilyHistoryForm } from "./FamilyHistoryForm"
import { familyHistoryModelBuilder } from "./familyHistoryModelBuilder"
import { familyHistoryValidationSchema, getInitialValues, sanitize } from "./valitations"

const FamilyHistoryContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { reset, add, edit, setDeleteIndex, showSlide, initialValue, isNew, deleteIndex } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })
  const { familyMemberHistories, isLoading, hasNextPage, fetchNextPage } = useFamilyHistory(patientId)

  const { createFamilyHistory } = useCreateFamilyHistory(reset, patientId)
  const { updateFamilyHistory } = useUpdateFamilyHistory(reset, patientId)
  const { removeFamilyHistory, isDeleting } = useDeleteFamilyHistory(patientId, () => setDeleteIndex(undefined))

  const onSubmit = (familyHistory: FamilyMemberHistory) => {
    isNew ? createFamilyHistory(sanitize(familyHistory)) : updateFamilyHistory(sanitize(familyHistory))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  return (
    <ReplceFormProvider>
      <DataContainerSlideoverForm
        messageDataNotFound="No family histories found"
        hasData={!!familyMemberHistories?.length}
        showSlide={showSlide}
        formTitle="Family Member History"
        formInitialValue={initialValue}
        validationSchema={familyHistoryValidationSchema}
        onSubmit={onSubmit}
        onCancel={reset}
        form={<FamilyHistoryForm />}
        onButtonAddClick={add}
        iconDataNotFound={appSubModules["intake"][ModulesId.FAMILY_HISTORY].getIcon()}
      >
        <div className="bg-white h-full overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <StackedListContainer
              data={familyMemberHistories ?? []}
              itemModelBuilder={(item) =>
                familyHistoryModelBuilder(
                  item,
                  () => edit(item),
                  () => setDeleteIndex(item.id),
                )
              }
            />
          </InfiniteScroll>
        </div>
        <ConfirmDialog
          confirmText={`Are you sure you want to remove this family history?`}
          actionName="Remove"
          visible={deleteIndex !== undefined || isDeleting}
          isLoading={isDeleting}
          onConfirm={() => removeFamilyHistory(deleteIndex as string)}
          hideDialog={() => setDeleteIndex(undefined)}
        />
      </DataContainerSlideoverForm>
    </ReplceFormProvider>
  )
}

export { FamilyHistoryContainer }
