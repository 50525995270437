import { faCancel, faCircleInfo, faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import {
  Account,
  AccountBETACreditCardArray,
  Address,
  Organization,
  Patient,
  codeableConceptAsString,
  humanNameAsString,
  isOrganization,
  isPatient,
} from "fhir"
import pluralize from "pluralize"
import { FC, useEffect, useMemo } from "react"

import { useAccountCreditCards } from "account"
import { AppSubModules } from "app-modules"
import { useChartContext } from "chart-view"
import { ModulesId, SkeletonLoader } from "commons"
import { useReaders } from "commons/readers"
import { creditCardTypes } from "data"
import { useOrganizationContext } from "organization"
import { formatDate, getMoneyCurrencyAlt, strCapitalize } from "utils"

import { useCPOERequestsContext, useInvoicePreview } from "../hooks"
import { CustomInvoiceData } from "../types"
import { getSummaryParameter } from "../utils"
import { OrderSummary } from "./OrderSummary"

const OrdersFinish: FC = () => {
  const { showSubModule } = useChartContext()
  const {
    setProcessEnabled,
    setIsProcessingActions,
    requestGroup,
    patientId,
    patient,
    setAddressErrorMessage,
    readerAccount,
    activeRequestsInfo: { hasInsuranceLabsRequest, hasNutraRequest, hasMedsRequest },
    checkoutAddressInfo: { labsShippingAddress, nutrasShippingAddress },
  } = useCPOERequestsContext()
  const { currentOrganizationCCAccount, currentOrganization } = useOrganizationContext()
  const { defaultCreditCard, isLoading: isLoadingCC } = useAccountCreditCards(patientId)

  const { invoiceData, error, isLoadingPreview } = useInvoicePreview(patientId, requestGroup)

  const practiceInvoice = invoiceData?.find(({ invoice }) => isOrganization(invoice?.recipient))
  const patientInvoice = invoiceData?.find(({ invoice }) => isPatient(invoice?.recipient))
  const missingOrganizationCreditCard = !!practiceInvoice && !currentOrganizationCCAccount?.creditCard
  const missingPatientCreditCard = !!patientInvoice && !defaultCreditCard

  useEffect(() => {
    setIsProcessingActions(true)
  }, [])

  useEffect(() => {
    if (!isLoadingPreview && !isLoadingCC) {
      if (error) {
        if (error?.cause?.message.includes("Zip is not valid for the state")) {
          setAddressErrorMessage("Zip code is not valid for the state")
          showSubModule({ subModule: AppSubModules.checkout[ModulesId.CHECKOUT_CONFIG] })
        } else {
          setProcessEnabled(false)
        }
      } else {
        const hasInvoice = invoiceData?.length && invoiceData.every(({ invoice }) => invoice !== undefined)
        if (hasInvoice && !readerAccount && (missingOrganizationCreditCard || missingPatientCreditCard)) {
          setProcessEnabled(false)
        } else {
          setProcessEnabled(true)
        }
      }
      setIsProcessingActions(false)
    }
  }, [invoiceData, error, missingPatientCreditCard, missingOrganizationCreditCard, readerAccount])

  if (isLoadingPreview || isLoadingCC) return <SkeletonLoader repeats={4} loaderType="two-lines" />
  else if (error) {
    return (
      <div className="flex flex-col h-full justify-center items-center ">
        <FontAwesomeIcon icon={faCancel} className="text-[4rem] text-red-400" />
        <div className="px-4 text-red-400 text-center mt-5">
          Oops! Somethig went wrong trying to show the invoice preview
        </div>
        <small className="p-error mt-5">{error.cause?.message ?? "No message"}</small>
      </div>
    )
  } else if (!invoiceData?.length) {
    return (
      <div className="flex  flex-col h-full justify-center items-center">
        <FontAwesomeIcon icon={faCircleInfo} className="text-[4rem] text-blue-400" />
        <div className="px-4 text-primary text-center mt-5">Thank you for your order</div>
        <small className="text-gray-500">Remember to process your items</small>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <InvoicePreviewSection
        invoiceData={practiceInvoice as CustomInvoiceData}
        isPracticeInvoice
        selectedCreditCard={defaultCreditCard}
        patient={patient}
        organization={currentOrganization}
        organizationCreditCard={currentOrganizationCCAccount?.creditCard?.[0]}
        labsShippingAddress={labsShippingAddress}
        nutrasShippingAddress={nutrasShippingAddress}
        hasMedicationItems={hasMedsRequest}
        showLabsAddress={hasInsuranceLabsRequest}
        showMedsAddress={hasNutraRequest}
      />
      <InvoicePreviewSection
        invoiceData={patientInvoice as CustomInvoiceData}
        selectedCreditCard={defaultCreditCard}
        patient={patient}
        readerAccount={readerAccount}
        organization={currentOrganization}
        labsShippingAddress={labsShippingAddress}
        nutrasShippingAddress={nutrasShippingAddress}
        hasMedicationItems={hasMedsRequest}
        showLabsAddress={hasInsuranceLabsRequest}
        showMedsAddress={hasNutraRequest}
      />
    </div>
  )
}

const InvoicePreviewSection: FC<
  Props & {
    invoiceData?: CustomInvoiceData
    isPracticeInvoice?: boolean
    patient: Patient
    readerAccount?: Account
  }
> = ({
  invoiceData,
  hasMedicationItems,
  selectedCreditCard,
  labsShippingAddress,
  nutrasShippingAddress,
  showLabsAddress,
  showMedsAddress,
  isPracticeInvoice,
  organization,
  organizationCreditCard,
  patient,
  readerAccount,
}) => {
  const { readers, locationsByReader } = useReaders(organization.id as string)
  const readerInfo = useMemo(() => {
    if (readerAccount) {
      const reader = readers?.find(({ id }) => readerAccount.subject?.[0]?.id === id)
      const locationStr = reader && locationsByReader?.[reader.id ?? ""]?.name
      const typeStr = codeableConceptAsString(readerAccount.type)

      return (
        <div className="flex flex-col">
          <p>{typeStr}</p>
          <p className="overflow-hidden text-ellipsis line-clamp-1 break-words">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            {`${reader?.deviceName?.[0]?.name} | ${locationStr}`}
          </p>
        </div>
      )
    }
  }, [readerAccount, readers, locationsByReader])

  if (!invoiceData) return null

  const currency = getMoneyCurrencyAlt(invoiceData?.invoice?.totalNet?.currency)
  const summary = invoiceData?.summary
  const paymentCreditCard = isPracticeInvoice ? organizationCreditCard : selectedCreditCard
  const hasLabsRequest = !!invoiceData.items?.laboratory?.length
  const hasMedsRequest = !!invoiceData.items?.nutraceutical?.length || !!invoiceData.items?.medication?.length

  return (
    <div className="px-3 first-of-type:border-t-0">
      <h3 className="text-sm font-medium inline-flex justify-between w-full items-center space-x-3">
        <span className="text-gray-700">{isPracticeInvoice ? "Bill to Practice" : "Bill to Patient"}</span>{" "}
        <hr className="border flex-1" />
      </h3>
      <div className="pl-2 py-3 flex flex-col text-sm">
        <div className="flex border-b justify-between flex-col">
          <div className="w-1/3 ">Information</div>
          <div className="flex-1 w-full">
            <div className="flex justify-between pb-2 border-b">
              <div className="font-light mr-2">Date</div>
              <div>
                {invoiceData?.invoice?.date
                  ? formatDate(parseISO(invoiceData.invoice?.date), "MM/dd/yy")
                  : "Unspecified"}
              </div>
            </div>

            <div className="flex justify-between py-2 border-b">
              <div className="font-light mr-2">Name</div>
              <div className="text-right">
                <div>{isPracticeInvoice ? organization.name : humanNameAsString(patient.name?.[0])}</div>
              </div>
            </div>

            <AddressItem
              label="Ship medications to"
              show={showMedsAddress && hasMedsRequest}
              address={nutrasShippingAddress}
            />

            {!isPracticeInvoice && (
              <AddressItem
                label="Ship laboratories to"
                show={showLabsAddress && hasLabsRequest}
                address={labsShippingAddress}
              />
            )}

            <div className="flex py-2 justify-between">
              <div className="font-light mr-2 text-nowrap">Payment Method</div>
              <div className="text-right">
                {readerAccount ? (
                  readerInfo
                ) : paymentCreditCard ? (
                  <>{`${
                    creditCardTypes.find((cc) => cc.code === paymentCreditCard.type)?.label?.toUpperCase() ?? "Unknown"
                  } | ${paymentCreditCard.last4Digits}`}</>
                ) : (
                  <>N/A</>
                )}
              </div>
            </div>
          </div>
        </div>

        {(!summary || !!invoiceData.discounts) && (
          <div className="border-b py-2 flex justify-between text-sm pl-10 md:pl-[33%]">
            <span className="inline-block">Subtotal</span>
            <span className="inline-block text-right">
              {currency +
                (
                  invoiceData.itemsSubtotal +
                  invoiceData.productFeesSubtotal +
                  invoiceData.shippingMethodSubtotal
                ).toFixed(2)}
            </span>
          </div>
        )}
        {!!invoiceData.discounts && (
          <div className="border-b py-2 flex justify-between items-baseline">
            <h4 className="text-sm inline-block pt-1 w-1/3 ">Discounts</h4>
            {Object.entries(invoiceData.discounts).map(([pType, items], index) => (
              <div key={`discount_${index}`} className="flex justify-between flex-1 w-full font-light text-sm">
                <span>{pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}</span>
                {items.map(({ price }, index) => (
                  <span key={`${pType}_${index}`} className="inline-block text-right font-normal">
                    {"-" +
                      currency +
                      (
                        (summary
                          ? ((getSummaryParameter(summary, "discounts") as Record<string, number>)[pType] ?? price)
                          : price) * -1
                      ).toFixed(2)}
                  </span>
                ))}
              </div>
            ))}
          </div>
        )}

        {summary && <OrderSummary summary={summary} hasMedicationItems={hasMedicationItems} currency={currency} />}
      </div>
    </div>
  )
}

const AddressItem = ({ label, address, show }: { label: string; show?: boolean; address?: Address }) => {
  if (!show) {
    return null
  }

  return (
    <div className="flex justify-between py-2 border-b">
      <div className="font-light mr-2">{label}</div>
      <div className="text-right">
        {address ? (
          <div>
            <div>{address?.line?.[0]}</div>
            {address?.line?.[1] && <div>{address?.line?.[1]}</div>}
            <div>{`${address?.city}, ${address.state} ${address.postalCode}`}</div>
          </div>
        ) : (
          <div>No shipping address</div>
        )}
      </div>
    </div>
  )
}

type Props = {
  organization: Organization
  selectedCreditCard?: AccountBETACreditCardArray
  organizationCreditCard?: AccountBETACreditCardArray
  hasMedicationItems: boolean
  nutrasShippingAddress?: Address
  labsShippingAddress?: Address
  showMedsAddress?: boolean
  showLabsAddress?: boolean
}

export { OrdersFinish }
