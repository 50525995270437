import {
  IconDefinition,
  faCapsules,
  faChevronLeft,
  faClipboardListCheck,
  faClipboardPrescription,
  faHandshake,
  faPeriod,
  faProcedures,
  faSuitcaseMedical,
  faVials,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns-tz"
import { formatDate } from "date-fns/format"
import { Coding, Consent, asReference } from "fhir"
import { TabPanel, TabView, TabViewTabChangeEvent } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import { getStatus, useLaboratoryOrder } from "commons/labs"
import { MEDICATION_CATALOG, formatsByTypes } from "data"
import { useRunCalculator, useSavePlanLabs } from "mc"
import { useAppModuleContext } from "modules"
import { useOrganizationPractitioners } from "organization"
import { useSearchParams } from "react-router-dom"
import { getBadgeColor } from "utils"

import { Badge } from "../../../components/Badge"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { ModulesId } from "../../../Module"
import { useAlgorithmAssessmentPlanDetails, useCarePlanDetails, usePlanContext } from "../../hooks"
import { PLAN_ACTION_CODES, PLAN_DETAILS_TABS } from "../../types"
import { CPAssessmentsDetails } from "./CPAssessmentsDetails"
import { CPConsentsDetails } from "./CPConsentsDetails"
import { CPIntakesDetails } from "./CPIntakesDetails"
import { CPLabsDetails } from "./CPLabsDetails"
import { CPMedicationsDetails } from "./CPMedicationsDetails"
import { CPProcedureDetails } from "./CPProcedureDetails"
import { CPQuestionnaires } from "./CPQuestionnaires"

const SelectedCPDetails = ({ selectedPlanId, showOrder, showProcedureCalculator, showAssessment }: Props) => {
  const charContext = useChartContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabId = searchParams.get("tabId")
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const { patientId, setCompleteDisabled, patient, currentOrganizationId, returnToListView, setReviewsEnabled } =
    usePlanContext()
  const { appModules } = useAppModuleContext()
  const {
    carePlan,
    serviceRequest,
    questionnaires,
    appointment,
    procedure,
    isLoading,
    isProcedurePlan,
    isMCPlan,
    mcAlgorithmId,
    isOnlySurvey,
    mcPlanIds,
    nutraceuticals,
    rx,
    consents,
    intakes,
    reassessMedicationTask,
    candidateTask,
    configureActions,
    generatedCalculatorResult,
    refetch: onSurveyHide,
    isRefetching: isUpdatingSurvey,
  } = useCarePlanDetails(patientId, selectedPlanId)

  const shouldReassess = reassessMedicationTask?.status === "completed"
  const reassessNotes = reassessMedicationTask?.note

  const { practitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  const { observations, isLoading: isLoadingLabResults } = useLaboratoryOrder(
    currentOrganizationId,
    serviceRequest?.id as string,
    patientId,
    practitionersInfo,
  )

  const {
    runAlgorithmCode,
    icd10,
    isLoading: isLoadingProcedureData,
  } = useAlgorithmAssessmentPlanDetails(mcAlgorithmId, isMCPlan)

  const { savePlanLabs, isPending: isSaving } = useSavePlanLabs((sr) => {
    runCalculator({
      labOrder: sr.id as string,
      planId: carePlan?.id ?? selectedPlanId,
      patientRef: asReference(patient),
    })
  })

  const { runCalculator, isEvaluating } = useRunCalculator((assessmentId) => {
    charContext.showModule({ module: ModulesId.MC, moduleParams: { mcPlanId: assessmentId } })
  })

  const handleGenerateAssesment = useCallback(() => {
    const patientPractRoleRef = practitionersInfo.find(
      ({ practitioner }) => practitioner.id === patient.generalPractitioner?.[0].id,
    )?.practitionerRoleRef

    if (patientPractRoleRef) {
      const planQrId = questionnaires?.[0]?.qResponse?.id ?? ""

      savePlanLabs({
        qrId: planQrId,
        observations: observations,
        performer: serviceRequest?.performer?.[0],
        patientRef: asReference(patient),
        requester: patientPractRoleRef,
        runAlgorithmCode: runAlgorithmCode as Coding,
        icd10,
      })
    }
  }, [runAlgorithmCode, icd10, observations, serviceRequest, patient, carePlan, selectedPlanId, questionnaires])

  const labStatus = serviceRequest && getStatus(serviceRequest)

  const { procedureRequired, procedureAction } = useMemo(() => {
    const procedureAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]?.action?.find(({ code }) =>
      code?.[0]?.coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_PROCEDURE),
    )

    return { procedureAction, procedureRequired: procedureAction?.requiredBehavior === "must" }
  }, [configureActions])
  const procedureActivityEnabled = useMemo(
    () =>
      carePlan?.activity?.find(({ outcomeCodeableConcept }) =>
        outcomeCodeableConcept?.[0]?.coding?.some(({ code }) => code === "procedure"),
      )?.enabled ?? false,
    [carePlan?.activity],
  )

  const tabPanelItems: TabPanelItem[] = [
    {
      id: PLAN_DETAILS_TABS.QUESTIONNAIRES,
      visible: true,
      header: { icon: faClipboardListCheck, title: "Questionnaires" },
      content: (
        <CPQuestionnaires
          questionnaires={questionnaires}
          isUpdatingSurvey={isUpdatingSurvey}
          isMCPlan={isMCPlan}
          onSurveyHide={onSurveyHide}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.INTAKE,
      visible: !!intakes?.length,
      header: { icon: faSuitcaseMedical, title: "Intake" },
      content: (
        <CPIntakesDetails
          intakes={intakes}
          isUpdatingSurvey={isUpdatingSurvey}
          isMCPlan={isMCPlan}
          onSurveyHide={onSurveyHide}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.CONSENTS,
      visible: !!consents?.length,
      header: { icon: faHandshake, title: "Consents" },
      content: <CPConsentsDetails consents={consents as Consent[]} />,
    },
    {
      id: PLAN_DETAILS_TABS.LABS,
      visible: !!serviceRequest,
      header: { icon: faVials, title: "Labs" },
      content: (
        <CPLabsDetails
          serviceRequest={serviceRequest}
          isOnlySurvey={isOnlySurvey}
          patientId={patientId}
          showOrder={showOrder}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.NUTRACEUTICALS,
      visible: !!nutraceuticals?.length,
      header: { icon: faCapsules, title: "Nutraceuticals" },
      content: (
        <CPMedicationsDetails
          medicationRequests={nutraceuticals}
          organizationId={currentOrganizationId}
          planId={selectedPlanId}
          category={MEDICATION_CATALOG.NUTRA}
          shouldResumeMedications={shouldReassess}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.PHARMACEUTICALS,
      visible: !!rx?.length,
      header: { icon: faClipboardPrescription, title: "Pharmaceuticals" },
      content: (
        <CPMedicationsDetails
          medicationRequests={rx}
          organizationId={currentOrganizationId}
          planId={selectedPlanId}
          category={MEDICATION_CATALOG.RX}
          shouldResumeMedications={shouldReassess}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.RECOMMENDATIONS,
      visible: isProcedurePlan,
      header: { icon: faProcedures, title: "Recommendations" },
      content: (
        <CPProcedureDetails
          carePlan={carePlan!}
          procedure={procedure}
          showProcedureCalculator={showProcedureCalculator}
          candidateTask={candidateTask}
          procedureAction={procedureAction}
          generatedCalculatorResult={generatedCalculatorResult}
        />
      ),
    },
    {
      id: PLAN_DETAILS_TABS.ASSESMENTS,
      visible: isMCPlan,
      header: { icon: appModules[ModulesId.MC].getIcon(), title: "Assessments" },
      content: (
        <CPAssessmentsDetails
          patientId={patientId}
          mcAssessments={mcPlanIds?.length ? mcPlanIds : undefined}
          showAssessment={showAssessment}
          applyAssessment={carePlan?.status === "active" && runAlgorithmCode ? handleGenerateAssesment : undefined}
          isGeneratingAssessment={isEvaluating || isSaving}
          isLoading={isLoadingProcedureData || isLoadingLabResults}
          disabled={questionnaires?.[0]?.qResponse?.status !== "completed"}
        />
      ),
    },
  ]

  const handleTabChange = (e: TabViewTabChangeEvent) => {
    const tabId = tabPanelItems[e.index].id
    searchParams.set("tabId", tabId)
    setSearchParams(searchParams)
  }

  useLayoutEffect(() => {
    if (tabId) {
      const index = tabPanelItems.findIndex(({ id }) => id === tabId)
      setActiveTabIndex(Math.max(index, 0))
    }
  }, [tabId])

  useEffect(() => {
    if (
      carePlan?.status !== "active" ||
      (labStatus && !["final-results-available", "completed"].includes(labStatus.code as string)) ||
      !questionnaires?.every(({ qResponse }) => qResponse?.status === "completed") ||
      (procedureRequired && procedureActivityEnabled && procedure?.status !== "completed") ||
      !consents?.every(({ status }) => status === "active") ||
      !rx.every(({ status, doNotPerform }) => ["active", "completed"].includes(status) || !!doNotPerform) ||
      !nutraceuticals.every(({ status, doNotPerform }) => ["active", "completed"].includes(status) || !!doNotPerform)
    ) {
      setCompleteDisabled(true)
    } else if (carePlan?.status === "active") {
      setCompleteDisabled(false)
    }
    setReviewsEnabled(
      (!labStatus || ["final-results-available", "completed"].includes(labStatus.code as string)) &&
        (!questionnaires || questionnaires.every(({ qResponse }) => qResponse?.status === "completed")),
    )
  }, [carePlan?.status, questionnaires, labStatus, procedure, consents, procedureRequired, procedureActivityEnabled])

  return (
    <div className="flex flex-col p-3 flex-1">
      {isLoading ? (
        <SkeletonLoader loaderType="two-lines" repeats={4} />
      ) : !carePlan ? (
        <span className="font-medium text-lg text-gray-700">Invalid plan id</span>
      ) : carePlan.status === "draft" ? (
        <span className="font-medium text-lg text-gray-700">This Plan has not yet been activated</span>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className="flex items-baseline gap-2">
              <span onClick={returnToListView} className="cursor-pointer text-gray-900 h-6 w-6 text-center">
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              <div className="flex flex-col">
                <h3 className="font-medium text-lg">{carePlan.title ?? "Treatment Plan Details"}</h3>
                <span className="text-gray-400 text-sm">
                  {appointment?.start || carePlan?.created
                    ? format(
                        new Date(appointment?.start ?? (carePlan?.created as string)),
                        formatsByTypes.FULL_DATETIME_WITH_TIMEZONE,
                      )
                    : "No date"}
                </span>
              </div>
            </div>
            <Badge
              {...getBadgeColor(
                carePlan.status === "revoked"
                  ? "cancelled"
                  : isMCPlan && carePlan.status === "active"
                    ? "pending"
                    : carePlan.status,
              )}
              className="h-min"
            />
          </div>
          <div className="flex flex-col grow overflow-y-auto flex-1">
            {shouldReassess && (
              <>
                <span className="font-medium text-sm text-gray-500 w-40 mt-3 mb-1">History</span>
                <ul className="flex flex-col gap-3 mb-3 w-full">
                  {reassessNotes?.map(({ text, author, time }, index) => {
                    const date = time && formatDate(time, formatsByTypes.SHORT_DATETIME)
                    const pract = author?.Reference?.display

                    return (
                      <li key={index} className="flex flex-col flex-1 ml-4 3xl:ml-2.5 relative">
                        <div className="flex space-x-1 text-gray-400 items-baseline">
                          <FontAwesomeIcon icon={faPeriod} className="text-xl" />
                          <p className="line-clamp-2 whitespace-normal text-sm">{`${text}${pract ? ` by ${pract}` : ""} ${date ? ` on ${date}` : ""}`}</p>
                        </div>
                        {reassessNotes.length > index + 1 && (
                          <span
                            className={classNames(
                              "border-l-2 absolute top-6 ml-1 3xl:ml-0.5 overflow-hidden",
                              text.length > 50 ? "h-10" : "h-6",
                            )}
                          />
                        )}
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
            <TabView
              className="mt-3 sticky-header flex flex-col flex-1"
              panelContainerClassName="h-full pb-0"
              activeIndex={activeTabIndex}
              onTabChange={handleTabChange}
            >
              {tabPanelItems.map(({ id, header: { icon, title }, visible, content }) => (
                <TabPanel key={id} header={<TabPanelHeader icon={icon} title={title} />} visible={visible}>
                  {content}
                </TabPanel>
              ))}
            </TabView>
          </div>
        </>
      )}
    </div>
  )
}

const TabPanelHeader: FC<{ icon: IconDefinition; title: string }> = ({ icon, title }) => (
  <div className="flex items-center text-primary gap-1">
    <FontAwesomeIcon icon={icon} />
    <span className="text-sm">{title}</span>
  </div>
)

type TabPanelItem = {
  id: string
  visible: boolean
  header: { icon: IconDefinition; title: string }
  content: JSX.Element
}

type Props = {
  selectedPlanId?: string
  showOrder(srId: string): void
  showProcedureCalculator(planId?: string): void
  showAssessment(planId: string): void
}

export { SelectedCPDetails }
