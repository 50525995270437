import { Address, CodeableConcept, Coding, ServiceRequest } from "fhir"

import { FormatTypes } from "commons"
import { SYSTEM_VALUES } from "system-values"

const EXEMPT_PAYMENT_PELLET_ORG_TYPE_CODE = "no-procedure-payment"
const EXEMPT_LAB_PAYMENT_ORG_TYPE_CODE = "no-lab-payment"
const HEALTH_GORILLA_VALUE_CHECK = "healthgorilla"

const appointmentTypesCodes = [
  { code: "CHECKUP", system: SYSTEM_VALUES.V2_0276, display: "Check-up" },
  { code: "EMERGENCY", system: SYSTEM_VALUES.V2_0276, display: "Emergency" },
  { code: "FOLLOWUP", system: SYSTEM_VALUES.V2_0276, display: "Follow-up" },
  { code: "ROUTINE", system: SYSTEM_VALUES.V2_0276, display: "Routine" },
  { code: "WALKIN", system: SYSTEM_VALUES.V2_0276, display: "Walk-in" },
]

const conditionCategoryCodes = [
  { code: "problem-list-item", display: "Problem List Item" },
  { code: "encounter-diagnosis", display: "Encounter Diagnosis" },
]

const conditionClinicalStatusCodes = [
  { code: "active", display: "Active" },
  { code: "recurrence", display: "Recurrence" },
  { code: "relapse", display: "Relapse" },
  { code: "inactive", display: "Inactive" },
  { code: "remission", display: "Remission" },
  { code: "resolved", display: "Resolved" },
]

const conditionSeverityCodes = [
  { code: "24484000", display: "Severe" },
  { code: "6736007", display: "Moderate" },
  { code: "255604002", display: "Mild" },
]

const conditionVerificationStatus = [
  { code: "unconfirmed", display: "Unconfirmed" },
  { code: "provisional", display: "Provisional" },
  { code: "differential", display: "Differential" },
  { code: "confirmed", display: "Confirmed" },
  { code: "refuted", display: "Refuted" },
  { code: "entered-in-error", display: "Entered in Error" },
]

enum ContactPointSystem {
  phone = "phone",
  fax = "fax",
  email = "email",
  pager = "pager",
  url = "URL",
  sms = "SMS",
  other = "other",
}

enum ERROR_CAUSE {
  AUTH_RESOURCE_NOT_FOUND = "Auth Resource Not Found",
  RESOURCE_NOT_FOUND = "Resource Not Found",
  UNAUTHORIZED = "Unauthorized",
  INTERNAL_SERVER_ERROR = "Internal Server Error",
  NETWORK_ERROR = "Network Error",
  FORBIDDEN = "Forbidden",
}

const genders = [
  { code: "female", label: "Female" },
  { code: "male", label: "Male" },
]

const sexCodes = [
  { code: "female", display: "Female", system: SYSTEM_VALUES.GENDER },
  { code: "male", display: "Male", system: SYSTEM_VALUES.GENDER },
]

const contactRelationship = [
  { code: "C", display: "Emergency Contact" },
  { code: "E", display: "Employer" },
  { code: "F", display: "Federal Agency" },
  { code: "I", display: "Insurance Company" },
  { code: "N", display: "Next-of-Kin" },
  { code: "S", display: "State Agency" },
  { code: "U", display: "Unknown" },
]

const countries = [{ label: "United States", code: "US" }]

const formatsByTypes: { [key in FormatTypes]: string } = {
  ISO_8601_DATE: "yyyy-MM-dd",
  ISO_8601_DATETIME: "yyyy-MM-dd'T'HH:mm:ss",
  SHORT_DATE: "MMM do",
  SHORT_DATETIME: "MMM do, h:mm a",
  SHORT_DATETIME_W_SEC: "MMM do, h:mm:ss a",
  LONG_DATE: "MMM do, yyyy",
  LONG_DATETIME: "MMM do, yyyy h:mm a",
  FULL_DATETIME: "MMMM do, yyyy h:mm a",
  TIME: "h:mm a",
  SHORT_MONTH_YEAR: "MMM yyyy",
  LONG_MONTH_YEAR: "MMMM yyyy",
  LONG_DATETIME_WITH_TIMEZONE: "MMM do, yyyy h:mm a z",
  SHORT_DATETIME_WITH_TIMEZONE: "MMM do, h:mm a z",
  FULL_DATETIME_WITH_TIMEZONE: "MMMM do, yyyy h:mm a z",
}

const appointmentMinutsDuration = [
  { label: "00:05", code: 5 },
  { label: "00:10", code: 10 },
  { label: "00:15", code: 15 },
  { label: "00:30", code: 30 },
  { label: "00:45", code: 45 },
  { label: "01:00", code: 60 },
  { label: "01:15", code: 75 },
  { label: "01:30", code: 90 },
  { label: "02:00", code: 120 },
]

const observationCodes = [
  {
    system: SYSTEM_VALUES.ACME_CODE_TEST,
    code: "60512",
    display: "Cefazolin",
  },
  {
    system: SYSTEM_VALUES.ACME_CODE_TEST,
    code: "60516",
    display: "Cefoxitin",
  },
  {
    system: SYSTEM_VALUES.ACME_CODE_TEST,
    code: "60527",
    display: "Clindamycin",
  },
]

const observationInterpretations = [
  {
    code: "L",
    system: SYSTEM_VALUES.OBSERVATION_INTERPRETATION,
    display: "Low",
  },
  {
    code: "H",
    system: SYSTEM_VALUES.OBSERVATION_INTERPRETATION,
    display: "High",
  },
  {
    code: "N",
    system: SYSTEM_VALUES.OBSERVATION_INTERPRETATION,
    display: "Normal",
  },
]

const allergyClinicalStatusCodes = [
  { code: "active", display: "Active" },
  { code: "inactive", display: "Inactive" },
  { code: "resolved", display: "Resolved" },
]

const allergyCategoryCodes = [
  { code: "food", label: "Food" },
  { code: "medication", label: "Medication" },
  { code: "environment", label: "Environment" },
  { code: "biologic", label: "Biologic" },
]

const allergyCriticalityCodes = [
  { code: "low", label: "Low" },
  { code: "high", label: "High" },
  { code: "unable-to-assess", label: "Unable to Assess Risk" },
]

const allergyTypeCodes = [
  { code: "allergy", label: "Allergy" },
  { code: "intolerance", label: "Intolerance" },
]

const allergySeverityCodes = [
  { code: "mild", label: "Mild" },
  { code: "moderate", label: "Moderate" },
  { code: "severe", label: "Severe" },
]

const vitalSignCodes = {
  weight: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "29463-7",
        display: "Body Weight",
      },
    ],
    text: "Body Weight",
  },
  height: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "8302-2",
        display: "Body height",
      },
    ],
    text: "Body height",
  },
  heartRate: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "8867-4",
        display: "Heart rate",
      },
    ],
    text: "Heart rate",
  },
  temperature: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "8310-5",
        display: "Body temperature",
      },
    ],
    text: "Body temperature",
  },
  respiratoryRate: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "9279-1",
        display: "Respiratory rate",
      },
    ],
    text: "Respiratory rate",
  },
  oxygenSaturation: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "59408-5",
        display: "Oxygen saturation in Arterial blood by Pulse oximetry",
      },
    ],
    text: "Oxygen saturation in Arterial blood by Pulse oximetry",
  },
  bloodPressure: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "55284-4",
        display: "Blood Pressure",
      },
    ],
    text: "Blood Pressure",
  },
  bloodPressureSystolic: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "8480-6",
        display: "Systolic Blood Pressure",
      },
    ],
    text: "Systolic Blood Pressure",
  },
  bloodPressureDiastolic: {
    coding: [
      {
        system: SYSTEM_VALUES.LOINC,
        code: "8462-4",
        display: "Diastolic Blood Pressure",
      },
    ],
    text: "Diastolic Blood Pressure",
  },
}

const vitalSignUnits = {
  weight: "lbs",
  height: "in",
  heartRate: "bpm",
  temperature: "°F",
  respiratoryRate: "bpm",
  oxygenSaturation: "%",
  bloodPressure: "mm/hg",
  bloodPressureSystolic: "mm/hg",
  bloodPressureDiastolic: "mm/hg",
}

const medicationRequestStatus = [
  { code: "draft", display: "Draft" },
  { code: "stopped", display: "Stopped" },
  { code: "completed", display: "Completed" },
  { code: "active", display: "Active" },
  { code: "on-hold", display: "Scheduled" },
]

const medicationRequestOrdersStatus = [
  { code: "revoked", display: "Cancelled" },
  { code: "completed", display: "Completed" },
  { code: "active", display: "Active" },
]

const invoiceStatusCodes = [
  { code: "draft", display: "Draft" },
  { code: "issued", display: "Issued" },
  { code: "balanced", display: "Paid" },
  { code: "cancelled", display: "Cancelled" },
  { code: "entered-in-error", display: "Entered in error" },
]

const consentStatusCodes = [
  { code: "draft", display: "Pending" },
  { code: "active", display: "Active" },
  { code: "inactive", display: "Inactive" },
  { code: "not-done", display: "Abandoned" },
  { code: "entered-in-error", display: "Entered in error" },
]

const requestCommonsStatusCodes = [
  { code: "draft", display: "Draft" },
  { code: "on-hold", display: "On hold" },
  { code: "revoked", display: "Cancelled" },
]

const requestStatusCodes = [{ code: "active", display: "Active" }]

const laboratoryOrderStatusCodes = [
  { code: "requisition-pending", display: "Requisition Pending" },
  { code: "requisition-available", display: "Requisition Available" },
  { code: "preliminary-results", display: "Preliminary Results" },
  { code: "final-results-available", display: "Final Results Available" },
]

const carePlanStatusCodes = [
  { code: "draft", display: "Draft" },
  { code: "active", display: "Active" },
  { code: "completed", display: "Completed" },
  { code: "revoked", display: "Cancelled" },
]

const creditCardTypes = [
  { code: "AE", label: "American Express", system: SYSTEM_VALUES.V3_ACTCODE },
  { code: "MC", label: "Master Card", system: SYSTEM_VALUES.V3_ACTCODE },
  { code: "V", label: "Visa", system: SYSTEM_VALUES.V3_ACTCODE },
  { code: "D", label: "Discover", system: SYSTEM_VALUES.V3_ACTCODE },
  { code: "JCB", label: "JCB", system: SYSTEM_VALUES.V3_ACTCODE },
  { code: "DC", label: "Diners Club", system: SYSTEM_VALUES.V3_ACTCODE },
]

const taskPriorityCodes = [
  {
    code: "routine",
    display: "Routine",
  },
  {
    code: "urgent",
    display: "Urgent",
  },
  {
    code: "asap",
    display: "ASAP",
  },
  {
    code: "stat",
    display: "STAT",
  },
]

const taskStatusCodes = [
  {
    code: "draft",
    display: "Draft",
  },
  {
    code: "requested",
    display: "Requested",
  },
  {
    code: "received",
    display: "Received",
  },
  {
    code: "acepted",
    display: "Rejected",
  },
  {
    code: "ready",
    display: "Ready",
  },
  {
    code: "cancelled",
    display: "Cancelled",
  },
  {
    code: "in-progress",
    display: "In Progress",
  },
  {
    code: "on-hold",
    display: "On Hold",
  },
  {
    code: "failed",
    display: "Failed",
  },
  {
    code: "completed",
    display: "Completed",
  },
  {
    code: "entered-in-error",
    display: "Entered in Error",
  },
]

const unitOfTime = [
  { code: "s", display: "Second", system: SYSTEM_VALUES.UNITS_MEASURE },
  { code: "min", display: "Minute", system: SYSTEM_VALUES.UNITS_MEASURE },
  { code: "h", display: "Hour", system: SYSTEM_VALUES.UNITS_MEASURE },
  { code: "d", display: "Day", system: SYSTEM_VALUES.UNITS_MEASURE },
  { code: "wk", display: "Week", system: SYSTEM_VALUES.UNITS_MEASURE },
  { code: "mo", display: "Month", system: SYSTEM_VALUES.UNITS_MEASURE },
  { code: "a", display: "Year", system: SYSTEM_VALUES.UNITS_MEASURE },
]

const dayOfWeek = [
  { code: "mon", label: "Monday" },
  { code: "tue", label: "Tuesday" },
  { code: "wed", label: "Wednesday" },
  { code: "thu", label: "Thursday" },
  { code: "fri", label: "Friday" },
  { code: "sat", label: "Saturday" },
  { code: "sun", label: "Sunday" },
]

export enum BILLING_TYPES_CODES {
  BILL_PATIENT = "bill-patient",
  BILL_PRACTICE = "bill-practice",
  INSURANCE = "insurance",
}

const INTERPRETATION_CODES: { [key: string]: string | undefined } = {
  lo: "Alert Low",
  trending_lo: "Low Normal",
  optimal: "Optimal Normal",
  trending_hi: "High Normal",
  hi: "Alert High",
}

const billingTypes = [
  {
    code: BILLING_TYPES_CODES.BILL_PATIENT,
    display: "Patient",
    system: SYSTEM_VALUES.COVERAGE_TYPE,
  },
  {
    code: BILLING_TYPES_CODES.BILL_PRACTICE,
    display: "Practice",
    system: SYSTEM_VALUES.COVERAGE_TYPE,
  },
  {
    code: BILLING_TYPES_CODES.INSURANCE,
    display: "3rd Party / Insurance",
    system: SYSTEM_VALUES.COVERAGE_TYPE,
  },
]

const coverageStatusCodes = [
  { code: "active", display: "Active" },
  { code: "cancelled", display: "Cancelled" },
  { code: "draft", display: "Draft" },
  { code: "entered-in-error", display: "Entered in Error" },
]

const mrCategoryCodes: Record<MrCategoryCodes, Coding> = {
  procedure: {
    code: "procedure",
    system: SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY,
    display: "Procedure",
  },
  nutraceutical: {
    code: "nutraceutical",
    display: "Nutraceutical",
    system: SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY,
  },
  medication: {
    code: "medication",
    display: "Medication",
    system: SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY,
  },
  "write-in": {
    code: "write-in",
    display: "Write in",
    system: SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY,
  },
  refrigerated: {
    code: "refrigerated",
    system: SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY,
    display: "Refrigerated",
  },
}

const nonContinentalStates = ["VI", "AE", "AP", "AS", "GU", "HI", "MH", "MP", "PR", "PW", "UM"]

const statesWithOnlyInsuranceLabOrdersAllowed = ["NJ", "NY"]

type MrCategoryCodes = "procedure" | "nutraceutical" | "medication" | "write-in" | "refrigerated"

const insuranceRelationship: Record<"self" | "other" | "parent" | "spouse", Coding> = {
  other: {
    code: "other",
    system: SYSTEM_VALUES.SUBSCRIBER_RELATIONSHIP,
    display: "Other",
  },
  parent: {
    code: "parent",
    system: SYSTEM_VALUES.SUBSCRIBER_RELATIONSHIP,
    display: "Parent",
  },
  self: {
    code: "self",
    system: SYSTEM_VALUES.SUBSCRIBER_RELATIONSHIP,
    display: "Self",
  },
  spouse: {
    code: "spouse",
    system: SYSTEM_VALUES.SUBSCRIBER_RELATIONSHIP,
    display: "Spouse",
  },
}

const emptyAddress: Address = {
  country: "US",
  line: ["", ""],
  city: "",
  state: "",
  postalCode: "",
  use: "home",
}

const azureContainer = {
  photos: "practitioner-photos",
  drs: "diagnostic-reports",
  branding: "branding-images",
  docs: "document-reference",
}

const srCategoryCodes: Record<
  "lab-order" | "pharmaceutical-order" | "nutraceutical-order" | "algorithm-order",
  Coding
> = {
  "lab-order": {
    code: "lab-order",
    system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
    display: "Lab Order",
  },
  "pharmaceutical-order": {
    code: "pharmaceutical-order",
    system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
    display: "Pharmaceutical Order",
  },
  "nutraceutical-order": {
    code: "nutraceutical-order",
    system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
    display: "Nutraceutical Order",
  },
  "algorithm-order": {
    system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
    code: "algorithm-order",
    display: "Algorithm Order",
  },
}

const isLabOrder = (order: ServiceRequest) =>
  order.category?.some((cc) => cc.coding?.some((c) => c.code === srCategoryCodes["lab-order"].code)) ?? false

const isMedicationOrder = (order: ServiceRequest) =>
  order.category?.some((cc) => cc.coding?.some((c) => c.code === "medication-order")) ?? false

const organizationSettingId: Record<
  | "ehrModules"
  | "branding"
  | "defaultOrgdata"
  | "medicationsCatalogs"
  | "paymentMethods"
  | "labFacilities"
  | "consents",
  string
> = {
  ehrModules: "ehr-modules",
  branding: "branding",
  defaultOrgdata: "default-org-data",
  medicationsCatalogs: "medications-catalogs",
  paymentMethods: "payment-methods",
  labFacilities: "order-labs",
  consents: "consents",
}

export type OrgDataSettingCode = "defaultProcedureIcd10"

const defaultOrgDataSettingCodes: Record<OrgDataSettingCode, string> = {
  defaultProcedureIcd10: "default-procedure-icd10",
}

enum ServiceRequestCategory {
  LAB_ORDER = "lab-order",
  LAB_ORDER_COMBO = "lab-order-combo",
  LAB_ORDER_PANEL = "lab-order-panel",
  LAB_ORDER_SPLIT = "lab-order-split",
}

export const catalogVisibility: Record<"patientVisibility" | "practiceVisibility", CodeableConcept> = {
  patientVisibility: {
    text: "Catalog Visibility Patient",
    coding: [
      {
        code: "catalog-visibility-patient",
        system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
        display: "Catalog Visibility Patient",
      },
    ],
  },
  practiceVisibility: {
    text: "Catalog Visibility Practice",
    coding: [
      {
        code: "catalog-visibility-practice",
        system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
        display: "Catalog Visibility Practice",
      },
    ],
  },
}

export const MC_PUBLISHER = "MetabolicCode"

export enum MC_ACTIVITY_TYPE {
  MC = "mc",
  MC_SURVEY = "mc-survey",
}

const DEFAULT_BLOOD_DRAWN_PANELS = {
  /* Requested delete of BDIO fees for LabCorp "996660", "998910" */
  "labcorp-catalog": ["998085"],
  "quest-catalog": ["3259"],
} as { [key: string]: string[] }

const DEFAULT_BLOOD_DRAWN_PANELS_LIST = Object.entries(DEFAULT_BLOOD_DRAWN_PANELS).reduce((acc, [, identifiers]) => {
  return [...acc, ...identifiers]
}, [] as string[])

const COMBO_PROMO_CODE = "promo"
const LAB_REFERENCE_ID_CODE = "ACSN"

export enum PAYMENT_METHODS {
  CARD_READER = "card-reader",
  CREDIT_CARD = "credit-card",
  BILL_PATIENT = "bill-patient",
  BILL_PRACTICE = "bill-practice",
}

export enum MEDICATION_CATALOG {
  RX = "pharmacy",
  NUTRA = "medication-distributor",
}

export const ADMINISTRATION_GUIDELINE_DOSAGE_TYPE = {
  coding: [{ code: "maintenance", system: "http://chartedhealth.com/fhir/dosage-type" }],
}

export enum MEDICATION_PRODUCT_TYPE {
  RX = "medication",
  NUTRA = "nutraceutical",
}

export enum PRACTITIONER_ROLE {
  ALL = "all",
  ADMIN = "admin",
  NON_ADMIN = "non-admin",
  PRACTITIONER = "practitioner",
}

export enum PD_ACTION_DYNAMIC_VALUE {
  REUSE = "reuse",
  NOT_SHOWABLE = "noShowable",
  NOT_EDITABLE = "noEditable",
  DEFAULT_DATE = "defaultDate",
  DAYS_BEFORE_APPOINTEMENT = "daysBeforeAppointment",
  REASON_CODE = "reasonCode",
  RECURSIVE_ENROLL = "recursionUntilDays",
}

export enum UNIT_DAYS {
  MONTH = 28,
  WEEK = 7,
  YEAR = 365,
}

export {
  COMBO_PROMO_CODE,
  ContactPointSystem,
  DEFAULT_BLOOD_DRAWN_PANELS_LIST,
  ERROR_CAUSE,
  EXEMPT_LAB_PAYMENT_ORG_TYPE_CODE,
  EXEMPT_PAYMENT_PELLET_ORG_TYPE_CODE,
  HEALTH_GORILLA_VALUE_CHECK,
  INTERPRETATION_CODES,
  LAB_REFERENCE_ID_CODE,
  ServiceRequestCategory,
  allergyCategoryCodes,
  allergyClinicalStatusCodes,
  allergyCriticalityCodes,
  allergySeverityCodes,
  allergyTypeCodes,
  appointmentMinutsDuration,
  appointmentTypesCodes,
  azureContainer,
  billingTypes,
  carePlanStatusCodes,
  conditionCategoryCodes,
  conditionClinicalStatusCodes,
  conditionSeverityCodes,
  conditionVerificationStatus,
  consentStatusCodes,
  contactRelationship,
  countries,
  coverageStatusCodes,
  creditCardTypes,
  dayOfWeek,
  defaultOrgDataSettingCodes,
  emptyAddress,
  formatsByTypes,
  genders,
  insuranceRelationship,
  invoiceStatusCodes,
  isLabOrder,
  isMedicationOrder,
  laboratoryOrderStatusCodes,
  medicationRequestOrdersStatus,
  medicationRequestStatus,
  mrCategoryCodes,
  nonContinentalStates,
  observationCodes,
  observationInterpretations,
  organizationSettingId,
  requestCommonsStatusCodes,
  requestStatusCodes,
  sexCodes,
  srCategoryCodes,
  statesWithOnlyInsuranceLabOrdersAllowed,
  taskPriorityCodes,
  taskStatusCodes,
  unitOfTime,
  vitalSignCodes,
  vitalSignUnits,
}
