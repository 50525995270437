import { add } from "date-fns"
import {
  Address,
  CodeableConcept,
  Coding,
  Dosage,
  DosageDoseAndRateArrayDose,
  Duration,
  Medication,
  MedicationIngredientArray,
  MedicationKnowledge,
  MedicationRequest,
  Patient,
  PractitionerRole,
  Quantity,
  Reference,
  Timing,
  asReference,
  isMedication,
  isMedicationKnowledge,
} from "fhir"
import cloneDeep from "lodash/cloneDeep"
import pluralize from "pluralize"
import * as Yup from "yup"

import { PractitionerInfo } from "commons"
import {
  DispenseIntervalOption,
  MEDICATIONS_REGULATIONS_CODE,
  dispenseInterval,
  getAdministrationGuideline,
} from "commons/meds"
import { mrCategoryCodes, unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getAddressByType, getPatientDefaultPractitioner, isRefrigeratedMedicationKnowledge, unitToDays } from "utils"

import { dosageTimingRepeats, durationCodeOptions, medicationUnitOptions, prescriptionFrequencies } from "../data"
import {
  MedicationDosage,
  MedicationFormData,
  MedicationIngredientFormData,
  MedicationRequestFormData,
  MedicationRequestInfo,
} from "../types"
import {
  calculateDosageDispenseInterval,
  exceedsSupplyDurationLimit,
  getTreatmentFrequency,
  isValidNPIValue,
} from "../utils"

const getInitialValues = ({
  patient,
  practitionersInfo,
  loggedInPractitionerRole,
  encounter,
  medRecommendedDosage,
  medicationKnowledge,
  pharmacy,
  requester,
  fallbackShippingAddress,
}: {
  patient: Patient
  loggedInPractitionerRole: PractitionerRole
  practitionersInfo: PractitionerInfo[]
  encounter?: Reference | null
  medicationKnowledge?: MedicationKnowledge
  medRecommendedDosage?: Record<string, Dosage[]>
  pharmacy?: Reference
  requester?: Reference
  fallbackShippingAddress?: Address
}): MedicationRequestFormData => {
  const currentDate = new Date().toISOString()

  const requesterPR = requester ?? getPatientDefaultPractitioner(practitionersInfo, patient, loggedInPractitionerRole)

  const defaultQuantity =
    medicationKnowledge &&
    ({
      value: 1,
      code: medicationKnowledge.packaging?.type?.coding?.[0].code,
      unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
      system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
    } as Quantity)

  const { value: _, ...prescriptionQuantity } = defaultQuantity ?? {}

  const dosageInstruction =
    medicationKnowledge && getAdministrationGuideline(medicationKnowledge, medRecommendedDosage)?.dosage?.[0]?.dosage

  const { intendedRoute } = medicationKnowledge ?? {}
  const dosages =
    dosageInstruction?.map((dosage) =>
      serializeDosage(
        dosage,
        intendedRoute,
        isMedicationKnowledge(medicationKnowledge) ? getDosageInitialValues(medicationKnowledge) : undefined,
      ),
    ) ?? []

  const dispenseInterval = calculateDosageDispenseInterval(dosages)

  return {
    medicationField: medicationKnowledge,
    medication: medicationKnowledge ? { CodeableConcept: medicationKnowledge.code } : undefined,
    category: [{ coding: [mrCategoryCodes.medication], text: mrCategoryCodes.medication.display }],
    status: "draft",
    intent: "order",
    authoredOn: currentDate,
    subject: asReference(patient),
    encounter: encounter ?? undefined,
    requester: requesterPR,
    recorder: asReference(loggedInPractitionerRole),
    performer: asReference(patient),
    dosageInstruction,
    dispenseRequest: {
      initialFill: {
        quantity: undefined,
        duration: undefined,
      },
      nextRefillDate: undefined,
      numberOfRepeatsAllowed: 0,
      dispenseInterval: dispenseInterval ?? {
        value: 1,
        code: durationCodeOptions[5].value.code,
        system: durationCodeOptions[5].value.system,
        unit: durationCodeOptions[5].value.display.toLowerCase(),
      },
      expectedSupplyDuration: undefined,
      quantity: defaultQuantity,
      performer: pharmacy,
      shippingAddress: getAddressByType("postal", patient.address) ?? fallbackShippingAddress,
    },
    dosages: !dosageInstruction ? [getDosageInitialValues()] : dosages,
    prescriptionQuantity: Object.keys(prescriptionQuantity).length ? prescriptionQuantity : undefined,
    resourceType: "MedicationRequest",
    medicationKnowledge,
  }
}

const sanitize = (mr: MedicationRequestFormData, fromMK?: boolean): MedicationRequest => {
  const medicationReq = cloneDeep(mr)

  delete medicationReq?.medicationField?.textDisplayedInField

  if (medicationReq.medicationField) {
    const { code: medicationCode } = medicationReq.medicationField
    medicationReq.medication = {
      CodeableConcept: medicationCode,
    }
  }

  if (isMedication(medicationReq.medicationField)) {
    medicationReq.contained = [medicationReq.medicationField]

    if (!medicationReq.category?.some(({ coding }) => coding?.[0]?.code === mrCategoryCodes["write-in"].code)) {
      medicationReq.category?.push({ coding: [mrCategoryCodes["write-in"]], text: mrCategoryCodes["write-in"].display })
    }
    const { id: medicationId } = medicationReq.medicationField
    if (medicationId)
      medicationReq.medication = {
        Reference: { localRef: medicationId, resourceType: "Medication" },
      }
  } else if (isMedicationKnowledge(medicationReq.medicationField)) {
    const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationReq.medicationField)

    if (isMKRefrigerated) {
      medicationReq.category?.push({
        coding: [mrCategoryCodes.refrigerated],
        text: mrCategoryCodes.refrigerated.display,
      })
    }
  }

  const currentDate = new Date().toISOString()
  if (!medicationReq.authoredOn) medicationReq.authoredOn = currentDate
  if (!medicationReq.dispenseRequest?.nextRefillDate)
    medicationReq.dispenseRequest = {
      ...medicationReq.dispenseRequest,
      nextRefillDate: currentDate,
    }

  const repeats = medicationReq.dispenseRequest?.numberOfRepeatsAllowed
  const { code, unit, system } = medicationReq.prescriptionQuantity ?? {}

  const interval = medicationReq.dispenseRequest.dispenseInterval?.value
  const duration = {
    ...medicationReq.dispenseRequest.dispenseInterval,
    value: Math.max(interval!, 1) * Math.max(repeats!, 1),
  } as Duration

  medicationReq.dispenseRequest = {
    ...medicationReq.dispenseRequest,
    initialFill: {
      quantity: medicationReq.dispenseRequest.quantity,
      duration: duration,
    },
    performer: medicationReq.dispenseRequest.performer && asReference(medicationReq.dispenseRequest.performer),
    quantity: { code, unit, system, ...medicationReq.dispenseRequest.quantity },
    numberOfRepeatsAllowed: repeats,
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: medicationReq.dispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      end: add(
        medicationReq.dispenseRequest?.validityPeriod?.start
          ? new Date(medicationReq.dispenseRequest.validityPeriod.start)
          : new Date(),
        { [`${duration.unit ?? "second"}s`]: duration.value! },
      ).toISOString(),
    },
  }

  if (!medicationReq.dosageInstruction?.length || medicationReq.dosages?.length)
    medicationReq.dosageInstruction = medicationReq.dosages?.map((dosage) =>
      sanitizeDosage(dosage, medicationReq.structured),
    )

  if (!medicationReq.encounter) delete medicationReq.encounter
  if (!medicationReq.recorder) delete medicationReq.recorder
  if (!medicationReq.requester) delete medicationReq.requester
  if (!medicationReq.performer) delete medicationReq.performer
  if (!medicationReq.note?.[0].text) delete medicationReq.note
  if (!medicationReq.dispenseRequest.shippingAddress && !fromMK) delete medicationReq.dispenseRequest.shippingAddress

  if (!fromMK) {
    delete medicationReq.medicationField
    delete medicationReq.prescriptionQuantity
    delete medicationReq.dosages
    delete medicationReq.administrationGuideline
    delete medicationReq.medicationKnowledge
  }

  delete medicationReq?.structured

  return medicationReq
}

const getDosageInitialValues = (mk?: MedicationKnowledge): MedicationDosage => {
  const dosageQuantity = mk?.administrationGuidelines?.[0]?.dosage?.[0]?.dosage?.[0]?.doseAndRate?.[0]?.dose?.Quantity
  const medicationQtyOption = medicationUnitOptions.find(
    ({ value: { unit } }) => dosageQuantity?.unit?.toLowerCase() === unit.toLowerCase(),
  )

  return {
    doseQuantity: "",
    medicationQuantity: medicationQtyOption?.value,
    treatmentFrequency: "",
    treatmentRoute: undefined,
    instructionText: "",
    prescriptionDuration: undefined,
    prescriptionDurationUnit: undefined,
  }
}

const sanitizeDosage = (dosage: MedicationDosage, isStructured: boolean = false): Dosage => {
  const frequency = getTimingFrequency({
    frequencyKeyValue: dosage.treatmentFrequency,
    duration: dosage.prescriptionDuration,
    durationUnit: dosage.prescriptionDurationUnit,
  })

  let doseValue: DosageDoseAndRateArrayDose

  const isRangeDose = dosage.doseQuantity?.includes("-")

  if (isRangeDose) {
    const rangeBaseQuantity = {
      unit: dosage.medicationQuantity?.unit,
      system: dosage.medicationQuantity?.system,
      code: dosage.medicationQuantity?.code,
    }
    const [low, high] = String(dosage.doseQuantity).split("-")

    doseValue = {
      Range: {
        low: { ...rangeBaseQuantity, value: parseInt(low) },
        high: { ...rangeBaseQuantity, value: parseInt(high) },
      },
    }
  } else {
    doseValue = {
      Quantity: {
        value: dosage.doseQuantity ? parseFloat(dosage.doseQuantity) : 0,
        unit: dosage.medicationQuantity?.unit,
        system: dosage.medicationQuantity?.system,
        code: dosage.medicationQuantity?.code,
      },
    }
  }

  return {
    timing: { ...dosage.doseTiming, ...frequency },
    route: { coding: dosage.treatmentRoute ? [dosage.treatmentRoute] : [] },
    text: isStructured ? generateInstructionTextFromDosage(dosage) : dosage.instructionText,
    doseAndRate: [{ dose: doseValue }],
  }
}

const generateInstructionTextFromDosage = (dosage: MedicationDosage) => {
  const quantityDisplay = `${dosage.doseQuantity} ${dosage.medicationQuantity?.unit?.toLowerCase()}`
  const frecuencyDisplay = prescriptionFrequencies
    .find((f) => f.value === dosage.treatmentFrequency)
    ?.label?.toLowerCase()
  const route = dosage.treatmentRoute?.display?.toLowerCase()
  const durationUnitDisplay = unitOfTime
    .find(({ code }) => code === dosage.prescriptionDurationUnit)
    ?.display.toLowerCase()
  const duration = `${parseInt(dosage.prescriptionDuration ?? "0")} ${pluralize(durationUnitDisplay ?? "", parseInt(dosage.prescriptionDuration ?? "0"))}`

  return `Take ${quantityDisplay} ${frecuencyDisplay} by ${route} for ${duration}`
}

const serializeDosage = (
  dosage: Dosage,
  intendedRoute?: CodeableConcept[],
  initialMedicationDosage?: MedicationDosage,
): MedicationDosage => {
  const medicationQuantity =
    initialMedicationDosage?.medicationQuantity ??
    medicationUnitOptions.find(
      ({ value }) => dosage.doseAndRate?.[0]?.dose?.Quantity?.unit?.toLowerCase() === value.unit.toLowerCase(),
    )?.value
  const doseQuantity = dosage.doseAndRate?.[0]?.dose?.Quantity?.value?.toString()
  const treatmentFrequency = getTreatmentFrequency(dosage)
  const doseTiming = dosage?.timing
  const prescriptionDuration = dosage?.timing?.repeat?.duration?.toString()
  const prescriptionDurationUnit = durationCodeOptions.find(
    (option) => option?.value?.code === dosage?.timing?.repeat?.durationUnit,
  )?.value

  return {
    doseQuantity,
    medicationQuantity,
    treatmentRoute: intendedRoute?.[0]?.coding?.[0],
    treatmentFrequency,
    prescriptionDuration,
    prescriptionDurationUnit,
    instructionText: dosage.text,
    doseTiming,
  }
}

const getTimingFrequency = ({
  frequencyKeyValue,
  duration,
  durationUnit,
}: {
  frequencyKeyValue?: string
  duration?: string
  durationUnit?: Coding
}) => {
  const selectedfrequency = dosageTimingRepeats.find((option) => option.id === frequencyKeyValue)

  const frequency = {
    code: selectedfrequency?.code,
    repeat: { ...selectedfrequency?.repeat, duration: parseInt(duration ?? "0"), durationUnit: durationUnit?.code },
  }

  const defaultTimingFreq = {
    code: { coding: [{ code: "QD", system: SYSTEM_VALUES.V3_GTSABB }] },
    repeat: { periodUnit: durationCodeOptions[3].value.code, ...frequency.repeat },
  } as Timing

  return frequency ?? defaultTimingFreq
}

const prescriptionValidationSchema = (practitionersInfo: PractitionerInfo[]) =>
  Yup.object().shape({
    medicationField: Yup.object().typeError("Medication is required").required("Medication is required"),
    dispenseRequest: Yup.object().shape({
      quantity: Yup.object()
        .shape({ value: Yup.number().nullable().required("Quantity is required") })
        .test("test-max-dispense-limits", (formValue, context) => {
          const medicationDispenseLimit = (
            context?.options?.context?.medicationField as MedicationKnowledge
          )?.regulatory?.find(
            (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DISPENSE,
          )?.maxDispense?.quantity?.value

          const medicationDispenseQty = formValue?.value

          if (!medicationDispenseLimit || !medicationDispenseQty) return true

          return medicationDispenseQty > medicationDispenseLimit
            ? context.createError({
                message: `Dispense quantity limit (${medicationDispenseLimit}) surpassed`,
                path: "dispenseRequest.quantity.value",
              })
            : true
        }),
      shippingAddress: Yup.object()
        .nullable()
        .required("Shipping address is required")
        .test(
          "test-not-using-restricted-states",
          (context) => `Invalid Address Selected: This prescription cannot be shipped to ${context.value?.state}.`,
          (value, context) => {
            const restrictedStates = (context?.options?.context?.medicationField as MedicationKnowledge)?.regulatory
              ?.find(
                (regulation) =>
                  regulation?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.RESTRICTED_SHIPPING,
              )
              ?.regulatoryCharacteristic?.flatMap((characteristic) => characteristic?.value?.string)
            const valueState = value?.state

            if (!valueState || !restrictedStates) return true

            const isRestrictedState = restrictedStates.includes(valueState)

            return !isRestrictedState
          },
        ),
      dispenseInterval: Yup.object()
        .required("Dispense interval is required")
        .test("test-max-days-supply", "Max supply duration limit in days exceeded", (formValue, context) => {
          const medDispenseInterval: Duration | undefined = formValue

          if (!medDispenseInterval?.value) return true

          const maxDaysSupply = (context?.options?.context?.medicationField as MedicationKnowledge)?.regulatory?.find(
            (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DAYS_SUPPLY,
          )?.regulatoryCharacteristic?.[0]?.value?.quantity
          const specifiedDurationInDays = medDispenseInterval.value * unitToDays(medDispenseInterval.code)

          const supplyDurationExceeded = exceedsSupplyDurationLimit({
            durationDays: specifiedDurationInDays,
            daysLimit: maxDaysSupply?.value,
          })

          if (supplyDurationExceeded) return false

          const medDispenseQty = context.parent?.quantity?.value
          const discardAfter = (
            context?.options?.context?.medicationField as MedicationKnowledge
          )?.drugCharacteristic?.find(({ type }) => type?.coding?.[0]?.code === "discard-after")?.value?.Quantity
          const discardAfterDays =
            discardAfter?.value && discardAfter.value * unitToDays(discardAfter.code) * medDispenseQty

          const supplyLimitExceededByQuantity = exceedsSupplyDurationLimit({
            durationDays: discardAfterDays,
            daysLimit: maxDaysSupply?.value,
          })

          if (supplyLimitExceededByQuantity) {
            return context.createError({
              message: `⚠️ Quantity exceeds ${maxDaysSupply?.value} ${maxDaysSupply?.unit?.toLowerCase() ?? "days"} supply`,
              path: "dispenseRequest.quantity.value",
            })
          }

          const refills = context.parent?.numberOfRepeatsAllowed
          const supplyLimitExceededByRefills = exceedsSupplyDurationLimit({
            durationDays: specifiedDurationInDays * (refills ?? 1),
            daysLimit: maxDaysSupply?.value,
          })

          return supplyLimitExceededByRefills
            ? context.createError({
                message: `Supply limit of ${maxDaysSupply?.value} ${maxDaysSupply?.unit?.toLowerCase() ?? "days"} surpassed`,
                path: "dispenseRequest.numberOfRepeatsAllowed",
              })
            : true
        }),
      numberOfRepeatsAllowed: Yup.number().when("dispenseInterval", {
        is: (val: DispenseIntervalOption) => val !== dispenseInterval[0],
        then: (schema) => schema.nullable().required("At least one refill is required"),
      }),
      performer: Yup.object().nullable().required("Pharmacy is required"),
    }),
    prescriptionQuantity: Yup.object().required("Unit is required"),
    requester: Yup.object()
      .test(
        "test-practitioner-systems",
        "This practitioner doesn't have a valid Lifefile ID",
        (value: Reference, context) => {
          const selectedPractInfo = practitionersInfo.find(
            ({ practitionerRoleRef }) => practitionerRoleRef?.id === value?.id,
          )
          if (!selectedPractInfo) {
            return context.createError({ message: "Prescriber is required" })
          }

          const selectedPractRoleSystems = selectedPractInfo?.practitionerRole?.identifier?.map(({ system }) => system)

          return Boolean(selectedPractRoleSystems?.includes(SYSTEM_VALUES.LIFEFILE_PRACTITIONER))
        },
      )
      .test(
        "test-practitioner-valid-npi",
        "This practitioner doesn't have a valid NPI identifier",
        (value: Reference, context) => {
          const selectedPractInfo = practitionersInfo.find(
            ({ practitionerRoleRef }) => practitionerRoleRef?.id === value?.id,
          )
          if (!selectedPractInfo) {
            return context.createError({ message: "Prescriber is required" })
          }

          const selectedPractNPIIdentifier = selectedPractInfo?.practitioner?.identifier?.find(
            ({ system }) => system === SYSTEM_VALUES.NPI,
          )
          if (!selectedPractNPIIdentifier || !selectedPractNPIIdentifier?.value) {
            return context.createError({ message: "This practitioner doesn't have a NPI identifier on file" })
          }

          return isValidNPIValue(selectedPractNPIIdentifier.value)
        },
      ),
    dosages: Yup.array().min(1, "At least one dosage is required").required("Dosage is required"),
  })

const getMedicationIngredientInitialValues = (): MedicationIngredientFormData => ({
  name: "",
  unit: "",
  value: undefined,
})

const sanitizeMedicationIngredient = (data: MedicationIngredientFormData): MedicationIngredientArray => {
  const { name, unit, value } = data

  return {
    item: {
      CodeableConcept: {
        coding: [
          {
            code: name?.toLocaleLowerCase(),
            display: name,
            system: SYSTEM_VALUES.VS_INGREDIENT,
          },
        ],
        text: name,
      },
    },
    strength: { numerator: { unit, value } },
  }
}

const medicationIngredientValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Name is required"),
    unit: Yup.string().required("Unit is required"),
    value: Yup.number().typeError("Must be number").positive("Must be positive").required("Value is required"),
  })
  .nullable()
  .optional()

const getMedicationInitialValues = (medication: Medication | string = ""): MedicationFormData => {
  const isMedicationType = isMedication(medication)
  const medicationCodeText = isMedicationType ? medication.code?.text : medication
  const medicationFormCoding = isMedicationType ? medication.form?.coding : undefined
  const medicationIngredient = isMedicationType ? medication.ingredient : undefined
  const medicationAmountValue = isMedicationType ? medication.amount?.numerator?.value : undefined
  const medicationAmountUnit = isMedicationType ? medication.amount?.numerator?.unit : undefined

  return {
    resourceType: "Medication",
    code: { text: medicationCodeText },
    status: "active",
    form: { coding: medicationFormCoding },
    ingredient: medicationIngredient,
    newIngredient: null,
    amount: { numerator: { value: medicationAmountValue, unit: medicationAmountUnit } },
  }
}

const medicationValidationSchema = Yup.object().shape({
  code: Yup.object().shape({ text: Yup.string().required("Name is required") }),
  form: Yup.object().shape({
    coding: Yup.array().required("Dose form is required"),
  }),
  ingredient: Yup.array().test({
    message: "At least one ingredient is required",
    test: (arr) => (arr?.length ?? 0) > 0,
  }),
  amount: Yup.object().shape({
    numerator: Yup.object().shape({
      value: Yup.number().required("Amount is required"),
      unit: Yup.string().required("Unit is required"),
    }),
  }),
  newIngredient: medicationIngredientValidationSchema,
})

const sanitizeMedication = (data: MedicationFormData): Medication => {
  delete data.newIngredient

  return data
}

const getRenewedMR = ({ ...medicationRequest }: MedicationRequestInfo) => {
  medicationRequest.priorPrescription = asReference(medicationRequest)
  const newDate = new Date().toISOString()
  medicationRequest.authoredOn = newDate
  medicationRequest.status = "draft"

  const duration = medicationRequest.dispenseRequest?.initialFill?.duration
  const interval = medicationRequest.dispenseRequest?.dispenseInterval?.value

  medicationRequest.dispenseRequest = {
    ...medicationRequest.dispenseRequest,
    nextRefillDate: newDate,
    validityPeriod: {
      start: newDate,
      ...(!interval
        ? {
            end: add(new Date(newDate), {
              [`${duration?.unit ?? "second"}s`]:
                (duration?.value ?? 0) * ((medicationRequest.dispenseRequest?.numberOfRepeatsAllowed ?? 0) + 1),
            }).toISOString(),
          }
        : {}),
    },
  }

  delete medicationRequest.id
  delete medicationRequest.prescriptionQuantity
  delete medicationRequest.dosages
  delete medicationRequest.administrationGuideline

  return medicationRequest
}

export {
  getDosageInitialValues,
  getInitialValues,
  getMedicationIngredientInitialValues,
  getMedicationInitialValues,
  getRenewedMR,
  medicationValidationSchema,
  prescriptionValidationSchema,
  sanitize,
  sanitizeDosage,
  sanitizeMedication,
  sanitizeMedicationIngredient,
  serializeDosage,
}
