import { formatsByTypes, unitOfTime } from "data"
import { format } from "date-fns"
import { MedicationRequest, ServiceRequest, Timing } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { SYSTEM_VALUES } from "system-values"

import { getRenewedMR } from "./components/validation"
import { MedicationRequestInfo } from "./types"

enum PrescriptionViews {
  prescriptions = "prescriptions",
  history = "history",
  orders = "orders",
}

const prescriptionViewOptions = [
  { name: "Prescriptions", value: PrescriptionViews.prescriptions },
  { name: "History", value: PrescriptionViews.history },
  { name: "Pending Orders", value: PrescriptionViews.orders },
]

const durationCodeOptions = unitOfTime.map((uot) => ({ label: uot.display, value: { ...uot } }))

const prescriptionFrequencies = [
  { label: "Once / day", value: "oncePerDay" },
  { label: "Twice / day", value: "twicePerDay" },
  { label: "Three times / day", value: "threePerDay" },
  { label: "Four times / day", value: "fourPerDay" },
  { label: "Every morning", value: "everyMorning" },
  { label: "Every night", value: "everyNight" },
  { label: "Every other day", value: "everyOtherDay" },
  { label: "Once / week", value: "oncePerWeek" },
  { label: "Twice / week", value: "twicePerWeek" },
  { label: "Three times / week", value: "threePerWeek" },
  { label: "Four times / week", value: "fourPerWeek" },
  { label: "Five times / week", value: "fivePerWeek" },
  { label: "Eight times / week", value: "eightPerWeek" },
  { label: "Five times / month", value: "fivePerMonth" },
  { label: "Seven times / month", value: "sevenPerMonth" },
  { label: "Fifteen times / month", value: "fifteenPerMonth" },
  { label: "Twenty five times / month", value: "twentyFivePerMonth" },
]

const routeOptions = [
  {
    label: "Mouth",
    value: {
      code: "oral",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Oral",
    },
  },
  {
    label: "Sublingual",
    value: { code: "37839007", display: "Sublingual use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Nose",
    value: { code: "37839007", display: "Nasal use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Intravenous",
    value: { code: "476220.value08", display: "Intravenous use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Intramuscular",
    value: { code: "78421000", display: "Intramuscular use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Subcutaneous",
    value: {
      code: "34206005",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Subcutaneous route",
    },
  },
  {
    label: "Injection",
    value: {
      code: "injection",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Injection",
    },
  },
  {
    label: "Topical",
    value: {
      code: "topical",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Topical",
    },
  },
]

const medicationUnitOptions = [
  {
    label: "Tablet",
    value: {
      code: "385055001",
      unit: "Tablet",
      system: SYSTEM_VALUES.MED_FROM_CODES,
    },
  },
  {
    label: "Chewable tablet",
    value: {
      code: "66076007",
      unit: "Chewable tablet",
      system: SYSTEM_VALUES.MED_FROM_CODES,
    },
  },
  {
    label: "Capsule",
    value: {
      code: "385049006",
      unit: "Capsule",
      system: SYSTEM_VALUES.MED_FROM_CODES,
    },
  },
  {
    label: "Oral capsule",
    value: {
      code: "420692007",
      unit: "Oral capsule",
      system: SYSTEM_VALUES.MED_FROM_CODES,
    },
  },
  {
    label: "ml",
    value: {
      code: "ml",
      unit: "ml",
      system: SYSTEM_VALUES.MED_FROM_CODES,
    },
  },
  {
    label: "mg",
    value: {
      code: "mg",
      unit: "milligram",
      system: SYSTEM_VALUES.MED_FROM_CODES,
    },
  },
]

const dosageTimingRepeats = [
  {
    id: "oncePerDay",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "twicePerDay",
    code: {
      coding: [
        {
          code: "BID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 2,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "threePerDay",
    code: {
      coding: [
        {
          code: "TID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 3,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "fourPerDay",
    code: {
      coding: [
        {
          code: "QID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 4,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "everyOtherDay",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 2,
      periodUnit: "d",
    },
  },
  {
    id: "everyMorning",
    code: {
      coding: [
        {
          code: "AM",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["MORN"],
    },
  },
  {
    id: "everyNight",
    code: {
      coding: [
        {
          code: "BED",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["NIGHT"],
    },
  },
  {
    id: "oncePerWeek",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "twicePerWeek",
    code: {
      coding: [
        {
          code: "BID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 2,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "threePerWeek",
    code: {
      coding: [
        {
          code: "TID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 3,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "fourPerWeek",
    code: {
      coding: [
        {
          code: "QID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 4,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "fivePerWeek",
    code: {
      coding: [
        {
          code: "WK",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 5,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "eightPerWeek",
    code: {
      coding: [
        {
          code: "WK",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 8,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "oncePerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "fivePerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 5,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "sevenPerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 7,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "fifteenPerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 15,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "twentyFivePerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 25,
      period: 1,
      periodUnit: "mo",
    },
  },
] as Timing[]

const discardMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to delete this prescription?",
    header: "Confirmation",
    acceptLabel: "Delete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const renewMR = (medicationRequest: MedicationRequestInfo, onAcceptCliked: (mr: MedicationRequest) => void) => {
  confirmDialog({
    message: "Are you sure you want to refill this prescription?",
    header: "Confirmation",
    acceptLabel: "Refill",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => {
      onAcceptCliked(getRenewedMR(medicationRequest))
    },
  })
}

const getOrderDate = (order?: ServiceRequest) => {
  let dateString = order?.occurrence?.dateTime ?? order?.authoredOn
  if (!dateString) return "Unspecified"

  if (!dateString.includes("T")) dateString += "T00:00:00"
  return format(new Date(dateString), formatsByTypes.LONG_DATE)
}

const medicationFormOptions = [
  { label: "Oral drops", value: [{ code: "385018001", display: "Oral drops", system: "ttp://snomed.info/sct" }] },
  {
    label: "Solution for injection",
    value: [{ code: "385219001", display: "Solution for injection", system: "ttp://snomed.info/sct" }],
  },
  { label: "Oral tablet", value: [{ code: "421026006", display: "Oral tablet", system: "ttp://snomed.info/sct" }] },
  { label: "Ear solution", value: [{ code: "421713001", display: "Ear solution", system: "ttp://snomed.info/sct" }] },
  {
    label: "Nasal solution",
    value: [{ code: "422336005", display: "Nasal solution", system: "ttp://snomed.info/sct" }],
  },
]

const getStatusClass = (status: string) => {
  switch (status) {
    case "active":
      return "is-success"
    case "stopped":
      return "is-danger"
    case "completed":
      return "is-info"
    case "cancelled":
      return "is-warning"
    case "draft":
      return "is-warning"
    default:
      return ""
  }
}

const NO_FOUND_MEDICATIONS = "No medications found"

export {
  NO_FOUND_MEDICATIONS,
  PrescriptionViews,
  discardMR,
  dosageTimingRepeats,
  durationCodeOptions,
  getOrderDate,
  getStatusClass,
  medicationFormOptions,
  medicationUnitOptions,
  prescriptionFrequencies,
  prescriptionViewOptions,
  renewMR,
  routeOptions,
}
