import { faCapsules } from "@fortawesome/pro-regular-svg-icons"
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationRequest, Reference, asReference, codeableConceptAsString } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { useChartContext } from "chart-view"
import { EmptyMessage, FooterActions, ModulesId, SkeletonLoader } from "commons"
import { useDraftMedicationRequests } from "commons/meds"
import { useOpenEncounter } from "encounter"
import { getMRInitialValues, sanitizeMR } from "medication-requests"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { useAuth } from "security"
import { getCommonCode } from "utils"

import { triads } from "../../data"
import { useCreateMRs } from "../../hooks"
import { MCSuplements } from "../../types"
import { TriadFilter } from "./TriadFilter"

const NutraView = ({ suplements }: Props) => {
  const { AppSubModules } = useAuth()
  const { patientId, patient } = usePatientContext()
  const { practitionerRole, currentOrganizationId } = useOrganizationContext()
  const { practitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const { openEncounter } = useOpenEncounter(patientId)
  const { medicationRequests, isLoading } = useDraftMedicationRequests({ patientId, category: "nutraceutical" })

  const [disabledTriads, setDisabledTriads] = useState<Record<string, boolean>>({})
  const [selectedSuplements, setSelectedSuplements] = useState<MCSuplements[]>([])

  const { hasGP, showSubModule } = useChartContext()

  const gotoDraftMR = () => showSubModule({ subModule: AppSubModules.medicationRequest[ModulesId.MR_DRAFT] })

  const { createMRs, isCreating } = useCreateMRs(gotoDraftMR)

  const draftMeds =
    medicationRequests?.map((mr) => getCommonCode({ codes: mr.medication?.CodeableConcept?.coding })) ?? []
  const availableMeds = suplements.filter(
    (sup) =>
      draftMeds.every((code) => code !== getCommonCode({ codes: sup.mk.code?.coding })) && sup.mk.id && sup.price,
  )

  const onSelect = (checked: boolean, suplement: MCSuplements) => {
    checked
      ? setSelectedSuplements([...selectedSuplements, suplement])
      : setSelectedSuplements(selectedSuplements.filter((s) => s.mr.id !== suplement.mr.id))
  }

  const onCreateMRs = () => {
    const mrs = selectedSuplements.reduce<MedicationRequest[]>((acc, suplement) => {
      const mr = getMRInitialValues(
        suplement.mk,
        1,
        patient,
        practitionerRole,
        practitionersInfo,
        suplement.mr?.dispenseRequest?.performer ??
          ({
            id: "metabolic-code-supplements",
            display: "Metabolic Code Supplements",
            resourceType: "Organization",
          } as Reference),
        openEncounter && asReference(openEncounter),
      )
      mr.dosageInstruction = suplement.mr.dosageInstruction

      return [...acc, sanitizeMR(mr, true)]
    }, [])
    createMRs(mrs)
  }

  const addOptions = [
    {
      label: "Create new nutraceutical order",
      icon: faCapsules,
      command: () => onCreateMRs(),
      disabled: !selectedSuplements.length && !hasGP,
      loading: isCreating || isLoading,
    },
  ]

  return suplements.length ? (
    <div className="flex flex-col h-full overflow-hidden">
      <TriadFilter {...{ disabledTriads, setDisabledTriads }} />
      <div className="flex flex-col overflow-y-auto gap-3 py-6 grow">
        <div className="grid grid-cols-12 rounded-mdp-2 text-gray-400">
          <div className="col-span-5 flex gap-2 font-medium text-sm">
            <Checkbox
              className="mx-2"
              onChange={(e) => (e.checked ? setSelectedSuplements(availableMeds) : setSelectedSuplements([]))}
              checked={selectedSuplements.length === availableMeds.length && !!availableMeds.length}
              disabled={!availableMeds.length || isCreating}
            />
            <span>Suplements</span>
          </div>
          <div className="col-span-6 grid grid-cols-5 gap-2">
            <div className="text-center text-sm">Morning</div>
            <div className="text-center text-sm">Noon</div>
            <div className="text-center text-sm">Afternoon</div>
            <div className="text-center text-sm">Dinner</div>
          </div>
        </div>
        {isLoading ? (
          <SkeletonLoader loaderType="one-line" repeats={5} />
        ) : (
          suplements.map(
            (sup, index) =>
              sup.triads.some((tId) => !disabledTriads[tId]) && (
                <MCSuplement
                  key={index}
                  suplement={sup}
                  selected={selectedSuplements.some((s) => s.mr.id === sup.mr.id)}
                  onChecked={(e) => onSelect(e, sup)}
                  disabled={
                    isCreating ||
                    draftMeds.some((code) => code === getCommonCode({ codes: sup.mk.code?.coding })) ||
                    !sup.mk.id ||
                    !sup.price
                  }
                  inUse={draftMeds.some((code) => code === getCommonCode({ codes: sup.mk.code?.coding }))}
                />
              ),
          )
        )}
      </div>
      <FooterActions actions={addOptions} />
    </div>
  ) : (
    <EmptyMessage icon={faCapsules} message="No medications suggested in plan" subMessage={false} />
  )
}

const MCSuplement = ({
  suplement,
  selected,
  onChecked,
  disabled,
  inUse,
}: {
  suplement: MCSuplements
  onChecked: (checked: boolean) => void
  selected: boolean
  disabled: boolean
  inUse: boolean
}) => {
  const morn = suplement.mr?.dosageInstruction?.find((d) => d?.timing?.repeat?.when?.[0] === "MORN")
  const noon = suplement.mr?.dosageInstruction?.find((d) => d?.timing?.repeat?.when?.[0] === "NOON")
  const cv = suplement.mr?.dosageInstruction?.find((d) => d?.timing?.repeat?.when?.[0] === "CV")
  const aft = suplement.mr?.dosageInstruction?.find((d) => d?.timing?.repeat?.when?.[0] === "AFT")
  const hs = suplement.mr?.dosageInstruction?.find((d) => d?.timing?.repeat?.when?.[0] === "HS")

  const warning =
    !suplement.mk.id || !suplement.price
      ? `${codeableConceptAsString(
          suplement.mr.medication?.CodeableConcept,
        )}, is currently unavailable in our inventory`
      : inUse
        ? "There is an active order with this medication"
        : undefined

  return (
    <div
      className={classNames(
        "grid grid-cols-12 rounded-md bg-gray-100 p-2",
        disabled ? "text-gray-500 cursor-default" : "cursor-pointer",
      )}
    >
      <div
        className={classNames(
          "col-span-5 flex gap-2 items-center font-medium text-sm",
          disabled ? "cursor-default" : "cursor-pointer",
        )}
        onClick={() => !disabled && onChecked(!selected)}
      >
        <Tooltip target={`.med-warning._${suplement.mr.id}`} position="left" />
        <div
          className={classNames("flex items-center gap-2", `_${suplement.mr.id}`, {
            "med-warning": warning,
          })}
          data-pr-tooltip={warning}
        >
          {warning ? (
            <FontAwesomeIcon icon={faTriangleExclamation} className="mx-1" />
          ) : (
            <Checkbox
              checked={selected}
              disabled={disabled}
              className={disabled ? "cursor-default" : "cursor-pointer"}
            />
          )}
          {suplement.triads.map((triad) => (
            <img key={`${triad}Img`} src={triads[triad].img} alt="" className="w-3" />
          ))}
          {codeableConceptAsString(suplement.mr.medication?.CodeableConcept)}
        </div>
      </div>
      <div className="col-span-6 grid grid-cols-5 gap-2">
        <div className="flex items-center justify-center rounded-md bg-white font-medium text-xs">
          {morn && morn.text}
        </div>
        <div className="flex items-center justify-center rounded-md bg-white font-medium text-xs">
          {noon && noon.text}
        </div>
        <div className="flex items-center justify-center rounded-md bg-white font-medium text-xs">
          {aft && aft.text}
        </div>
        <div className="flex items-center justify-center rounded-md bg-white font-medium text-xs">{cv && cv.text}</div>
        <div className="flex items-center justify-center rounded-md bg-white font-medium text-xs">{hs && hs.text}</div>
      </div>
      <div className="flex items-center justify-end text-xs">
        {suplement.price ? `$${suplement.price.value?.toFixed(2)}` : "No price"}
      </div>
    </div>
  )
}

type Props = {
  suplements: MCSuplements[]
}

export { NutraView }
