import { format, parseISO } from "date-fns"
import { humanNameAsString } from "fhir"
import { useFormikContext } from "formik"
import { useCallback } from "react"

import { formatsByTypes, vitalSignCodes } from "data"
import { usePatientContext } from "patients"
import { useVitalsKnowledge } from "vitals/hooks"

import { ProcedureData } from "../types"

const useProcedureNotePrefillData = (): PrefillData => {
  const {
    patient: { birthDate, name, id: patientId },
  } = usePatientContext()
  const { vitals } = useVitalsKnowledge(patientId as string)
  const {
    values: {
      procedure: { performer },
    },
  } = useFormikContext<ProcedureData>()

  const getQuantity = useCallback(
    (code: string, sameUnit?: boolean) => {
      if (!vitals[code]) return "N/A"

      return vitals[code].vital.component
        ? vitals[code].vital.component
            ?.map((comp) => `${comp.value?.Quantity?.value} ${!sameUnit ? comp.value?.Quantity?.unit : ""}`)
            .join(" / ") + `${sameUnit ? vitals[code].vital.component?.[0].value?.Quantity?.unit : ""}`
        : `${vitals[code].vital.value?.Quantity?.value?.toString()} ${vitals[code].vital.value?.Quantity?.unit}`
    },
    [vitals],
  )

  return {
    patientName: humanNameAsString(name?.[0]),
    patientFirstName: name?.[0]?.given?.[0] ?? "",
    patientDOB: birthDate ? format(parseISO(birthDate), formatsByTypes.LONG_DATE) : "",
    currentDate: format(parseISO(new Date().toISOString()), formatsByTypes.LONG_DATE),
    practitionerName: performer?.[0]?.actor?.display ?? "",
    patientHeight: getQuantity(vitalSignCodes.height.coding[0].code),
    patientWeight: getQuantity(vitalSignCodes.weight.coding[0].code),
    patientBloodPressure: getQuantity(vitalSignCodes.bloodPressure.coding[0].code),
    patientPulse: getQuantity(vitalSignCodes.heartRate.coding[0].code),
    patientRespiration: getQuantity(vitalSignCodes.respiratoryRate.coding[0].code),
    patientTemperature: getQuantity(vitalSignCodes.temperature.coding[0].code),
  }
}

type PrefillData = {
  patientName: string
  patientFirstName: string
  patientDOB: string
  patientHeight: string
  patientWeight: string
  patientBloodPressure: string
  patientPulse: string
  patientRespiration: string
  patientTemperature: string
  currentDate: string
  practitionerName: string
}

export { useProcedureNotePrefillData }
