import {
  codeableConceptAsString,
  Coding,
  ObservationDefinition,
  Questionnaire,
  QuestionnaireResponse,
  Reference,
} from "fhir"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"

import { QRDropdownField, SkeletonLoader } from "commons"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"
import { useAssignQuestionnaire } from "surveys"
import { getLoincCode } from "utils"

import { DRData, ObsData } from "../../types"
import { DRDropdown } from "./DRDropdown"
import { LabDataInputFieldList, OptionalFieldDefinition } from "./LabDataInputFieldList"

const QuestionnaireAndLabDataForm = ({
  isLoading,
  questionnaire,
  questionnaireResponses,
  observationRequestData,
  observationData,
  selectedQR,
  performers,
  requiredOD,
  reloadData,
}: Props) => {
  const { patient, patientId } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { assignQuestionnaire } = useAssignQuestionnaire(reloadData)
  const assignSurvey = () =>
    assignQuestionnaire({ patient, questionnaire: questionnaire as Questionnaire, openEncounter: openEncounterRef })
  const {
    values: { selectedDR },
    setFieldValue,
  } = useFormikContext<{ performer: Reference; selectedDR: DRData; qrId?: string }>()
  const filteredQRs = Object.values(questionnaireResponses).filter(({ status }) => status !== "entered-in-error")

  const mapObservationData = (observations: ObsData) =>
    Object.values(observations?.data ?? {}).reduce<OptionalFieldDefinition[]>(
      (acc, obs) =>
        getLoincCode(obs.code.coding) !== "no code"
          ? [
              ...acc,
              {
                field: getLoincCode(obs.code.coding),
                label: codeableConceptAsString(obs.code),
              },
            ]
          : acc,
      [],
    )

  const [selectedResults, setSelectedResults] = useState<OptionalFieldDefinition[] | undefined>(
    selectedDR ? mapObservationData(selectedDR.observations) : undefined,
  )
  useEffect(() => {
    if (selectedDR?.observations.values) {
      let values = {}
      for (const key in selectedDR.observations.values) {
        if (Object.prototype.hasOwnProperty.call(selectedDR.observations.values, key)) {
          const value = selectedDR.observations.values[key]
          values = { ...values, [key]: value }
        }
      }
      setFieldValue("labData", values)
    }
  }, [selectedDR?.observations.values])

  const filteredDRData = performers
    ? observationData.filter(
        (data) =>
          performers.some((p) => data.dr.performer?.[0]?.id === p.id) ||
          (data.observations.data &&
            performers.some((p) => Object.values(data.observations.data)?.[0]?.performer?.[0]?.id === p.id)),
      )
    : observationData

  const onChangeDR = (drData: DRData) => {
    setFieldValue(
      "performer",
      performers?.find(
        (p) =>
          drData?.dr?.performer?.[0]?.id === p.id ||
          Object.values(drData?.observations?.data ?? {})?.[0]?.performer?.[0]?.id === p.id,
      ) ?? {},
    )
    setSelectedResults(drData ? mapObservationData(drData.observations) : undefined)
  }

  return isLoading ? (
    <SkeletonLoader containerClassName="flex flex-col" loaderType="two-lines" repeats={5} />
  ) : (
    <div className="flex flex-col gap-6">
      <QRDropdownField
        field="qrId"
        questionnaire={questionnaire}
        options={selectedQR ? [selectedQR] : filteredQRs}
        onAssignSurvey={assignSurvey}
        onHideSurvey={reloadData}
        validation={(qrId) =>
          typeof qrId !== "string" || questionnaireResponses[qrId]?.status !== "completed"
            ? "Completed questionnaire is required"
            : undefined
        }
        optionLabel="title"
        optionValue="id"
      />
      {observationRequestData.length > 0 && (
        <div className="flex flex-col gap-6">
          {filteredDRData.length > 0 && <DRDropdown options={filteredDRData} onChangeDR={onChangeDR} />}
          <LabDataInputFieldList
            field="labData"
            label="Lab Data"
            addFieldLabel="Add result"
            fieldDefinitions={observationRequestData.map((od) => ({
              field: getLoincCode(od.code.coding),
              label: `${codeableConceptAsString(od.code)} - (${getLoincCode(od.code.coding)})`,
            }))}
            initialFields={selectedResults}
            performers={performers}
            requiredFields={requiredOD?.map((c) => ({ field: c.code as string, label: c.display as string }))}
          />
        </div>
      )}
    </div>
  )
}

type Props = {
  isLoading: boolean
  questionnaire?: Questionnaire
  questionnaireResponses: Record<string, QuestionnaireResponse>
  observationRequestData: ObservationDefinition[]
  observationData: DRData[]
  selectedQR?: QuestionnaireResponse
  performers?: Reference[]
  requiredOD?: Coding[]
  reloadData(): void
}

export { QuestionnaireAndLabDataForm }
