import { useQuery } from "@tanstack/react-query"
import {
  AllergyIntolerance,
  Appointment,
  CarePlan,
  Consent,
  getResource,
  getResources,
  MedicationRequest,
  MedicationStatement,
  PlanDefinition,
  PlanDefinitionActionArrayActionArray,
  Procedure,
  Provenance,
  Questionnaire,
  QuestionnaireResponse,
  ServiceRequest,
  Task,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { QuestionnaireData } from "commons"
import { MC_ACTIVITY_TYPE, mrCategoryCodes } from "data"

import { CalculatorOutput, generateCalculatorResultFromCarePlan } from "../../procedures"
import { plansQueryKeys } from "../query-keys"
import { PLAN_ACTION_CODES } from "../types"
import { getActionCode } from "../utils"

const useCarePlanDetails = (patientId: string, planId?: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.details(patientId, planId)

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _sort: "-createdAt",
        _include:
          "instantiates-canonical:PlanDefinition,outcome-reference:Procedure,outcome-reference:Task,outcome-reference:QuestionnaireResponse,outcome-reference:Appointment,outcome-reference:Consent,outcome-reference:CarePlan,outcome-reference:ServiceRequest,QuestionnaireResponse:questionnaire:Questionnaire",
        _revinclude: "MedicationRequest:based-on:CarePlan,Provenance:entity:QuestionnaireResponse",
      })
      filters.append("_include", "Provenance:target")

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const carePlans = getResources<CarePlan>(bundle, "CarePlan")
      const carePlan = carePlans.find(({ id }) => id === planId)
      const planDefinition = getResource<PlanDefinition>(bundle, "PlanDefinition")
      const serviceRequest = getResources<ServiceRequest>(bundle, "ServiceRequest")?.find(
        (sr) => sr.category?.[0]?.coding?.[0]?.code === "lab-order",
      )
      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")
      const questionnaireResponses = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const provenances = getResources<Provenance>(bundle, "Provenance")
      const medicationStatementsExtractedFromQR = getResources<MedicationStatement>(bundle, "MedicationStatement")
      const allergiesIntolerancesExtractedFromQR = getResources<AllergyIntolerance>(bundle, "AllergyIntolerance")
      const appointment = getResource<Appointment>(bundle, "Appointment")
      const procedure = getResource<Procedure>(bundle, "Procedure")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const consents = getResources<Consent>(bundle, "Consent")
      const tasks = getResources<Task>(bundle, "Task")

      const generatedCarePlan = carePlans.find(({ id }) => id !== planId)
      let generatedCalculatorResult: CalculatorOutput | undefined = undefined
      if (generatedCarePlan) {
        generatedCalculatorResult = generateCalculatorResultFromCarePlan(generatedCarePlan)
      }

      return {
        carePlan,
        planDefinition,
        serviceRequest,
        questionnaires,
        questionnaireResponses,
        appointment,
        procedure,
        medicationRequests,
        provenances,
        medicationStatementsExtractedFromQR,
        allergiesIntolerancesExtractedFromQR,
        consents,
        tasks,
        generatedCalculatorResult,
      }
    },
    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  const {
    questionnaires,
    isProcedurePlan,
    isMCPlan,
    isOnlySurvey,
    mcAlgorithmId,
    mcPlanIds,
    nutraceuticals,
    rx,
    reassessMedicationTask,
    configureActions,
    candidateTask,
  } = useMemo(() => {
    const questionnaires = data?.questionnaireResponses?.reduce<Array<QuestionnaireData>>((acc, qr) => {
      const questionnaire = data?.questionnaires.find((q) => `${q.url}|${q.version}` === qr.questionnaire)
      return [...acc, { qResponse: qr, questionnaire } as QuestionnaireData]
    }, [])

    const actions =
      data?.planDefinition.action?.reduce(
        (acc, action) => {
          const code = getActionCode(action.code?.[0])
          if (code) return { ...acc, [code]: action }
          else return { ...acc }
        },
        {} as Record<string, PlanDefinitionActionArrayActionArray>,
      ) ?? {}

    const isMCPlan = !!actions[PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]?.action?.some(
      ({ code }) => code?.[0]?.coding?.[0]?.code === PLAN_ACTION_CODES.CONFIGURE_MC,
    )
    const isProcedurePlan = !!actions[PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]?.action?.some(
      ({ code }) => code?.[0]?.coding?.[0]?.code === PLAN_ACTION_CODES.CONFIGURE_PROCEDURE,
    )

    const mcActivity = isMCPlan
      ? data?.carePlan?.activity?.find((a) => a.outcomeCodeableConcept?.[0]?.coding?.[0]?.code === "request-mc")
      : undefined
    const isOnlySurvey = mcActivity?.detail?.kind === MC_ACTIVITY_TYPE.MC_SURVEY
    const mcAlgorithmId = mcActivity?.outcomeReference?.find((r) => r.resourceType === "PlanDefinition")?.id
    const mcPlanIds = mcActivity?.outcomeReference
      ?.filter((r) => r.resourceType === "CarePlan")
      ?.map(({ id }) => id ?? "")

    const defaultMeds = { nutraceuticals: Array<MedicationRequest>(), rx: Array<MedicationRequest>() }

    const { nutraceuticals, rx } =
      data?.medicationRequests.reduce((acc, mr) => {
        const isNutra = mr.category?.some(({ coding }) =>
          coding?.some(({ code }) => mrCategoryCodes.nutraceutical?.code === code),
        )
        const isRx = mr.category?.some(({ coding }) =>
          coding?.some(({ code }) => mrCategoryCodes.medication?.code === code),
        )
        if (isNutra) return { ...acc, nutraceuticals: [...acc.nutraceuticals, mr] }
        if (isRx) return { ...acc, rx: [...acc.rx, mr] }
        return { ...acc }
      }, defaultMeds) ?? defaultMeds

    const reassessMedicationTask = data?.tasks?.find((task) =>
      task.code?.coding?.some(({ code }) => code === "reassess-medication"),
    )

    const candidateAction = actions[PLAN_ACTION_CODES.PROCEDURE_CANDIDATE]

    const candidateTask = data?.tasks?.find((task) =>
      task.code?.coding?.some(({ code }) => code === candidateAction?.code?.[0]?.coding?.[0]?.code),
    )

    return {
      questionnaires,
      isProcedurePlan,
      isMCPlan,
      isOnlySurvey,
      mcAlgorithmId,
      mcPlanIds,
      nutraceuticals,
      rx,
      reassessMedicationTask,
      configureActions: actions,
      candidateTask,
    }
  }, [
    data?.carePlan?.activity,
    data?.planDefinition?.action,
    data?.questionnaireResponses,
    data?.questionnaires,
    data?.tasks,
    data?.medicationRequests,
  ])
  const intakes = useMemo(
    () =>
      data?.provenances?.reduce(
        (acc, provenance) => {
          const questionnairesData = questionnaires?.find((q) => q?.qResponse?.id === provenance.entity?.[0]?.what?.id)

          if (!questionnairesData) {
            return acc
          }

          const medicationStatements = data?.medicationStatementsExtractedFromQR.filter((ms) =>
            provenance.target?.find((target) => target.id === ms.id),
          )

          const allergiesIntolerances = data?.allergiesIntolerancesExtractedFromQR.filter((ai) =>
            provenance.target?.find((target) => target.id === ai.id),
          )

          if (medicationStatements.length || allergiesIntolerances.length) {
            return [
              ...acc,
              {
                questionnairesData,
                ...(medicationStatements.length > 0 && { medicationStatements }),
                ...(allergiesIntolerances.length > 0 && { allergiesIntolerances }),
              },
            ]
          }

          return acc
        },
        Array<{
          questionnairesData: QuestionnaireData
          medicationStatements?: MedicationStatement[]
          allergiesIntolerances?: AllergyIntolerance[]
        }>(),
      ),
    [data?.medicationStatementsExtractedFromQR, data?.allergiesIntolerancesExtractedFromQR, data?.provenances],
  )
  return {
    carePlan: data?.carePlan,
    planDefinition: data?.planDefinition,
    serviceRequest: data?.serviceRequest,
    questionnaires,
    appointment: data?.appointment,
    procedure: data?.procedure,
    consents: data?.consents,
    intakes,
    isLoading,
    isProcedurePlan,
    isMCPlan,
    isOnlySurvey,
    mcAlgorithmId,
    mcPlanIds,
    refetch,
    isRefetching,
    nutraceuticals,
    rx,
    reassessMedicationTask,
    configureActions,
    candidateTask,
    generatedCalculatorResult: data?.generatedCalculatorResult,
  }
}

export { useCarePlanDetails }
