import { codeableConceptAsString } from "fhir"
import { FC } from "react"

import { CalculatorOutput } from "../../types"

const CalculatorRecomendations: FC<Omit<CalculatorOutput, "suggestedMeds">> = ({ notes, recommended }) => (
  <>
    <div className="flex flex-col">
      <div className="font-medium text-gray-900">Recommended dosage</div>
      {recommended.map(({ codeableConcept, dosage }, index) => (
        <li key={index} className="text-gray-700">
          {`${codeableConceptAsString(codeableConcept)}: ${dosage.text}`}
        </li>
      ))}
    </div>
    <div className="flex flex-col">
      <div className="font-medium text-gray-900">Considerations</div>
      {notes.map((note, index) => (
        <li key={index} className="text-gray-700">
          {note.text}
        </li>
      ))}
    </div>
  </>
)

export { CalculatorRecomendations }
