import { asReference, Reference, Task } from "fhir"
import { Sidebar } from "primereact/sidebar"
import { useCallback, useMemo, useReducer } from "react"
import { useSearchParams } from "react-router-dom"

import { FormContainer, ValueSetIds } from "commons"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { useValueSet, ValueSetItem } from "value-set"

import { useCreateTask, useUpdateTask } from "../hooks"
import { TaskDetailView } from "./TaskDetailView"
import { TaskForm } from "./TaskForm"
import { TaskList } from "./TaskList"
import { getInitialValues, sanitize, taskValidationSchema } from "./validations"

const TaskView = () => {
  const [params] = useSearchParams()
  const { currentOrganizationId, currentOrganization, practitionerRole } = useOrganizationContext()
  const { codes: taskCodes } = useValueSet({ valueSetId: ValueSetIds.TASK_CODE })

  const { practitionerRefs } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  //TODO: remove fallback when organizational is added to VS
  const defaultTaskCode = useMemo(
    () =>
      taskCodes?.find((tCode) => tCode.code === "organizational") ?? {
        system: SYSTEM_VALUES.TASK_CODE,
        code: "organizational",
        display: "Organizational",
      },
    [taskCodes],
  )

  const { showSidePanel, initialValues, isNew, add, edit, reset } = useReducerState(
    asReference(currentOrganization),
    defaultTaskCode,
    asReference(practitionerRole ?? {}),
  )

  const closeForm = () => {
    reset()
  }

  const { createTask } = useCreateTask(closeForm)
  const { updateTask } = useUpdateTask({ hideForm: closeForm })

  const customSidebarTitlebar = (
    <>
      <span className="bg-white w-full">
        <h6 className="font-semibold">Task</h6>
        <p className="text-slate-400 text-sm">Create or edit tasks for practitioners</p>
      </span>
    </>
  )

  const onSubmit = (task: Task) => {
    isNew ? createTask(sanitize(task)) : updateTask(sanitize(task))
  }

  return (
    <>
      {!params.get("id") ? (
        <TaskList
          organizationId={currentOrganizationId}
          practitioners={practitionerRefs ?? []}
          onAdd={add}
          onEditTask={edit}
        />
      ) : (
        <TaskDetailView />
      )}
      <Sidebar
        visible={showSidePanel}
        position="right"
        style={{ minWidth: "30%" }}
        header={customSidebarTitlebar}
        onHide={closeForm}
        className="sidebar-form"
      >
        <div className="relative h-full">
          <FormContainer
            initialValue={initialValues}
            onSubmit={onSubmit}
            onCancel={closeForm}
            validationSchema={taskValidationSchema}
          >
            <TaskForm practitioners={practitionerRefs} />
          </FormContainer>
        </div>
      </Sidebar>
    </>
  )
}

const initialState = (defaultTask: Task) =>
  ({
    initialValues: defaultTask,
    showSidePanel: false,
  }) as State

const reducer = (state: State, { type, payload }: { type: "reset" | "add" | "edit"; payload: Task | string }) => {
  switch (type) {
    case "reset":
      return { ...state, showSidePanel: false, isNew: false, initialValues: payload as Task, confirmDeleteItem: "" }
    case "add":
      return { ...state, showSidePanel: true, panelView: "form", isNew: true, initialValues: payload as Task }
    case "edit":
      return { ...state, showSidePanel: true, panelView: "form", isNew: false, initialValues: payload as Task }
    default:
      return state
  }
}
const useReducerState = (organization: Reference, defaultTaskCode: ValueSetItem, practitionerRole?: Reference) => {
  const defaultTask = useMemo(
    () => getInitialValues(organization, defaultTaskCode, practitionerRole),
    [organization, practitionerRole, defaultTaskCode],
  )
  const state = useMemo(() => initialState(defaultTask), [defaultTask])
  const [{ initialValues, showSidePanel, isNew }, dispatch] = useReducer(reducer, state)

  const reset = useCallback(() => {
    dispatch({ type: "reset", payload: defaultTask })
  }, [defaultTask])

  const add = useCallback(() => {
    dispatch({ type: "add", payload: defaultTask })
  }, [defaultTask])

  const edit = (selectedTask: Task) => {
    dispatch({ type: "edit", payload: selectedTask })
  }

  return { initialValues, isNew, reset, add, edit, showSidePanel }
}

type State = {
  initialValues: Task
  showSidePanel: boolean
  isNew?: boolean
}

export { TaskView }
