import { useQuery } from "@tanstack/react-query"
import { getResources, PlanDefinition } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { SYSTEM_VALUES } from "system-values"

import { getConfigCode } from "../data"
import { proceduresQueryKeys } from "../query-keys"
import { ProcedureTypeData } from "../types"

const useProcedurePlanDefinitions = (gender?: string, pdCanonical?: string) => {
  const { search } = useClient()
  const queryKey = proceduresQueryKeys.definitions(pdCanonical)
  const [url, version] = pdCanonical?.split("|") ?? []

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        type: "procedure",
        status: "active",
        _elements: "name,title,url,version,identifier,useContext,action",
        ...(url && version ? { url, version } : {}),
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")

      return { planDefinitions }
    },
    meta: { context: { queryKey } },
  })

  const procedureTypes = useMemo(
    () =>
      data?.planDefinitions
        ?.filter(
          ({ useContext }) =>
            useContext?.some(
              ({ code, value }) => code.code === "gender" && value?.CodeableConcept?.coding?.[0]?.code === gender,
            ) || !useContext,
        )
        ?.reduce<Record<string, ProcedureTypeData>>((acc, { id, name, title, url, version, identifier, action }) => {
          const configAction = action?.filter(({ groupingBehavior }) => groupingBehavior === "visual-group")
          const configCodes =
            configAction?.reduce((acc, action) => {
              const subActionConfigCodes = action.action?.reduce((acc2, subaction) => {
                const code = getConfigCode(subaction)
                return [...acc2, ...(code ? [code] : [])]
              }, Array<string>())

              const code = getConfigCode(action)

              return [...acc, ...(subActionConfigCodes ?? []), ...(code ? [code] : [])]
            }, Array<string>()) ?? []

          const isAlgorithm =
            action?.some(({ code }) => code?.[0]?.coding?.some(({ code }) => code === "run-algorithm")) ?? false

          return {
            ...acc,
            [`${url}|${version}`]: {
              id: id as string,
              canonical: [`${url}|${version}`],
              cc: {
                coding: [{ code: name, display: title, system: SYSTEM_VALUES.PROCEDURE_TYPE }],
                text: title,
              },
              identifier,
              action: configAction ?? [],
              title,
              configCodes,
              isAlgorithm,
            } as ProcedureTypeData,
          }
        }, {}) ?? {},
    [data?.planDefinitions, gender],
  )

  return {
    planDefinitions: data?.planDefinitions,
    isLoading,
    procedureTypes,
  }
}

export { useProcedurePlanDefinitions }
