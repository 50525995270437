import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons"
import { faCheck, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { asReference } from "fhir"
import { useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"
import { displayNotificationSuccess } from "utils"

import { FloatingButton } from "../../../components/Buttons"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { SplitButton } from "../../../components/SplitButton"
import { useCarePlanHistory, useEnrollPlan, usePlanContext, useUpdatePlanStatus } from "../../hooks"
import { AppointmentAction, PLAN_SUBVIEWS } from "../../types"
import { isValidGenderContext } from "../../utils"
import { CPItem } from "./CPItem"

const AppointmentHistory = ({ carePlanId, selectedItemId, onSelectHisoryItem }: Props) => {
  const { hasGP } = useChartContext()
  const { patientId, patient, isPlanView, navigateToSubview, completeDisabled, returnToListView } = usePlanContext()
  const { practitionerRoleRef } = useOrganizationContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { isLoading, appointmentHistory, nextAppointment, lastActiveAppointment, isRefetching, refetch } =
    useCarePlanHistory(patientId, carePlanId)

  const { updatePlanStatus: completeCarePlan, isUpdating } = useUpdatePlanStatus(patientId, () => {
    if ((confirmAction & AppointmentAction.Enroll) === AppointmentAction.Enroll) enroll()
    else {
      displayNotificationSuccess(`Treatment plan completed successfully!`)
      returnToListView()
      setConfirmAction(confirmAction ^ AppointmentAction.Complete)
    }
  })
  const { enrollPlan, isEnrolling } = useEnrollPlan((pdId) => {
    setConfirmAction(AppointmentAction.None)
    navigateToSubview(PLAN_SUBVIEWS.CONFIG, pdId)
  })

  const [confirmAction, setConfirmAction] = useState<AppointmentAction>(AppointmentAction.None)

  const enroll = () =>
    enrollPlan({
      planId: lastActiveAppointment?.nextPlanDefinition?.id as string,
      author: practitionerRoleRef,
      patient: asReference(patient),
      encounter: openEncounterRef,
    })

  const processAction = () => {
    if ((confirmAction & AppointmentAction.Complete) === AppointmentAction.Complete)
      completeCarePlan({ planId: lastActiveAppointment?.carePlan.id as string, status: "completed" })
    else if ((confirmAction & AppointmentAction.Enroll) === AppointmentAction.Enroll) enroll()
  }

  const canContinuePlan = useMemo(
    () =>
      isPlanView &&
      lastActiveAppointment?.carePlan?.activity?.find(({ outcomeCodeableConcept }) =>
        outcomeCodeableConcept?.[0]?.coding?.some(({ code }) => code === "procedure"),
      )?.enabled !== false,
    [lastActiveAppointment?.carePlan?.activity, isPlanView],
  )

  const actions = useMemo(
    () => [
      ...(lastActiveAppointment?.nextPlanDefinition && canContinuePlan
        ? [
            {
              label: "Continue treatment",
              description:
                lastActiveAppointment?.carePlan.status === "active"
                  ? "Complete current treatment and configure a new appointment"
                  : "Configure a new treatment",
              icon: faPlus,
              onSelectClick: () => {
                setConfirmAction(
                  lastActiveAppointment?.carePlan.status === "active"
                    ? AppointmentAction.CompleteAndEnroll
                    : AppointmentAction.Enroll,
                )
              },
              disabled:
                !isValidGenderContext(patient.gender as string, lastActiveAppointment.nextPlanDefinition.useContext) ||
                completeDisabled,
              disabledReason: completeDisabled
                ? "Complete questionnaires and wait for all the information to be completed before proceed"
                : "Follow up treatment plan and patient have different genders",
            },
          ]
        : []),
      ...(lastActiveAppointment?.carePlan?.status === "active"
        ? [
            {
              label: "End treatment",
              description: "End current treatment",
              icon: faCheck,
              onSelectClick: () => setConfirmAction(AppointmentAction.Complete),
            },
          ]
        : []),
    ],
    [canContinuePlan, lastActiveAppointment, patient.gender, completeDisabled],
  )

  return (
    <>
      {isPlanView ? (
        <div className="flex flex-col mr-3 mb-3 w-80 divide-y rounded-md border">
          <div className="flex flex-col flex-1 p-5">
            {isLoading ? (
              <SkeletonLoader loaderType="two-lines" repeats={4} />
            ) : (
              <>
                <div className="inline-flex justify-between items-center">
                  <h1 className="font-medium text-lg text-gray-900">Appointment History</h1>
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    className="cursor-pointer text-gray-900"
                    spin={isRefetching}
                    onClick={() => refetch()}
                    title="Refresh"
                  />
                </div>

                <div className="flex flex-col mt-2 overflow-y-auto">
                  {nextAppointment && (
                    <>
                      <div className="flex mt-2 border-t h-4">
                        <h2 className="text-sm text-gray-700 bg-white -mt-3 pr-2">Next appointment</h2>
                      </div>
                      <CPItem
                        cpItem={nextAppointment}
                        selected={selectedItemId === nextAppointment.carePlan.id}
                        onClick={() => onSelectHisoryItem(nextAppointment.carePlan.id as string)}
                      />
                    </>
                  )}
                  {appointmentHistory.length ? (
                    <>
                      <div className="flex mt-6 border-t h-4">
                        <h2 className="text-sm text-gray-700  bg-white -mt-3 pr-2">Previous appointments</h2>
                      </div>
                      {appointmentHistory.map((cp) => (
                        <CPItem
                          key={cp.carePlan.id}
                          cpItem={cp}
                          selected={selectedItemId === cp.carePlan.id}
                          onClick={() => onSelectHisoryItem(cp.carePlan.id as string)}
                        />
                      ))}
                    </>
                  ) : (
                    !nextAppointment && <span className="font-medium text-gray-700 flex-1">Empty history</span>
                  )}
                </div>
              </>
            )}
          </div>
          {lastActiveAppointment?.carePlan?.id === selectedItemId && !!actions.length && (
            <div className="flex justify-evenly py-4">
              <SplitButton
                loading={isLoading || isUpdating || isEnrolling}
                size="lg"
                autoUpdateMainAction
                actions={actions}
                defaultActionIndex={completeDisabled ? 1 : undefined}
                disabled={!hasGP}
              />
            </div>
          )}
        </div>
      ) : (
        lastActiveAppointment?.carePlan?.status === "active" &&
        isPlanView && (
          <FloatingButton
            icon={faCheck}
            label="Complete"
            onClick={() => setConfirmAction(AppointmentAction.Complete)}
            disabled={completeDisabled || !hasGP}
            title={
              completeDisabled
                ? "Complete questionnaires and wait for labs and assessments to be complete before closing this plan"
                : undefined
            }
          />
        )
      )}

      <ConfirmDialog
        confirmElement={
          (confirmAction & AppointmentAction.Complete) === AppointmentAction.Complete
            ? `Please confirm you want to ${
                (confirmAction & AppointmentAction.Enroll) === AppointmentAction.Enroll
                  ? "continue with treatment"
                  : "end this treatment"
              }?`
            : "Are you sure you want to start a new treatment?"
        }
        hideDialog={() => {
          setConfirmAction(AppointmentAction.None)
        }}
        visible={(confirmAction & AppointmentAction.CompleteAndEnroll) >= 1 || isUpdating || isEnrolling}
        isLoading={isUpdating || isEnrolling}
        onConfirm={() => processAction()}
        waitToFinish
      />
    </>
  )
}

type Props = { carePlanId: string; selectedItemId: string; onSelectHisoryItem(planId: string): void }

export { AppointmentHistory }
