import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query"
import { formatDate } from "date-fns/format"
import { formatDistance } from "date-fns/formatDistance"
import { AllergyIntolerance, MedicationStatement, QuestionnaireResponse } from "fhir"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { Panel, PanelFooterTemplateOptions } from "primereact/panel"
import { classNames } from "primereact/utils"
import { FC } from "react"

import { allergyModelBuilder } from "allergies"
import { useChartContext } from "chart-view"
import { ModulesId, QuestionnaireData, StackedListContainer } from "commons"
import { formatsByTypes } from "data"
import { medicationModel } from "medications-statement"
import { useAppModuleContext } from "modules"

import { CPQuestionnaires } from "./CPQuestionnaires"

const CPIntakesDetails: FC<Props> = ({ intakes, isUpdatingSurvey, isMCPlan, onSurveyHide }) => {
  const { isModuleActive, appSubModules } = useAppModuleContext()
  const { showSubModule } = useChartContext()

  const renderHeaderTemplate = (options: PanelFooterTemplateOptions, item: QuestionnaireData) => {
    return (
      <CPQuestionnaires
        isUpdatingSurvey={isUpdatingSurvey}
        isMCPlan={isMCPlan}
        onSurveyHide={onSurveyHide}
        questionnaires={[item]}
        className={options.className}
      />
    )
  }

  const renderFooterTemplate = (options: PanelFooterTemplateOptions, item: QuestionnaireResponse) => {
    const lastUpdatedAt = item?.meta?.lastUpdated
    const isCompleted = item?.status === "completed"

    return (
      <div className={classNames(options.className, "flex justify-end gap-2")}>
        <span className="text-gray-500 text-sm">
          {isCompleted
            ? lastUpdatedAt
              ? `Completed on ${formatDate(lastUpdatedAt, formatsByTypes.LONG_DATE)}`
              : "No completion date provided"
            : lastUpdatedAt
              ? `Updated ${formatDistance(lastUpdatedAt, new Date(), { addSuffix: true })}`
              : "No last updated date provided"}
        </span>
      </div>
    )
  }

  return (
    <div className="my-6 mx-auto flex flex-col justify-between gap-4">
      {intakes?.map((intake) => (
        <Panel
          key={intake.questionnairesData.qResponse!.id}
          headerTemplate={(options) => renderHeaderTemplate(options, intake?.questionnairesData)}
          footerTemplate={(options) => renderFooterTemplate(options, intake.questionnairesData.qResponse!)}
          pt={{
            content: {
              className: "rounded-br-none rounded-bl-none",
            },
          }}
        >
          <section className="flex flex-col">
            {!!intake?.medicationStatements?.length && (
              <div className="gap-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-gray-900 font-semibold">Medication Statements</h2>
                  <ExternalLinkButton
                    onClick={() => showSubModule({ subModule: appSubModules.intake[ModulesId.MEDICATIONE] })}
                    isVisible={isModuleActive(ModulesId.INTAKE)}
                  />
                </div>
                <StackedListContainer
                  data={intake?.medicationStatements}
                  itemModelBuilder={(item) => medicationModel(item)}
                />
              </div>
            )}
            {!!intake?.medicationStatements?.length && !!intake?.allergiesIntolerances?.length && (
              <Divider className="border-gray-800" />
            )}
            {!!intake?.allergiesIntolerances?.length && (
              <div className="gap-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-gray-900 font-semibold">Allergies</h2>
                  <ExternalLinkButton
                    onClick={() => showSubModule({ subModule: appSubModules.intake[ModulesId.ALLERGY] })}
                    isVisible={isModuleActive(ModulesId.INTAKE)}
                  />
                </div>
                <StackedListContainer
                  data={intake?.allergiesIntolerances}
                  itemModelBuilder={(item) => allergyModelBuilder(item)}
                />
              </div>
            )}
          </section>
        </Panel>
      ))}
    </div>
  )
}

const ExternalLinkButton = ({ onClick, isVisible }: ExternalLinkProps) =>
  isVisible && (
    <Button
      className="close-left-panel p-button-text"
      icon={<FontAwesomeIcon className="fa-fw" icon={faExternalLink} />}
      onClick={onClick}
    />
  )

type ExternalLinkProps = {
  onClick: () => void
  isVisible?: boolean
}

type Intake = {
  questionnairesData: QuestionnaireData
  medicationStatements?: MedicationStatement[]
  allergiesIntolerances?: AllergyIntolerance[]
}

type Props = {
  intakes?: Intake[]
  isUpdatingSurvey: boolean
  isMCPlan: boolean
  onSurveyHide: (options?: RefetchOptions) => Promise<QueryObserverResult>
}

export { CPIntakesDetails }
